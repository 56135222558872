import {
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  FREE_COMMISSION_DATA,
  FREE_COMMISSION_DISPLAY,
  ROUTE_INDIVIDUAL_FUND,
  SIGN_MINUS,
  SIGN_PLUS,
  UNITS,
} from './constants'
import {
  AbrParams,
  Fund,
  FundOrderInfo,
  FundTradeInfoResponseType,
  StockFundResponseType,
} from './types'
import { formatStandardDate, formatWithComma } from './util'

export const generateFundPageUrl = (
  abrParams: AbrParams,
  fundCode: string
): string => {
  return `${ROUTE_INDIVIDUAL_FUND.replace(':fundCode', fundCode)}?param1=${
    abrParams.param1
  }&param2=${abrParams.param2}&param3=${abrParams.param3}`
}

const mapFundTradeInfoResponseToInvestmentInfo = (
  info: FundTradeInfoResponseType | null
): FundOrderInfo | null => {
  return info
    ? {
        commission: info.commission
          ? info.commission.replace(
              FREE_COMMISSION_DATA,
              FREE_COMMISSION_DISPLAY
            )
          : '',
        minTradeAmount: formatWithComma(info.minTradeAmount),
        tradeUnitAmount: formatWithComma(info.tradeUnitAmount),
        tradeAmountUnit: UNITS[info.tradeAmountUnit.toString()],
      }
    : null
}

export const mapStockFundResponseToFund = (
  stockFundRes: StockFundResponseType
): Fund => {
  const fundRes = stockFundRes.data
  return {
    fundCode: fundRes.fundCode,
    fundName: fundRes.fundName,
    investmentCompany: fundRes.investmentCompany || '',
    standardPrice: formatWithComma(fundRes.standardPrice),
    changePercent: `${
      fundRes.changePercent >= 0 ? SIGN_PLUS : SIGN_MINUS
    }${Math.abs(fundRes.changePercent).toFixed(2).toString()}`,
    standardPriceDate: formatStandardDate(fundRes.standardPriceDate),
    executionDate: 'TBD',
    settlementDate: 'TBD',
    accumulatedAmount: 'TBD',
    distributedAmount: 'TBD',
    specialtyDescription: 'TBD',
    buyingInfo: {
      distributing: mapFundTradeInfoResponseToInvestmentInfo(
        fundRes.buyingInfomation.distributingInfo
      ),
      accumulating: mapFundTradeInfoResponseToInvestmentInfo(
        fundRes.buyingInfomation.accumulatingInfo
      ),
    },
    sellingInfo: {
      distributing: mapFundTradeInfoResponseToInvestmentInfo(
        fundRes.sellingInfomation.distributingInfo
      ),
      accumulating: mapFundTradeInfoResponseToInvestmentInfo(
        fundRes.sellingInfomation.accumulatingInfo
      ),
    },
    buyingDeadline: fundRes.buyingInfomation.deadline,
    sellingDeadline: fundRes.sellingInfomation.deadline,
    renumerationNominal: 'TBD',
    renumerationReal: 'TBD',
    buyingReservation: 'TBD',
    sellingReservation: 'TBD',
    isReserve: fundRes.isReserve,
    isNisaReserve: fundRes.isNisaReserve,
  } as Fund
}

// Check if specified distribution type is available for a fund
export const isDistributionTypeDisabled = (
  distributionTypeName: string,
  fund: Fund
) => {
  if (distributionTypeName === DISTRIBUTION_TYPE_DISTRIBUTIVE) {
    return !fund.buyingInfo.distributing
  } else {
    return !fund.buyingInfo.accumulating
  }
}
