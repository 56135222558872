import React from 'react'
import styled from 'styled-components'

export type ButtonProps = {
  handleClick: () => void
  disabled?: boolean
  text: string
  className?: string
}

const Button = ({ handleClick, disabled, text, className }: ButtonProps) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={handleClick}
      className={className ? className : ''}
    >
      {text}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  & {
    width: 160px;
    height: 40px;
    font-size: 1.4rem;
    box-sizing: border-box;
    outline: none;
    color: #ff6400;
    background: #fff;
    border: 2px solid #ff6400;
  }

  &:disabled {
    color: #999;
    background: #eee;
    border: none;
  }
`
