import React from 'react'
import CenteredText from './CenteredText'

const AuthErrorText = () => (
  <CenteredText>
    <p>
      auカブコム認証に失敗しました。
      <br />
      再度、auカブコム証券の認証手続きを行ってください。
    </p>
  </CenteredText>
)

export default AuthErrorText
