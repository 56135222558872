import { Prospectus } from '../../common/utils/types'
import React from 'react'
import ICON_PROSPECTUS from '../../assets/images/icon_prospectus.svg'
import ICON_TICK from '../../assets/images/icon_tick.svg'
import Spinner from './Spinner'
import styled from 'styled-components'

type ProspectusLinksProps = {
  prospectuses: Prospectus[]
  loading: boolean
}
const ProspectusLinks = ({ prospectuses, loading }: ProspectusLinksProps) => {
  return (
    <StyledProspectusLinks className="l-link01 prospectus-links">
      <p className="l-link01__ttl">目論見書と注意事項を確認してください。</p>
      {loading ? (
        <Spinner small={true} isBlack={true} short={true} />
      ) : (
        <ul className="l-link01__list">
          {prospectuses.map((prospectus) => (
            <li key={prospectus.fileName}>
              <a href={prospectus.url} target="_blank">
                <span>
                  <img src={ICON_PROSPECTUS} alt="" />
                  {prospectus.fileName}
                </span>
                {prospectus.requiresConfirmation && (
                  <>
                    {prospectus.isConfirmed ? (
                      <span className="confirmed">
                        <img src={ICON_TICK} alt="" />
                        確認済
                      </span>
                    ) : (
                      <span>未確認</span>
                    )}
                  </>
                )}
              </a>
            </li>
          ))}
        </ul>
      )}
    </StyledProspectusLinks>
  )
}

export default ProspectusLinks

const StyledProspectusLinks = styled.div`
  &.l-link01 {
    margin: 20px 0;
  }

  .l-link01__ttl {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 0 20px 20px;
  }

  .l-link01__list {
    border-top: 1px solid #c2c2c2;
  }

  .l-link01__list li {
    border-bottom: 1px solid #c2c2c2;
  }

  .l-link01__list a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 10px 10px;
  }

  .l-link01__list a:before {
    content: '';
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 8px;
    height: 8px;
    margin: auto;
    border-top: 1px solid #333;
    border-right: 1px solid #333;
    transform: rotate(45deg);
    box-sizing: border-box;
  }

  .l-link01__list span {
    display: flex;
    align-items: center;
  }

  .l-link01__list span:nth-child(1) {
    font-size: 1.4rem;
  }

  .l-link01__list span:nth-child(1) img {
    width: 40px;
    margin: 0 10px 0 0;
  }

  .l-link01__list span:nth-child(2) {
    color: #ff5f5e;
    min-width: 60px;
    display: block;
    text-align: end;
  }

  .l-link01__list span:nth-child(2) img {
    width: 12px;
    margin: 2px 5px 0 0;
  }

  .l-link01__list span.confirmed {
    color: #48be2d;
  }
`
