import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ACCOUNT_TYPE_ALL,
  ACCOUNT_TYPES,
  EXTERNAL_URL_POSITION_TABLE,
} from '../../common/utils/constants'
import { generateFundPageUrl } from '../../common/utils/fund'
import { Position } from '../../common/utils/types'
import { formatWithComma } from '../../common/utils/util'
import { useAppContext } from '../../contexts/AppContext'
import CenteredText from '../molecules/CenteredText'
import Spinner from '../molecules/Spinner'

type PositionTableProps = {
  positions: Position[]
  isLoading?: boolean
}

const PositionTable = ({ positions, isLoading }: PositionTableProps) => {
  const { abrParams } = useAppContext()
  const [displayPositions, setDisplayPositions] = useState([] as Position[])
  const [filteredPositions, setFilteredPositions] = useState(positions)
  const [rowsPerPage] = useState(5)
  const [numOfDisplayPageButton] = useState(5)
  const [numOfPages, setNumOfPages] = useState(
    Math.ceil(positions.length / rowsPerPage)
  )
  const [displayPageButtons, setDisplayPageButtons] = useState([] as number[])
  const [selectedPageIdx, setSelectedPageIdx] = useState(0)
  const [tabs, setTabs] = useState([] as string[])
  const [selectedTab, setSelectedTab] = useState('')

  useEffect(() => {
    if (selectedTab === ACCOUNT_TYPE_ALL) {
      setFilteredPositions(positions)
    } else {
      setFilteredPositions(
        positions.filter((position) => position.accountType === selectedTab)
      )
    }
  }, [selectedTab])

  useEffect(() => {
    if (positions) {
      setFilteredPositions(positions)

      if (positions.length > 0) {
        const uniqueTabs = Object.values(ACCOUNT_TYPES).filter((type) =>
          positions.find((position) => position.accountType === type)
        )
        setTabs([ACCOUNT_TYPE_ALL, ...uniqueTabs])
        setSelectedTab(uniqueTabs[0])
      }
    }
  }, [positions])

  useEffect(() => {
    if (filteredPositions && filteredPositions.length > 0) {
      const newNumOfPages = Math.ceil(filteredPositions.length / rowsPerPage)
      setDisplayPositions(filteredPositions.slice(0, rowsPerPage))
      setNumOfPages(newNumOfPages)
      setSelectedPageIdx(0)

      const maxNumOfDisplayPageButton =
        newNumOfPages > numOfDisplayPageButton
          ? numOfDisplayPageButton
          : newNumOfPages
      const displayBtns = [...Array(maxNumOfDisplayPageButton)].map(
        (_, idx) => idx
      )
      setDisplayPageButtons(displayBtns)
    } else {
      setDisplayPositions([])
      setDisplayPageButtons([])
      setNumOfPages(0)
    }
  }, [filteredPositions])

  useEffect(() => {
    const newIdx = selectedPageIdx * rowsPerPage
    setDisplayPositions(filteredPositions.slice(newIdx, rowsPerPage + newIdx))

    if (
      selectedPageIdx > displayPageButtons[displayPageButtons.length - 1] &&
      selectedPageIdx < numOfPages
    ) {
      const newBtns = displayPageButtons
        .filter((pageIdx) =>
          pageIdx + 1 + numOfDisplayPageButton > numOfPages ? false : true
        )
        .map((pageIdx) => pageIdx + numOfDisplayPageButton)
      setDisplayPageButtons(newBtns)
    } else if (
      selectedPageIdx < displayPageButtons[0] &&
      selectedPageIdx >= 0
    ) {
      const newBtns = [] as number[]
      for (let i = 0; i < numOfDisplayPageButton; i++) {
        newBtns.push(selectedPageIdx - (numOfDisplayPageButton - i) + 1)
      }
      setDisplayPageButtons(newBtns)
    }
  }, [selectedPageIdx])

  const onPrevClicked = () => {
    if (selectedPageIdx > 0) {
      setSelectedPageIdx(selectedPageIdx - 1)
    }
  }

  const onNextClicked = () => {
    if (selectedPageIdx < numOfPages - 1) {
      setSelectedPageIdx(selectedPageIdx + 1)
    }
  }

  return isLoading ? (
    <Spinner isBlack />
  ) : (
    <StyledPositionTable className="position-table">
      <StyledTabs>
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`tab ${selectedTab === tab ? 'active' : ''}`}
            onClick={() => {
              setSelectedTab(tab)
            }}
          >
            <span>{tab}</span>
          </li>
        ))}
      </StyledTabs>
      {!displayPositions || displayPositions.length === 0 ? (
        <CenteredText>保有資産はありません</CenteredText>
      ) : (
        <>
          <StyledTable>
            <thead>
              <tr>
                <th>ファンド名</th>
                <th>分類</th>
                <th>
                  評価額
                  <br />
                  評価損益
                </th>
              </tr>
            </thead>
            <tbody>
              {displayPositions.map((position, idx) => (
                <tr key={`position-${idx}`}>
                  <td>
                    <a
                      href={generateFundPageUrl(abrParams, position.fundCode)}
                      target="_blank"
                    >
                      {position.fundName}
                    </a>
                  </td>
                  <td>{position.accountType}</td>
                  <td>
                    {formatWithComma(position.evaluationPrice)}円
                    <br />
                    <span
                      className={
                        position.evaluationProfit > 0
                          ? 'plus'
                          : position.evaluationProfit < 0
                          ? 'minus'
                          : ''
                      }
                    >
                      {position.evaluationProfit > 0 ? '+' : ''}
                      {formatWithComma(position.evaluationProfit)}円
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <StyledPagination>
            <p
              className={`prev ${selectedPageIdx === 0 ? 'disabled' : ''}`}
              onClick={onPrevClicked}
            >
              <span></span>
            </p>
            <ul>
              {displayPageButtons &&
                displayPageButtons.map((num) => (
                  <li
                    key={`page-idx-${num}`}
                    className={`btn-page ${displayPageButtons.length} ${
                      selectedPageIdx === num ? 'current' : ''
                    }`}
                    onClick={() => {
                      setSelectedPageIdx(num)
                    }}
                  >
                    <span>{num + 1}</span>
                  </li>
                ))}
            </ul>
            <p
              className={`next ${
                selectedPageIdx + 1 >= numOfPages ? 'disabled' : ''
              }`}
              onClick={onNextClicked}
            >
              <span></span>
            </p>
          </StyledPagination>
        </>
      )}
      <StyledText>
        ※投資信託残高は、お客さまの保有する投資信託の評価額（前日基準価額より計算。上記明細においても同じ。）の合算額を表示しております。
        <br />
        ※保有投資信託の解約、注文訂正または取消しを行う場合は、
        <a href={EXTERNAL_URL_POSITION_TABLE} target="_blank">
          こちら
        </a>
      </StyledText>
    </StyledPositionTable>
  )
}

export default PositionTable

const StyledPositionTable = styled.div`
  padding: 0 var(--app-right-padding) 20px var(--app-left-padding);
`

const StyledTabs = styled.ul`
  & {
    display: flex;
    margin: 0 0 16px;
    li {
      list-style: none;
      span {
        display: block;
        padding: 10px;
        border-bottom: 2px solid #fff;
        cursor: pointer;
      }
    }
    .active {
      span {
        border-bottom: 3px solid #ff6400;
      }
    }
  }
`
const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 8px;
  thead {
    background: #666;
    th {
      color: #fff;
      text-align: center;
      &:nth-child(1) {
        width: 58%;
      }
      &:nth-child(2) {
        width: 14%;
      }
      &:nth-child(3) {
        width: 28%;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background: #eee;
      }
    }
    td {
      &:nth-child(1) {
        a {
          color: #ff6400;
          text-decoration: underline;
        }
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
  th,
  td {
    font-size: 1.1rem;
    font-weight: normal;
    padding: 5px;
    border: 1px solid #525252;
  }
  .plus {
    color: #48be2d;
  }
  .minus {
    color: #ff5f5e;
  }
`

const StyledPagination = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    p {
      span {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        box-sizing: border-box;
        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
          box-sizing: border-box;
        }
      }
      &.prev {
        span {
          background: #999;
          padding: 0 0 0 2px;
          &:before {
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
          }
        }

        &.disabled span {
          background: #eee;
          padding: 0 0 0 2px;
          &:before {
            border-left: 2px solid #999;
            border-bottom: 2px solid #999;
          }
        }
      }
      &.next {
        span {
          background: #999;
          padding: 0 2px 0 0;
          &:before {
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
          }
        }

        &.disabled span {
          background: #eee;
          padding: 0 2px 0 0;
          &:before {
            border-top: 2px solid #999;
            border-right: 2px solid #999;
          }
        }
      }
    }
    ul {
      display: flex;
      li {
        cursor: pointer;
        margin: 0 4px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-size: 1.4rem;
          color: #999;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
      .current {
        span {
          color: #ff6400;
          border: 1px solid #ff6400;
        }
      }
    }
  }
`

const StyledText = styled.p`
  a {
    color: #ff6400;
    text-decoration: underline;
  }
`
