import {
  AbrParams,
  Fund,
  PurchaseInfo,
  ReservePlanInfo,
} from './common/utils/types'
import AppContext from './contexts/AppContext'
import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Router } from './Router'
import NetworkAlertModal from './components/molecules/NetworkAlertModal'

const App = () => {
  const [fund, setFund] = useState({} as Fund)
  const [purchaseInfo, setPurchaseInfo] = useState({} as PurchaseInfo)
  const [reservePlanInfo, setReservePlanInfo] = useState({} as ReservePlanInfo)
  const [abrParams, setAbrParams] = useState({} as AbrParams)
  const [selectedPurchaseType, setSelectedPurchaseType] = useState(
    (null as unknown) as string
  )

  const GlobalStyle = createGlobalStyle<{ ios: boolean; android: boolean }>`
  html {
    --app-left-padding: ${(props) => (props.ios ? '12px' : '20px')};
    --app-right-padding: ${(props) => (props.ios ? '12px' : '20px')};
    --accordion-left-padding: ${(props) => (props.ios ? '12px' : '20px')};
    --accordion-right-padding: ${(props) => (props.ios ? '12px' : '20px')};
    --accordion-top-padding: ${(props) => (props.ios ? '12px' : '8px')};
    --accordion-bottom-padding: ${(props) => (props.ios ? '12px' : '8px')};
    --accordion-title-font-weight: ${(props) =>
      props.android ? '500' : 'normal'};
  }`

  const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  const isAndroid = /(android)/i.test(navigator.userAgent)

  return (
    <AppContext.Provider
      value={{
        fund,
        setFund,
        purchaseInfo,
        setPurchaseInfo,
        reservePlanInfo,
        setReservePlanInfo,
        abrParams,
        setAbrParams,
        selectedPurchaseType,
        setSelectedPurchaseType,
      }}
    >
      <StyledApp className="App">
        <GlobalStyle ios={isIOS} android={isAndroid} />
        <NetworkAlertModal />
        <Router />
      </StyledApp>
    </AppContext.Provider>
  )
}

export default App

const StyledApp = styled.div``
