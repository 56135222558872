import React, { useState } from 'react'
import styled from 'styled-components'

type AccordionProps = {
  title: string
  children: React.ReactNode
  shownOnDefault?: boolean
  boldTitle?: boolean
  isHiddenState?: boolean
  setHiddenState?: React.Dispatch<React.SetStateAction<boolean>>
}
const Accordion = ({
  title,
  children,
  shownOnDefault,
  boldTitle,
  isHiddenState,
  setHiddenState,
}: AccordionProps) => {
  const [isHidden, setHidden] =
    isHiddenState !== undefined && setHiddenState !== undefined
      ? [isHiddenState, setHiddenState]
      : useState(!shownOnDefault)

  const toggleAccordion = () => {
    setHidden(!isHidden)
  }

  return (
    <StyledAccordion
      isHidden={isHidden}
      boldTitle={boldTitle}
      className="accordion01__item"
    >
      <div className="l-accordion01__head in-active" onClick={toggleAccordion}>
        {title}
      </div>
      {!isHidden && (
        <div className="l-accordion01__body in-active">{children}</div>
      )}
    </StyledAccordion>
  )
}

export default Accordion

const StyledAccordion = styled.div<{ isHidden?: boolean; boldTitle?: boolean }>`
  .l-accordion01 {
    &__head {
      margin-top: -1px;
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
      position: relative;
      font-size: 1.6rem;
      padding: var(--accordion-top-padding) 30px var(--accordion-bottom-padding)
        var(--accordion-left-padding);
      font-weight: ${(props) =>
        props.boldTitle ? `bold` : `var(--accordion-title-font-weight)`};
      &:before {
        content: '';
        position: absolute;
        right: var(--accordion-right-padding);
        bottom: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        ${(props) =>
          props.isHidden
            ? `border-top: 1px solid #333;
        border-left: 1px solid #333;
        top: 0;`
            : `border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        top: 5px;`}
        transform: rotate(45deg);
        box-sizing: border-box;
      }
      &.in-active {
        &:before {
          transform: rotate(225deg);
        }
      }
    }
    &__body {
      display: none;
      &.in-active {
        display: block;
      }
    }
  }
`
