import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CenteredButton from '../atoms/CenteredButton'

const NetworkAlertModal = () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const handleOffline = () => {
      setShowModal(true)
    }
    const handleOnline = () => {
      setShowModal(false)
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('offline', handleOnline)
    }
  }, [])

  const [alertMessage] = useState(
    '通信できません。アクセス制限設定や電波状態などをご確認ください。\n\n当行のメンテンナス情報などについてはウェブサイトをご覧ください。'
  )

  const onCloseBtnClicked = () => {
    setShowModal(false)
  }

  return alertMessage && showModal ? (
    <StyledModal
      id="alert-modal"
      className={`${showModal ? 'modal__open' : 'modal__close'}`}
    >
      <div className="modal__overlay"></div>
      <div className="modal__inner">
        <div className="modal__content">
          <p className="alert">エラー</p>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: alertMessage }}
          ></div>
          <CenteredButton text={'閉じる'} handleClick={onCloseBtnClicked} />
        </div>
      </div>
    </StyledModal>
  ) : (
    <></>
  )
}

export default NetworkAlertModal

const StyledModal = styled.div`
  font-size: 1.2em;
  display: none;
  position: absolute;
  overflow-y: hidden;
  overscroll-behavior: contain;

  p.alert {
    font-weight: bold;
    font-size: 1.3em;
    margin: 10px 0;
  }

  .content {
    white-space: break-spaces;
  }

  &.modal__open {
    display: block;
  }
  .modal {
    &__overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(17, 24, 29, 0.1);
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
    }
    &__inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 3;
      overflow: auto !important;
      background: white;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      padding: 20px;
      max-width: 790px;
      border-radius: 4px;
      height: fit-content;
      overflow: scroll;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
    }
    &__content {
      width: 100%;
      height: inherit;
      max-width: 920px;
      box-sizing: border-box;
      background: white;
      position: relative;
      @include hack-edge {
        &::after {
          content: '';
          display: block;
          padding: 0 0 80px;
        }
      }
    }
  }
`
