import axios from 'axios'
import config from '../../config/config'
import {
  FundWalletResponseType,
  PositionListResponseType,
  ProspectusResponseType,
  PurchaseResponseType,
  StockFundResponseType,
  OrderCompletionResponseType,
  ErrorResponseType,
  FundRankingsResponseType,
  AuthRequestUrlResponseType,
  ReservePlanCompletionResponseType,
  ReservePlanApplicationResponseType,
  PontaPointsResponseType,
} from './types'
import { ROUTE_MY_FUND_LIST } from './constants'

export const initApiCallerWithAbrParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const abrParam1 = urlParams.get('param1') || ''
  const abrParam2 = urlParams.get('param2') || ''
  const abrParam3 = urlParams.get('param3') || ''
  initApiCaller(abrParam1, abrParam2, abrParam3)
  return {
    param1: abrParam1,
    param2: abrParam2,
    param3: abrParam3,
  }
}

const initApiCaller = (
  abrParam1: string,
  abrParam2: string,
  abrParam3: string
) => {
  axios.defaults.baseURL = config.API_URL

  if (abrParam1 && abrParam2 && abrParam3) {
    axios.defaults.headers.common['abr-param1'] = abrParam1
    axios.defaults.headers.common['abr-param2'] = abrParam2
    axios.defaults.headers.common['abr-param3'] = abrParam3
  }
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const isMyFundPage = window.location.pathname.includes(ROUTE_MY_FUND_LIST)
    const shouldRedirect = !isMyFundPage
    if (!error || !error.response) {
      shouldRedirect && (window.location.pathname = '/auth/failure')
    } else {
      const statusCode = error.response.status
      if ([401, 403].includes(statusCode)) {
        shouldRedirect && (window.location.pathname = '/auth/failure')
      } else {
        shouldRedirect && (window.location.pathname = '/server/failure')
      }
    }
    return Promise.reject(error)
  }
)

export const parallelRun = (...promises: any[]) => {
  return Promise.all(
    promises
      .filter((promise) => promise)
      .map((promise) =>
        promise.catch((err: any) => {
          throw err
        })
      )
  )
}

export const fetchAuthUrl = async (): Promise<AuthRequestUrlResponseType> => {
  const param1 = axios.defaults.headers.common['abr-param1']
  const param2 = axios.defaults.headers.common['abr-param2']
  const param3 = axios.defaults.headers.common['abr-param3']
  const url = `/auth/request-url?param1=${param1}&param2=${param2}&param3=${param3}`
  return await axios.get(url, {
    transformRequest: (_, headers) => {
      delete headers.common['abr-param1']
      delete headers.common['abr-param2']
      delete headers.common['abr-param3']
    },
  })
}

export const fetchMyFunds = async (): Promise<PositionListResponseType> => {
  const url = `/my-assets/funds`
  return await axios.get(url, {
    validateStatus: (status: any) => {
      return status >= 200 && status < 600
    },
  })
}

export const fetchFund = async (
  fundCode: string
): Promise<StockFundResponseType> => {
  const url = `/funds/${fundCode}`
  return await axios.get(url)
}

export const fetchProspectus = async (
  fundCode: string,
  investmentType: number,
  callbackUrl: string,
  confirmCode?: string
): Promise<ProspectusResponseType> => {
  const url = `/funds/${fundCode}/prospectus?investmentType=${investmentType}&callbackUrl=${callbackUrl}${
    confirmCode ? `&confirmCode=${confirmCode}` : ''
  }`
  return await axios.get(url)
}

export const fetchFundWallet = async (
  fundCode: string,
  investmentType: number
): Promise<FundWalletResponseType> => {
  const url = `/funds/${fundCode}/wallet?investmentType=${investmentType}`
  return await axios.get(url)
}

export const fetchFundOrder = async (
  orderId: string
): Promise<OrderCompletionResponseType> => {
  const url = `/fund-orders/${orderId}`
  return await axios.get(url)
}

export const placePurchaseOrder = async (
  fundCode: string,
  price: number,
  accountType: number,
  investmentType: number,
  confirmCode: string,
  requestId: string,
  pointUseAmount: number | null
): Promise<PurchaseResponseType | ErrorResponseType> => {
  const url = `/fund-orders:buy`
  const postObj: any = {
    fundCode: fundCode,
    price: price,
    accountType: accountType,
    investmentType: investmentType,
    confirmCode: confirmCode,
    requestId: requestId,
  }
  if (pointUseAmount) {
    postObj['pointUseAmount'] = pointUseAmount
  }
  return await axios.post(url, postObj, {
    validateStatus: (status: any) => {
      return status >= 200 && status < 600
    },
  })
}

export const fetchFundRanking = async (): Promise<FundRankingsResponseType> => {
  return await axios.get(config.FUND_RANKING_URL)
}

export const fetchReservePlan = async (
  applicationNo: string
): Promise<ReservePlanCompletionResponseType> => {
  const url = `/fund-reserves/${applicationNo}`
  return await axios.get(url)
}

export const placeReservePlanApplication = async (
  fundCode: string,
  accountType: number,
  investmentType: number,
  paymentType: number,
  specifiedAmount: number,
  specifiedDate: number,
  increaseMonth1: number | null,
  increaseMonth2: number | null,
  increaseAmount: number | null,
  confirmCode: string,
  requestId: string
): Promise<ReservePlanApplicationResponseType | ErrorResponseType> => {
  const url = `/fund-reserves:plan`
  const postObj: any = {
    fundCode: fundCode,
    accountType: accountType,
    investmentType: investmentType,
    paymentType: paymentType,
    specifiedAmount: specifiedAmount,
    specifiedDate: specifiedDate,
    confirmCode: confirmCode,
    requestId: requestId,
  }
  if (increaseMonth1) {
    postObj['increaseMonth1'] = increaseMonth1
  }
  if (increaseMonth2) {
    postObj['increaseMonth2'] = increaseMonth2
  }
  if (increaseAmount) {
    postObj['increaseAmount'] = increaseAmount
  }
  return await axios.post(url, postObj, {
    validateStatus: (status: any) => {
      return status >= 200 && status < 600
    },
  })
}

export const fetchPontaPoints = async (): Promise<PontaPointsResponseType> => {
  const url = '/point/ponta'
  return await axios.get(url)
}
