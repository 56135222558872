import React from 'react'
import ICON_FAIL from '../../assets/images/icon_auth_failure.svg'

const UnauthorizedPage = () => (
  <div>
    <div className="l-login01">
      <div className="l-login01__head">
        <img src={ICON_FAIL} alt="" />
      </div>
      <div className="l-login01__body">
        <p>
          auカブコム証券口座の認証を
          <br />
          中断しました。
        </p>
        <p>
          本画面を閉じ、
          <br />
          再度、auカブコム証券の認証手続きを行ってください。
        </p>
      </div>
    </div>
  </div>
)

export default UnauthorizedPage
