import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../atoms/SectionHeader'

const FundDisclaimer = () => {
  return (
    <div className="fund-disclaimer">
      <SectionHeader title="注記事項" />
      <StyledFundDisclaimer>
        <div className="content-txt">
          <p>
            ※「投資信託」ランキングは、特定の投資信託の推奨、勧誘を目的としたものではありません。お取引の際は、約款・規定集、契約締結前交付書面、投資信託説明書（交付目論見書）、お取扱商品の重要事項の説明等をよくお読みいただき、商品特性やリスク及びお取引ルール等をよくご理解の上、銘柄選択、投資時期、投資スタイル等、投資の最終決定はご自身のご判断とご責任で行ってください。
          </p>
          <p>
            ※販売金額ランキングと積立設定金額ランキングは、auカブコム証券における国内籍公募型投資信託の買付約定金額を各対象期間で集計したものであり、前営業日までの約定分について毎営業日0：00頃にデータが更新されます。単位型投資信託、外国籍投資信託、公社債投資信託、当初募集期間中の投資信託等、一部ランキングの対象外となる投資信託があります。
          </p>
          <p>※リターンランキングは、毎営業日0：00頃にデータを更新します。</p>
          <p>
            ※分配金利回りランキングは毎営業日午前5：00頃にデータを更新します。分配金利回りは直近過去1年間分配金実績値（税込）÷前営業日基準価額で計算されます。
          </p>
          <p>
            ※リターンランキングと分配金利回りランキングは過去の一定期間の実績に基づいて作成したもので、将来の投資成果や将来の市況環境の変動等を保証するものではありません。
          </p>
          <p>
            ※純資産増加額ランキングは、毎営業日午前5：00頃にデータを更新します。
          </p>
          <p>
            ※ランキング情報に関する知的財産権は、auカブコム証券株式会社、QUICK株式会社に帰属します。ランキング情報は信頼できると考えられる情報源から作成しておりますが、その正確性・完全性を保証するものではなく、これらの情報によって生じた損害について原因の如何を問わず一切の責任を負いません。
          </p>
          <p>
            ※当該ランキングには、新規募集期間中の銘柄等、一部ランキング対象外となる銘柄があります。
          </p>
          <p>
            ※当該ランキングには、「毎月分配型投資信託」または「通貨選択型投資信託」が含まれております。お取引の前に必ず「毎月分配型投資信託の収益分配金に関するご説明」をご確認ください。お取扱商品の重要事項の説明等をよくお読みいただき、商品特性やリスク及びお取引ルール等をよくご理解の上、銘柄選択、投資時期、投資スタイル等、投資の最終決定はご自身のご判断とご責任で行ってください。
          </p>
        </div>
      </StyledFundDisclaimer>
    </div>
  )
}
export default FundDisclaimer

const StyledFundDisclaimer = styled.div`
  .content-txt {
    background: #eee;
    padding: 20px var(--app-left-padding);
    p {
      margin: 0 0 16px;
      &:last-child {
        margin: 0;
      }
    }
  }
`
