import React, { useCallback, useEffect, useState } from 'react'
import ICON_CHART from '../../assets/images/icon_chart.svg'
import ICON_BUILDING from '../../assets/images/icon_building.png'
import Accordion from '../organisms/Accordion'
import styled from 'styled-components'
import { useAppContext } from '../../contexts/AppContext'
import { fetchFund } from '../../common/utils/fetcher'
import { useHistory, useParams } from 'react-router-dom'
import {
  PURCHASE_STEPS,
  RESERVE_PLAN_STEPS,
  NO_DATA,
  SIGN_MINUS,
  PURCHASE_TYPES,
  PURCHASE_TYPE_SPOT,
  PURCHASE_TYPE_RESERVE,
} from '../../common/utils/constants'
import Spinner from '../molecules/Spinner'
import { mapStockFundResponseToFund } from '../../common/utils/fund'
import FundTitle from '../molecules/FundTitle'
import { PurchaseInfo, ReservePlanInfo } from '../../common/utils/types'
import PurchaseWizardButtons from '../../components/molecules/PurchaseWizardButtons'

const IndividualFundPage = () => {
  const { fundCode } = useParams<{ fundCode: string }>()
  const history = useHistory()
  const {
    fund,
    setFund,
    selectedPurchaseType,
    setPurchaseInfo,
    setReservePlanInfo,
    setSelectedPurchaseType,
  } = useAppContext()
  const [isReserve, setReserve] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const loadData = useCallback(async () => {
    const res = await fetchFund(fundCode)
    const fundObj = mapStockFundResponseToFund(res)
    setFund(fundObj)
    setReserve(
      fundObj
        ? fundObj.isReserve && fundObj.buyingInfo.accumulating !== null
        : false
    )
    setLoading(false)
  }, [])

  useEffect(() => {
    setPurchaseInfo({} as PurchaseInfo)
    setReservePlanInfo({} as ReservePlanInfo)
    loadData()
  }, [])

  const onNextButtonClicked = () => {
    if (selectedPurchaseType === PURCHASE_TYPE_SPOT) {
      history.push(PURCHASE_STEPS['1'].route.replace(':fundCode', fundCode))
    } else if (selectedPurchaseType === PURCHASE_TYPE_RESERVE) {
      history.push(RESERVE_PLAN_STEPS['1'].route.replace(':fundCode', fundCode))
    }
  }

  return (
    <div className="individual-fund">
      {isLoading ? (
        <Spinner isBlack />
      ) : (
        <>
          <div>
            <FundTitle fundCode={fund.fundCode} fundName={fund.fundName} />

            <StyledFundTopSection>
              <dl>
                <dt>
                  <img src={ICON_CHART} alt="基準価額(前日比)" />
                  基準価額(前日比)
                </dt>
                <dd>
                  {fund.standardPrice}円
                  <span
                    className={
                      fund.changePercent.startsWith(SIGN_MINUS)
                        ? '-negative'
                        : ''
                    }
                  >
                    　({fund.changePercent}%)
                  </span>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src={ICON_BUILDING} alt="運用会社" />
                  運用会社
                </dt>
                <dd>{fund.investmentCompany || NO_DATA}</dd>
              </dl>
            </StyledFundTopSection>

            <Accordion title="お申込み、解約情報">
              <StyledInfoList>
                <dl>
                  <dt>お申込み手数料</dt>
                  <dd>
                    {fund.buyingInfo && (
                      <>
                        【買付】
                        {fund.buyingInfo.distributing && (
                          <>
                            (一般型) {fund.buyingInfo.distributing?.commission}
                            <br />
                          </>
                        )}
                        {fund.buyingInfo.accumulating && (
                          <>
                            (累投型) {fund.buyingInfo.accumulating?.commission}
                            <br />
                          </>
                        )}
                      </>
                    )}
                    {fund.sellingInfo && (
                      <>
                        【売却】
                        {fund.sellingInfo.distributing && (
                          <>
                            (一般型) {fund.sellingInfo.distributing?.commission}
                            <br />
                          </>
                        )}
                        {fund.sellingInfo.accumulating && (
                          <>
                            (累投型) {fund.sellingInfo.accumulating?.commission}
                          </>
                        )}
                      </>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>お申込み単位</dt>
                  <dd>
                    {fund.buyingInfo.distributing && (
                      <>
                        (一般型){fund.buyingInfo.distributing?.minTradeAmount}
                        {fund.buyingInfo.distributing?.tradeAmountUnit}以上
                        {fund.buyingInfo.distributing?.tradeUnitAmount}
                        {fund.buyingInfo.distributing?.tradeAmountUnit}単位
                        <br />
                      </>
                    )}
                    {fund.buyingInfo.accumulating && (
                      <>
                        (累投型){fund.buyingInfo.accumulating?.minTradeAmount}
                        {fund.buyingInfo.accumulating?.tradeAmountUnit}以上
                        {fund.buyingInfo.accumulating?.tradeUnitAmount}
                        {fund.buyingInfo.accumulating?.tradeAmountUnit}単位
                      </>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>解約単位</dt>
                  <dd>
                    {fund.sellingInfo.distributing && (
                      <>
                        (一般型){fund.sellingInfo.distributing?.minTradeAmount}
                        {fund.sellingInfo.distributing?.tradeAmountUnit}以上
                        {fund.sellingInfo.distributing?.tradeUnitAmount}
                        {fund.sellingInfo.distributing?.tradeAmountUnit}単位
                        <br />
                      </>
                    )}
                    {fund.sellingInfo.accumulating && (
                      <>
                        (累投型){fund.sellingInfo.accumulating?.minTradeAmount}
                        {fund.sellingInfo.accumulating?.tradeAmountUnit}以上
                        {fund.sellingInfo.accumulating?.tradeUnitAmount}
                        {fund.sellingInfo.accumulating?.tradeAmountUnit}単位
                      </>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>お申込み締切時間</dt>
                  <dd>
                    【買付】{fund.buyingDeadline}
                    <br />
                    【売却】{fund.sellingDeadline}
                  </dd>
                </dl>
              </StyledInfoList>
            </Accordion>

            <Accordion title="投資信託のリスクおよび手数料等に関するご注意事項">
              <StyledDisclaimerContent>
                <p>
                  {`・投資信託は、元本や利益（分配金を含む）を保証するものではありません。組み入れた金融商品の値動き等により基準価額が変動するため、投資元本を割り込むおそれがあります。
・投資信託は銘柄により、信託報酬、解約手数料、その他手数料等を要するものがありますが、銘柄毎に要件・料率等が異なりますので表示できません。手数料等は、当社ホームページの目論見書および目論見書補完書面等にてご確認ください。
・投資信託は、銘柄により運用継続が困難と委託会社が判断する場合に償還期限が繰上げとなる可能性があります。
・クローズド期間のある投資信託については、クローズド期間中は原則として換金の請求を受付けることができませんのでご留意ください。
・ファンド・オブ・ファンズは、複数の投資信託に投資をおこなう投資信託であるため、投資先の投資信託が徴収する信託報酬も間接的に負担しています。詳細は、当社ホームページ等にて各銘柄の目論見書や契約締結前交付書面等をよくお読みください。 
・前金商品（投資信託等）をご購入の場合には、お申込みから約定までの間に現金買付余力がないと注文が取消になる場合があります。株式発注金額は、約定・未約定にかかわらず優先して拘束されますので、前金商品（投資信託等）の申込条件を満たさない場合もあります。前金商品（投資信託等）をお申込みの際は、オンライントレード規定・目論見書補完書面に記載するご注意事項や申込画面のご注意事項を十分にご確認ください。

[主な投資対象が国内株式である投資信託の注意事項]
・組み入れた株式の値動き等により基準価額が上下しますので、株価の下落で投資元本を割り込むことがあります。また、組み入れた株式の発行体の経営・財務状況の変化およびそれらに関する外部評価の変化等で、基準価額は変動します。

[主な投資対象が株式・一般債にわたっており、かつ、円建・外貨建の双方にわたっている投資信託の注意事項]
・組み入れた株式および債券の値動き等により基準価額が上下しますので、株式の値下がり・債券の値下がりで投資元本を割り込むことがあります。
・投資対象国の市場動向や金利動向、また、対円レートの状況で保有資産の損益が変動しますので、投資信託内の資産配分の状況で基準価額の巧拙が分かれます。
・一般的に、債券投資の部分は発行国の金利上昇に伴い下落します。また、投資対象国の通貨に対して円高となった場合は、投資対象資産の円換算の金額は減少します。
・組み入れた国内外の株式や債券の発行体の経営・財務状況の変化およびそれらに関する外部評価の変化等で、基準価額は変動します。
・投資信託が投資する外貨建ての公社債や短期金融商品の発行体の財務状況、経営不振等により支払い不能（債務不履行）が発生した場合は基準価額が下落する要因となります。

[主な投資対象が外貨建の公社債や短期金融商品である投資信託の注意事項]
・組み入れた外貨建ての公社債や短期金融商品の値動きや為替相場の変更等の影響により上下するため、値下がりに伴う基準価額の下落で投資元本の損失が生じることがあります。一般に投資対象国の金利が上昇した場合は、組み入れた有価証券は値下がりし基準価額が下落する要因になります。また、円レートが投資対象国の通貨に対して円高に推移した場合、円換算の金額は減少します。
・組み入れた海外債券の発行体の経営・財務状況の変化およびそれらに関する外部評価の変化等で、基準価額は変動します。
・投資信託が投資する外貨建ての公社債や短期金融商品の発行体の財務状況、経営不振等により支払い不能（債務不履行）が発生した場合は基準価額が下落する要因となります。

[通貨選択型の投資信託に関するご注意事項]
・通貨選択型の投資信託は、株式や債券等といった投資対象資産に加えて、為替ヘッジの対象となる円以外の通貨も選択することができるよう設計された投資信託です。取引対象通貨が円以外の場合には、当該取引対象通貨の対円での為替リスクが発生することに留意が必要です。

[毎月分配型投資信託の収益分配金に関するご注意事項]
・投資信託の分配金は、預貯金の利息とは異なり、投資信託の純資産から支払われますので分配金が支払われると、その金額相当分、基準価額は下がります。なお、分配金の有無や金額は確定したものではありません。

[レバレッジ投信投資信託に関するご注意事項]
・レバレッジ投資信託は対象となる指数に対して一定倍率での投資効果を目指して運用されるため、対象となる指数が下落した場合、当該指数に比べて大きな損失が生じる可能性があります。
・レバレッジ投資信託がブル・ベア型である場合は、ベア型については、対象となる指数に対して一定倍率反対となる投資効果を目指して運用されるため、対象となる指数が上昇した場合に、当該指数に比べて大きな損失が生じる可能性があります。
・ブル・ベア型の投資信託は、対象となる指数・相場の値動きに対し、2倍もしくはそれ以上の値動きとなるよう運用される商品が多い投資信託です。当該投資信託がベア型である場合は、対象となる指数に対して一定倍率反対となる投資効果を目指して運用されるため、対象となる指数が上昇した場合、当該指数に比べて大きな損失が生じる可能性があります。

・当コンテンツにおける各情報は、情報の提供のみを目的としており、投資または特定の商品を勧誘するものではありません。
・当コンテンツに掲載されている情報によって、何らかの損害を被った場合でも、auカブコム証券および株式会社QUICKは一切責任を負うものではありません。
・投資対象、投資機会の選択などの投資に係る最終決定は、お客さまご自身の判断でなさるようにお願いいたします。
・当コンテンツに掲載されている内容に関しては、万全を期しておりますが、auカブコム証券はその内容および情報の正確性、完全性または適時性について保証を行って おらず、また、いかなる責任を持つものでもありません。
・当コンテンツに掲載されている運用実績は過去のものであり、将来の運用成果等を保証・推測するものではありません。 分配金額は収益分配方針に基づいて委託会社が決定します。あらかじめ一定の額の分配をお約束するものではありません。分配金が支払われない場合もあります。
・掲載している銘柄を含めて、当コンテンツの記載内容は、予告なしに変更されます。
・このページの情報は株式会社QUICKから提供されています。株式会社QUICKが信頼できると判断したデータにより作成しましたが、その正確性、安全性等 について保証するものではありません。
・トータルリターン四半期、累積収益グラフ、チャートにおける分配金込み（受取）と分配金込み（再投資）は株式会社QUICKのデータを元に当社独自で算出しております。その正確性、安全性等 について保証するものではありません。
・著作権等の知的所有権その他一切の権利はauカブコム証券、並びに株式会社QUICK帰属し、許可なく複製、転載、引用することを禁じます。`}
                </p>
              </StyledDisclaimerContent>
              <hr />
            </Accordion>

            <StyledPurchaseGuide>
              <p>
                ※この先のサービスはauカブコム証券が提供する投資信託サービスになります。
              </p>
              <br />
              <br />
              <p>
                <strong>投資信託の取引種別を選択してください。</strong>
              </p>
            </StyledPurchaseGuide>

            <ul className="l-link03__list purchase-type-buttons">
              {PURCHASE_TYPES.map((purchaseType) => (
                <li
                  key={purchaseType}
                  className={`
                ${purchaseType === selectedPurchaseType ? 'in-active' : ''} ${
                    purchaseType === PURCHASE_TYPE_RESERVE && !isReserve
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <button
                    disabled={
                      purchaseType === PURCHASE_TYPE_RESERVE && !isReserve
                    }
                    onClick={() => {
                      setSelectedPurchaseType(purchaseType)
                    }}
                  >
                    <span>{purchaseType}</span>
                  </button>
                </li>
              ))}
            </ul>

            <PurchaseWizardButtons
              disabled={!selectedPurchaseType || isLoading}
              handleNextClick={onNextButtonClicked}
              hidePreviousButton={true}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default IndividualFundPage

const StyledInfoList = styled.div`
  & {
    background: #eee;
    padding: 20px var(--app-left-padding);
    dl {
      margin: 0 0 16px;
      &:last-child {
        margin: 0;
      }
    }
    dt {
      font-weight: bold;
      margin: 0 0 8px;
    }
    dd {
      text-align: right;
    }
  }
`

const StyledFundTopSection = styled.div`
  & {
    margin: 20px var(--app-left-padding);
  }

  dl {
    margin: 0 0 16px;
  }

  dl:last-child {
    margin: 0;
  }

  dt {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin: 0 0 8px;
  }

  dd {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: right;
  }

  img {
    width: 28px;
    margin: 0 5px 0 0;
  }

  span {
    display: block;
    font-size: 1.2rem;
  }

  span:not(.-negative) {
    color: #48be2d;
  }

  span.-negative {
    color: red;
  }
`

const StyledDisclaimerContent = styled.div`
  & {
    margin: 20px var(--app-left-padding);
    white-space: pre-wrap;
    a {
      color: #ff6400;
      text-decoration: underline;
    }
  }
`

const StyledStockLink = styled.div`
  & {
    max-width: 280px;
    margin: -10px auto 20px;
    a {
      color: #ff6400;
      text-decoration: underline;
    }
  }
`

const StyledPurchaseGuide = styled.div`
  margin: 20px var(--app-left-padding);
`
