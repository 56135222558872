import React, { useCallback, useEffect, useState } from 'react'
import ProgressStepBar from '../../components/organisms/ProgressStepBar'
import { useHistory, useParams } from 'react-router-dom'
import {
  CODE_ORDER_SUCCESS,
  PURCHASE_STEP_ORDER_CONFIRMATION,
  ROUTE_INVESTMENT_AMOUNT,
  ROUTE_ORDER_COMPLETION,
  ORDER_RESULT_MAPPING,
  URL_PROSPECTUS_CALLBACK,
  ROUTE_AUTH_FAILURE,
} from '../../common/utils/constants'
import { useAppContext } from '../../contexts/AppContext'
import {
  fetchFund,
  fetchFundOrder,
  fetchProspectus,
  parallelRun,
  placePurchaseOrder,
} from '../../common/utils/fetcher'
import Spinner from '../../components/molecules/Spinner'
import OrderDetails from '../../components/organisms/OrderDetails'
import {
  formatStandardDate,
  formatWithComma,
  generateRequestId,
} from '../../common/utils/util'
import {
  ErrorResponseType,
  Prospectus,
  ProspectusResponseType,
  PurchaseResponseType,
  StockFundResponseType,
} from '../../common/utils/types'
import { mapStockFundResponseToFund } from '../../common/utils/fund'
import config from '../../config/config'
import { createProspectusList } from '../../common/utils/prospectus'
import styled from 'styled-components'

const OrderConfirmationPage = () => {
  const {
    fundCode,
    accountType,
    investmentType,
    investmentAmount,
    requestId,
  } = useParams() as {
    fundCode: string
    accountType: string
    investmentType: string
    investmentAmount: string
    requestId: string
  }

  const [disabled, setDisabled] = useState(true)

  const [isOrdering, setOrdering] = useState(false)

  const [isLoading, setLoading] = useState(true)

  const [errorMessage, setErrorMessaqe] = useState('')

  const [prospectuses, setProspectuses] = useState([] as Prospectus[])

  const {
    fund,
    purchaseInfo,
    setFund,
    setPurchaseInfo,
    abrParams,
  } = useAppContext()

  const history = useHistory()

  const loadData = useCallback(
    async (
      investmentTypeFromParams?: number,
      accountTypeFromParams?: number,
      investmentAmountFromParams?: string,
      requestIdFromParams?: string
    ) => {
      setLoading(true)

      const investmentType =
        investmentTypeFromParams || purchaseInfo.investmentType
      const accountType = accountTypeFromParams || purchaseInfo.accountType
      const investmentAmount = investmentAmountFromParams || purchaseInfo.price
      const requestId = requestIdFromParams || purchaseInfo.requestId
      const callbackUrl = URL_PROSPECTUS_CALLBACK.replace(
        ':apiUrl',
        config.API_URL
      )

      let newFund = fund
      let prospectusRes: ProspectusResponseType, fundRes: StockFundResponseType

      // if fundCode is not in states, i.e. after page redirection from prospectus site,
      // call fund API endpoint together with prospectus API endpoint;
      // otherwise, just fetch prospectus.
      if (!fund.fundCode) {
        const responses = await parallelRun(
          fetchProspectus(fundCode, investmentType, callbackUrl),
          fetchFund(fundCode)
        )
        prospectusRes = responses[0]
        fundRes = responses[1]
        newFund = mapStockFundResponseToFund(fundRes)
      } else {
        prospectusRes = await fetchProspectus(
          fundCode,
          investmentType,
          callbackUrl,
          purchaseInfo.confirmCode
        )
      }
      const prospectuses = createProspectusList(prospectusRes, fundCode)
      setProspectuses(prospectuses)
      setFund({
        ...newFund,
        prospectuses,
      })
      setPurchaseInfo({
        ...purchaseInfo,
        confirmCode: prospectusRes.data.confirmCode,
        investmentType: investmentType,
        accountType: accountType,
        price: investmentAmount,
        requestId: requestId,
      })
      setLoading(false)
    },
    []
  )

  useEffect(() => {
    if (!fund.fundCode && !purchaseInfo.accountType) {
      loadData(
        Number(investmentType),
        Number(accountType),
        investmentAmount,
        requestId
      )
    } else {
      loadData()
    }
  }, [])

  useEffect(() => {
    const isPurchaseInfoValid =
      purchaseInfo &&
      purchaseInfo.accountType &&
      purchaseInfo.investmentType &&
      purchaseInfo.confirmCode &&
      purchaseInfo.price &&
      purchaseInfo.requestId

    const isProspectusValidAndConfirmed =
      prospectuses.length > 0 &&
      !prospectuses.some(
        (prospectus) =>
          prospectus.requiresConfirmation && !prospectus.isConfirmed
      )

    if (
      isOrdering ||
      isLoading ||
      !isPurchaseInfoValid ||
      !isProspectusValidAndConfirmed ||
      !fund ||
      !fund.fundCode
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [isOrdering, isLoading, purchaseInfo, prospectuses, fund])

  const onPrevButtonClicked = () => {
    if (!isOrdering && !isLoading) {
      history.push(ROUTE_INVESTMENT_AMOUNT.replace(':fundCode', fundCode))
    }
  }

  const onOrderButtonClicked = async () => {
    setOrdering(true)

    const purchaseRes = await placePurchaseOrder(
      fundCode,
      Number(purchaseInfo.price.replace(/,/g, '')),
      purchaseInfo.accountType,
      purchaseInfo.investmentType,
      purchaseInfo.confirmCode,
      purchaseInfo.requestId,
      purchaseInfo.pointsSelect
        ? Number(purchaseInfo.points.replace(/,/g, ''))
        : null
    )

    const isPurchaseType = (
      res: PurchaseResponseType | ErrorResponseType
    ): res is PurchaseResponseType => {
      return (res as PurchaseResponseType).data.result !== undefined
    }

    if (
      isPurchaseType(purchaseRes) &&
      purchaseRes.status === 200 &&
      purchaseRes.data.result &&
      purchaseRes.data.result === CODE_ORDER_SUCCESS
    ) {
      setErrorMessaqe('')
      const orderRes = await fetchFundOrder(purchaseRes.data.orderID)
      const order = orderRes.data
      setFund({
        ...fund,
        fundCode: order.fundCode,
        fundName: order.fundName,
        executionDate: formatStandardDate(order.tradeDate),
        settlementDate: formatStandardDate(order.settleDate),
        executionQuantity: order.executionQuantity
          ? formatWithComma(order.executionQuantity)
          : '0',
      })
      setPurchaseInfo({
        ...purchaseInfo,
        investmentType: order.investmentType,
        accountType: order.accountType,
        price: formatWithComma(order.orderAmount),
        points: formatWithComma(order.pontaPoint),
      })
      history.push(ROUTE_ORDER_COMPLETION.replace(':fundCode', fundCode))
    } else if (purchaseRes.status === 401) {
      window.location.pathname = ROUTE_AUTH_FAILURE
    } else {
      let errorMessage = 'サーバーエラーが発生しました。'

      // If API response returns 200 but with result other than '0',
      // we need to convert the result code to respective error message;
      // otherwise, in the case of 400/500/503, we should infer
      // the error message from the response itself.
      if (
        isPurchaseType(purchaseRes) &&
        purchaseRes.status === 200 &&
        purchaseRes.data.result &&
        purchaseRes.data.result !== CODE_ORDER_SUCCESS
      ) {
        const mappedErrorMessage = ORDER_RESULT_MAPPING[purchaseRes.data.result]
        if (mappedErrorMessage) {
          errorMessage = mappedErrorMessage
        }
      } else if (!isPurchaseType(purchaseRes)) {
        const errorData = (purchaseRes as ErrorResponseType).data
        if (errorData?.detail && Object.keys(errorData.detail).length > 0) {
          let detailError = ''
          for (const [key, value] of Object.entries(errorData.detail)) {
            if (key !== 'parameters') {
              const messages: string[] = value
              detailError += `${messages.join('\n')}\n`
            }
          }
          if (detailError.replaceAll('\n', '') !== '') {
            errorMessage = detailError
          }
        } else if (errorData.message) {
          errorMessage = errorData.message
        }
      }
      const regeneratedRequestId = generateRequestId(fund, purchaseInfo)
      setPurchaseInfo({
        ...purchaseInfo,
        requestId: regeneratedRequestId,
      })
      setErrorMessaqe(errorMessage)
      window.scrollTo(0, document.body.scrollHeight)
    }
    setOrdering(false)
  }

  return (
    <div>
      <ProgressStepBar currentStep={PURCHASE_STEP_ORDER_CONFIRMATION} />
      <div className="l-content01 order-confirmation">
        <div className="l-content01__head">
          この内容で問題なければ
          <br />
          『auカブコム証券で注文する』を押してください。
        </div>
        {isLoading ? (
          <Spinner isBlack />
        ) : (
          <OrderDetails isCompletionPage={false} prospectuses={prospectuses} />
        )}

        <div className="l-notes01 notice">
          <p>ご注意事項</p>
          <ul>
            <li>
              auじぶん銀行は、auカブコム証券の金融商品仲介業者として委託商品取引業者であるauカブコム証券株式会社の証券口座開設のお申し込み、取次ぎおよびauカブコム証券株式会社の取扱う「株式、投資信託、債券」をご案内しております。
            </li>
            <li>
              「株式、投資信託、債券（金融商品仲介）」に関するお申込み、お問い合わせなどの各種お手続きはauカブコム証券株式会社が受付します。
            </li>
            <li>
              お申込み前に交付目論見書及び目論見書補完書面をご確認ください。
            </li>
            <li>
              ご注文後に基準価額が上昇した場合、約定口数が当初の買付口数見込みを下回ったり、買付できないことがありますのでご留意ください。
            </li>
            <li>
              ご注文時にお客さまが指定された注文金額が「出金可能額（お預り金）」の合計残高より一旦減額されますが、正式な買付受渡代金は基準価額確定後となります。実際の購入金額（約定金額）はご注文金額を上限とする金額内になります。指定されたご注文金額やファンドの基準価額の変動状況によっては、買付できなかったり、買付口数が見込みと異なったりする場合がありますので、ご注意ください。
            </li>
            <li>注文締切時間を過ぎたご注文は翌営業日扱いとなります。</li>
            <li>
              実際の約定日はauカブコム証券サイトの取引履歴よりご確認ください。
            </li>
            <li>
              受渡日はファンドごとに異なります。本注文に係る受渡日は注文受付完了画面でご確認ください。
            </li>
            <li>
              償還優遇枠を使用してのご注文は平日9:00～20:00までの受付となります。
            </li>
            <li>
              投資信託等（前金商品）のご注文を発注された後に、最大買付可能額を利用して株式等の買付（品受含む）のご注文を発注した場合には、約定・未約定の如何に係わらずお預り金は、株式等の買付のご注文にたいして優先して拘束されます。その結果、投資信託等（前金商品）買付可能額が不足していた場合、投資信託の買付のご注文は、前金条件を満たさないこととなり取り消しとなります。
            </li>
          </ul>
        </div>

        <div className="l-button02">
          {!isLoading && !isOrdering && (
            <StyledBackLinkButton onClick={onPrevButtonClicked}>
              {`< `}
              <span className="underlined">戻る</span>
            </StyledBackLinkButton>
          )}
          <button
            disabled={disabled}
            className={`btn-order -short ${isOrdering ? '-is-ordering' : ''}`}
            onClick={onOrderButtonClicked}
          >
            {!isOrdering ? (
              `auカブコム証券で注文する`
            ) : (
              <Spinner small={false} short={true} />
            )}
          </button>
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  )
}

export default OrderConfirmationPage

const StyledBackLinkButton = styled.span`
  & {
    position: absolute;
    left: 20px;
    color: #999;
    span.underlined {
      text-decoration: underline;
      color: #999;
    }
  }
`
