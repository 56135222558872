import ProgressStepBar from '../../../components/organisms/ProgressStepBar'
import React, { useEffect, useState } from 'react'
import FundInfo from '../../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPE_ACCUMULATIVE,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  RESERVE_PLAN_STEP_DISTRIBUTION,
  RESERVE_ROUTES,
} from '../../../common/utils/constants'
import { useAppContext } from '../../../contexts/AppContext'
import ICON_DISTRIBUTIVE_SELECTED from '../../../assets/images/icon_distributive_selected.svg'
import ICON_DISTRIBUTIVE_UNSELECTED from '../../../assets/images/icon_distributive_unselected.svg'
import ICON_ACCUMULATIVE_SELECTED from '../../../assets/images/icon_accumulative_selected.svg'
import ICON_ACCUMULATIVE_UNSELECTED from '../../../assets/images/icon_accumulative_unselected.svg'
import PurchaseWizardButtons from '../../../components/molecules/PurchaseWizardButtons'
import styled from 'styled-components'

const ReserveDistributionTypePage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }

  const [distributionTypeNames] = useState(Object.values(DISTRIBUTION_TYPES))

  const { reservePlanInfo, setReservePlanInfo } = useAppContext()

  const history = useHistory()

  useEffect(() => {
    setReservePlanInfo({
      ...reservePlanInfo,
      investmentType: Number(
        Object.keys(DISTRIBUTION_TYPES).find(
          (key) => DISTRIBUTION_TYPES[key] === DISTRIBUTION_TYPE_ACCUMULATIVE
        )
      ),
    })
  }, [])

  const onPrevButtonClicked = () => {
    history.push(
      RESERVE_ROUTES.ACCOUNT_SELECTION.replace(':fundCode', fundCode)
    )
  }

  const onNextButtonClicked = () => {
    if (reservePlanInfo.investmentType) {
      history.push(
        RESERVE_ROUTES.PROSPECTUS.replace(':fundCode', fundCode)
          .replace(':accountType', reservePlanInfo.accountType.toString())
          .replace(':investmentType', reservePlanInfo.investmentType.toString())
      )
    }
  }

  const onDistributionButtonClicked = (distributionTypeName: string) => {
    if (distributionTypeName === DISTRIBUTION_TYPE_ACCUMULATIVE) {
      setReservePlanInfo({
        ...reservePlanInfo,
        investmentType: Number(
          Object.keys(DISTRIBUTION_TYPES).find(
            (key) => DISTRIBUTION_TYPES[key] === distributionTypeName
          )
        ),
      })
    }
  }

  return (
    <StyledDistributionTypePage>
      <ProgressStepBar currentStep={RESERVE_PLAN_STEP_DISTRIBUTION} isReserve />
      <div className="l-link03 distribution-selection">
        <p className="l-link03__ttl">分配金は？</p>
        <ul className="l-link03__list">
          {distributionTypeNames.map((distributionTypeName) => {
            return (
              <li
                key={distributionTypeName}
                className={`${
                  DISTRIBUTION_TYPES[reservePlanInfo.investmentType] ===
                  distributionTypeName
                    ? 'in-active'
                    : ''
                } ${
                  distributionTypeName !== DISTRIBUTION_TYPE_ACCUMULATIVE
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  onDistributionButtonClicked(distributionTypeName)
                }}
              >
                <button>
                  <span>
                    {DISTRIBUTION_TYPES[reservePlanInfo.investmentType] ===
                    distributionTypeName ? (
                      <img
                        src={
                          distributionTypeName ===
                          DISTRIBUTION_TYPE_DISTRIBUTIVE
                            ? ICON_DISTRIBUTIVE_SELECTED
                            : ICON_ACCUMULATIVE_SELECTED
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        src={
                          distributionTypeName ===
                          DISTRIBUTION_TYPE_DISTRIBUTIVE
                            ? ICON_DISTRIBUTIVE_UNSELECTED
                            : ICON_ACCUMULATIVE_UNSELECTED
                        }
                        alt=""
                      />
                    )}
                    {distributionTypeName}
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
        <p className="reserve-disclaimer">
          ※現在、投資信託の積立においては「累投型」のみ選択可能となっております。
        </p>
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={!reservePlanInfo.investmentType}
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </StyledDistributionTypePage>
  )
}

export default ReserveDistributionTypePage

const StyledDistributionTypePage = styled.div`
  .reserve-disclaimer {
    margin-top: 20px;
  }
`
