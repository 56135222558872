import {
  AbrParams,
  Fund,
  PurchaseInfo,
  ReservePlanInfo,
} from '../common/utils/types'
import { createContext, useContext } from 'react'

const AppContext = createContext({
  fund: {} as Fund,
  setFund: (fund: Fund) => {},
  purchaseInfo: {} as PurchaseInfo,
  setPurchaseInfo: (purchaseInfo: PurchaseInfo) => {},
  reservePlanInfo: {} as ReservePlanInfo,
  setReservePlanInfo: (reservePlanInfo: ReservePlanInfo) => {},
  abrParams: {} as AbrParams,
  setAbrParams: (abrParams: AbrParams) => {},
  selectedPurchaseType: (null as unknown) as string,
  setSelectedPurchaseType: (selectedPurchaseType: string) => {},
})

export const useAppContext = () => useContext(AppContext)

export default AppContext
