import { INPUT_ERROR } from '../../common/utils/constants'
import { formatWithComma } from '../../common/utils/util'
import React, { useEffect } from 'react'
import { useState } from 'react'

type AmountInputProps = {
  maxAmount: number
  minAmount: number
  minTradeUnit: number
  maxStrLength: number
  value: string | null
  disabled: boolean
  disabledPlaceholder?: string
  onAmountChange: Function
  setBadInput: Function
  className: string
}

const AmountInput = ({
  maxAmount,
  minAmount,
  minTradeUnit,
  maxStrLength,
  value,
  disabled,
  disabledPlaceholder,
  onAmountChange,
  setBadInput,
  className,
}: AmountInputProps) => {
  const [inputErrors, setInputErrors] = useState([] as string[])

  const checkInputError = (num: number) => {
    const inputErrors = []
    if (num > maxAmount) {
      inputErrors.push(INPUT_ERROR.EXCEED_MAX_AMOUNT)
    }
    if (num < minAmount) {
      inputErrors.push(INPUT_ERROR.LESS_THAN_MIN_AMOUNT)
    }
    if (num % minTradeUnit !== 0) {
      inputErrors.push(INPUT_ERROR.INVALID_MIN_UNIT)
    }
    setInputErrors(inputErrors)
  }

  const onChange = (e: any) => {
    const input = e.target.value

    // return if input contains non-numeric and non-comma characters
    if (
      isNaN(input.replace(/,/g, '')) ||
      input.includes('.') ||
      input.length > maxStrLength
    ) {
      e.target.value = value || ''
      return
    }

    // get original cursor position
    let position = e.target.selectionStart

    // get original num of commas before cursor (for new cursor position calculation)
    const numOfCommas = value
      ? (value.slice(0, position - 1).match(/,/g) || []).length
      : 0

    // remove commas and leading 0s
    let num = input && input.replace(/,/g, '').replace(/^0+/, '')

    // check if there is input error
    checkInputError(num)

    // format number with comma
    const newPrice = formatWithComma(num)
    onAmountChange(newPrice)

    // calculate new num of commas
    const newNumOfCommas = (newPrice.slice(0, position).match(/,/g) || [])
      .length

    // calculate new cursor position
    const diff = newNumOfCommas - numOfCommas
    if (diff !== 0) {
      position += diff
    }
    // set new value to input field
    e.target.value = newPrice

    // set new cursor position
    e.target.selectionEnd = position
  }

  useEffect(() => {
    setBadInput(inputErrors.length > 0)
  }, [inputErrors])

  useEffect(() => {
    if (disabled) {
      setInputErrors([])
    } else if (value) {
      checkInputError(Number(value.replace(/,/g, '')))
    }
  }, [maxAmount, disabled])

  return (
    <>
      <div className={`l-price02__body__price`}>
        <input
          className={`${className} ${
            inputErrors.length !== 0 && !disabled ? '-error' : ''
          }`}
          onChange={onChange}
          placeholder={
            disabled
              ? disabledPlaceholder
                ? disabledPlaceholder
                : ''
              : `金額をご入力ください`
          }
          inputMode="numeric"
          value={value || ''}
          disabled={disabled}
        />
        円
      </div>
      <p className="l-price02__body__caption">
        <span
          className={
            inputErrors.includes(INPUT_ERROR.LESS_THAN_MIN_AMOUNT) && !disabled
              ? '-error'
              : ''
          }
        >
          ※{formatWithComma(minAmount)}円以上
        </span>
        {'　'}
        <span
          className={
            inputErrors.includes(INPUT_ERROR.INVALID_MIN_UNIT) && !disabled
              ? '-error'
              : ''
          }
        >
          {formatWithComma(minTradeUnit)}円単位
        </span>
      </p>
    </>
  )
}

export default AmountInput
