import { useAppContext } from '../../contexts/AppContext'
import React, { useEffect, useState } from 'react'
import ICON_TICK from '../../assets/images/icon_tick.svg'
import {
  ACCOUNT_TYPES,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  EXTERNAL_URL_FUND_INFO,
  FREE_COMMISSION_DISPLAY,
  NO_DATA,
  NO_INCREASE_AMOUNT,
  NO_INCREASE_MONTH,
  PAYMENT_TYPES,
} from '../../common/utils/constants'
import { Prospectus } from '../../common/utils/types'
import { getWeekday } from '../../common/utils/util'

type ReservePlanDetailsProps = {
  isCompletionPage?: boolean
  prospectuses?: Prospectus[]
}
const ReservePlanDetails = ({
  isCompletionPage,
  prospectuses,
}: ReservePlanDetailsProps) => {
  const { fund, reservePlanInfo } = useAppContext()

  const [isNoLoad, setNoLoad] = useState(false)

  useEffect(() => {
    if (fund.prospectuses && fund.prospectuses?.length > 0 && !prospectuses) {
      prospectuses = fund.prospectuses
    }
  }, [prospectuses])

  useEffect(() => {
    const commission =
      DISTRIBUTION_TYPES[reservePlanInfo.investmentType] ===
      DISTRIBUTION_TYPE_DISTRIBUTIVE
        ? fund.buyingInfo.distributing?.commission
        : fund.buyingInfo.accumulating?.commission
    if (
      commission === FREE_COMMISSION_DISPLAY ||
      commission === '0' ||
      !commission
    ) {
      setNoLoad(true)
    } else {
      setNoLoad(false)
    }
  }, [fund, reservePlanInfo])

  return (
    <div className="l-content01__body">
      <div className="l-content01__body__block">
        <div className="l-content01__body__ttl">注文内容</div>
        <div className="l-content01__body__content">
          <div className="l-content01__body__num">
            <p>
              <a
                href={EXTERNAL_URL_FUND_INFO.replace(
                  '${fundCode}',
                  fund.fundCode
                )}
                target="_blank"
              >
                {fund.fundName}
              </a>
            </p>
            {isNoLoad && <p>ノーロード</p>}
          </div>
          <div className="l-content01__body__detail">
            <dl>
              <dt>運用会社</dt>
              <dd>{fund.investmentCompany || NO_DATA}</dd>
            </dl>
            <dl>
              <dt>投信分類</dt>
              <dd>
                {DISTRIBUTION_TYPES[reservePlanInfo.investmentType] ===
                DISTRIBUTION_TYPE_DISTRIBUTIVE
                  ? '一般型'
                  : '累投型'}
              </dd>
            </dl>
            <dl>
              <dt>基準価額</dt>
              <dd>
                {fund.standardPrice}円<span>({fund.standardPriceDate})</span>
              </dd>
            </dl>
          </div>
          {!isCompletionPage && (
            <div className="l-content01__body__link">
              <p>交付目論見書および目論見補完書面</p>
              {prospectuses &&
                prospectuses.map((prospectus: Prospectus) => {
                  return (
                    <dl key={`prospectus-${prospectus.fileName}`}>
                      <dt>
                        <a href={prospectus.url} target="_blank">
                          {prospectus.fileName}
                        </a>
                      </dt>
                      {prospectus.requiresConfirmation && (
                        <dd>
                          {prospectus.isConfirmed ? (
                            <span className="confirmed">
                              <img src={ICON_TICK} alt="" />
                              確認済
                            </span>
                          ) : (
                            <span>未確認</span>
                          )}
                        </dd>
                      )}
                    </dl>
                  )
                })}
            </div>
          )}
          <div className="l-content01__body__detail">
            <dl>
              <dt>毎月の買付金額</dt>
              <dd>{reservePlanInfo.specifiedAmount}円</dd>
            </dl>
            <dl>
              <dt>口座区分</dt>
              <dd>{ACCOUNT_TYPES[reservePlanInfo.accountType]}</dd>
            </dl>
            <dl>
              <dt>決済方法</dt>
              <dd>{PAYMENT_TYPES[reservePlanInfo.paymentType]}</dd>
            </dl>
            <dl>
              <dt>毎月の指定日</dt>
              <dd>毎月{reservePlanInfo.specifiedDate}日</dd>
            </dl>
            {isCompletionPage && reservePlanInfo.nextScheduledDate && (
              <dl>
                <dt>次回積立指定日</dt>
                <dd>
                  {reservePlanInfo.nextScheduledDate} （
                  {getWeekday(reservePlanInfo.nextScheduledDate)}）
                </dd>
              </dl>
            )}
            <dl>
              <dt>増額月１</dt>
              <dd>
                {reservePlanInfo.increaseMonth1
                  ? `${reservePlanInfo.increaseMonth1}月`
                  : NO_INCREASE_MONTH}
              </dd>
            </dl>
            <dl>
              <dt>増額月２</dt>
              <dd>
                {reservePlanInfo.increaseMonth2
                  ? `${reservePlanInfo.increaseMonth2}月`
                  : NO_INCREASE_MONTH}
              </dd>
            </dl>
            <dl>
              <dt>増額金額</dt>
              <dd>
                {reservePlanInfo.increaseAmount
                  ? `${reservePlanInfo.increaseAmount}円`
                  : NO_INCREASE_AMOUNT}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservePlanDetails
