import FundRanking from '../../components/organisms/FundRanking'
import TradeDisclaimer from '../../components/organisms/TradeDisclaimer'
import React from 'react'

const FundRankingPage = () => {
  return (
    <div>
      <FundRanking isFundRankingPage />
      <TradeDisclaimer />
    </div>
  )
}

export default FundRankingPage
