import React from 'react'
import ICON_SUCCESS from '../../assets/images/icon_auth_success.svg'

const AuthorizedPage = () => (
  <div>
    <div className="l-login01">
      <div className="l-login01__head">
        <img src={ICON_SUCCESS} alt="" />
      </div>
      <div className="l-login01__body">
        <p>
          auカブコム証券口座の認証が完了し、
          <br />
          auカブコム証券へのアクセス権限を
          <br />
          付与しました。
        </p>
        <p>本画面を閉じてください。</p>
      </div>
    </div>
  </div>
)

export default AuthorizedPage
