import React from 'react'
import styled from 'styled-components'
import { EXTERNAL_URL_FUND_INFO } from '../../common/utils/constants'

type FundTitleProps = {
  fundName: string
  fundCode: string
}

const FundTitle = ({ fundName, fundCode }: FundTitleProps) => {
  return (
    <StyledFundtitle>
      <p>{fundCode}</p>
      <p>
        <a
          href={EXTERNAL_URL_FUND_INFO.replace('${fundCode}', fundCode)}
          target="_blank"
        >
          {fundName}
        </a>
      </p>
    </StyledFundtitle>
  )
}

export default FundTitle

const StyledFundtitle = styled.div`
  & {
    margin: 20px var(--app-left-padding);

    p:nth-child(1) {
      display: inline-block;
      font-size: 1rem;
      padding: 0 2px;
      margin: 0 0 2px;
      border: 1px solid #333;
      border-radius: 5px;
    }

    p:nth-child(2) a {
      font-size: 1.4rem;
      color: #ff6400;
      text-decoration: underline;
    }
  }
`
