import React, { useCallback, useEffect, useState } from 'react'
import ProgressStepBar from '../../../components/organisms/ProgressStepBar'
import { useHistory, useParams } from 'react-router-dom'
import {
  CODE_ORDER_SUCCESS,
  ORDER_RESULT_MAPPING,
  URL_PROSPECTUS_CALLBACK,
  RESERVE_PLAN_STEP_ORDER_CONFIRMATION,
  RESERVE_ROUTES,
  ROUTE_AUTH_FAILURE,
  EXTERNAL_URL_TSUMITATE_RULES,
  EXTERNAL_URL_CREDITCARD_RULES,
} from '../../../common/utils/constants'
import { useAppContext } from '../../../contexts/AppContext'
import {
  fetchProspectus,
  fetchReservePlan,
  placeReservePlanApplication,
} from '../../../common/utils/fetcher'
import Spinner from '../../../components/molecules/Spinner'
import ReservePlanDetails from '../../../components/organisms/ReservePlanDetails'
import {
  formatStandardDate,
  formatWithComma,
  generateReserveRequestId,
} from '../../../common/utils/util'
import {
  ErrorResponseType,
  Prospectus,
  ProspectusResponseType,
  ReservePlanApplicationResponseType,
} from '../../../common/utils/types'
import config from '../../../config/config'
import { createProspectusList } from '../../../common/utils/prospectus'
import styled from 'styled-components'

const ReserveOrderConfirmationPage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }

  const [disabled, setDisabled] = useState(true)

  const [isOrdering, setOrdering] = useState(false)

  const [isLoading, setLoading] = useState(true)

  const [errorMessage, setErrorMessaqe] = useState('')

  const [prospectuses, setProspectuses] = useState([] as Prospectus[])

  const { fund, reservePlanInfo, setFund, setReservePlanInfo } = useAppContext()

  const history = useHistory()

  const loadData = useCallback(async () => {
    setLoading(true)

    const callbackUrl = URL_PROSPECTUS_CALLBACK.replace(
      ':apiUrl',
      config.API_URL
    )

    const prospectusRes: ProspectusResponseType = await fetchProspectus(
      fundCode,
      reservePlanInfo.investmentType,
      callbackUrl,
      reservePlanInfo.confirmCode
    )
    const prospectuses = createProspectusList(prospectusRes, fundCode)
    setProspectuses(prospectuses)
    setFund({
      ...fund,
      prospectuses,
    })
    setReservePlanInfo({
      ...reservePlanInfo,
      confirmCode: prospectusRes.data.confirmCode,
    })
    setLoading(false)
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    const isReservePlanValid =
      reservePlanInfo &&
      reservePlanInfo.accountType &&
      reservePlanInfo.investmentType &&
      reservePlanInfo.confirmCode &&
      reservePlanInfo.specifiedAmount &&
      reservePlanInfo.requestId &&
      (reservePlanInfo.increaseMonth1 || reservePlanInfo.increaseMonth2
        ? reservePlanInfo.increaseAmount
        : true)

    const isProspectusValidAndConfirmed =
      prospectuses.length > 0 &&
      !prospectuses.some(
        (prospectus) =>
          prospectus.requiresConfirmation && !prospectus.isConfirmed
      )

    if (
      isOrdering ||
      isLoading ||
      !isReservePlanValid ||
      !isProspectusValidAndConfirmed ||
      !fund ||
      !fund.fundCode
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [isOrdering, isLoading, reservePlanInfo, prospectuses, fund])

  const onPrevButtonClicked = () => {
    if (!isOrdering && !isLoading) {
      history.push(RESERVE_ROUTES.PLAN_SETTING.replace(':fundCode', fundCode))
    }
  }

  const onOrderButtonClicked = async () => {
    setOrdering(true)

    const applicationRes = await placeReservePlanApplication(
      fundCode,
      reservePlanInfo.accountType,
      reservePlanInfo.investmentType,
      reservePlanInfo.paymentType,
      Number(reservePlanInfo.specifiedAmount.replace(/,/g, '')),
      reservePlanInfo.specifiedDate,
      reservePlanInfo.increaseMonth1,
      reservePlanInfo.increaseMonth2,
      reservePlanInfo.increaseAmount
        ? Number(reservePlanInfo.increaseAmount.replace(/,/g, ''))
        : null,
      reservePlanInfo.confirmCode,
      reservePlanInfo.requestId
    )

    const isApplicationType = (
      res: ReservePlanApplicationResponseType | ErrorResponseType
    ): res is ReservePlanApplicationResponseType => {
      return (
        (res as ReservePlanApplicationResponseType).data.result !== undefined
      )
    }

    if (
      isApplicationType(applicationRes) &&
      applicationRes.status === 200 &&
      applicationRes.data.result &&
      applicationRes.data.result === CODE_ORDER_SUCCESS
    ) {
      setErrorMessaqe('')
      const planRes = await fetchReservePlan(applicationRes.data.applicationNo)
      const plan = planRes.data
      setFund({
        ...fund,
        fundCode: plan.fundCode,
        fundName: plan.fundName,
      })
      setReservePlanInfo({
        ...reservePlanInfo,
        investmentType: plan.investmentType,
        accountType: plan.accountType,
        specifiedAmount: formatWithComma(plan.specifiedAmount),
        specifiedDate: plan.specifiedDate,
        increaseAmount: plan.increaseAmount
          ? formatWithComma(plan.increaseAmount)
          : null,
        increaseMonth1: plan.increaseMonth1 ? plan.increaseMonth1 : null,
        increaseMonth2: plan.increaseMonth2 ? plan.increaseMonth2 : null,
        nextScheduledDate: formatStandardDate(plan.nextScheduledDate),
      })
      history.push(
        RESERVE_ROUTES.ORDER_COMPLETION.replace(':fundCode', fundCode)
      )
    } else if (applicationRes.status === 401) {
      window.location.pathname = ROUTE_AUTH_FAILURE
    } else {
      let errorMessage = 'サーバーエラーが発生しました。'

      // If API response returns 200 but with result other than '0',
      // we need to convert the result code to respective error message;
      // otherwise, in the case of 400/500/503, we should infer
      // the error message from the response itself.
      if (
        isApplicationType(applicationRes) &&
        applicationRes.status === 200 &&
        applicationRes.data.result &&
        applicationRes.data.result !== CODE_ORDER_SUCCESS
      ) {
        const mappedErrorMessage =
          ORDER_RESULT_MAPPING[applicationRes.data.result]
        if (mappedErrorMessage) {
          errorMessage = mappedErrorMessage
        }
      } else if (!isApplicationType(applicationRes)) {
        const errorData = (applicationRes as ErrorResponseType).data
        if (errorData?.detail && Object.keys(errorData.detail).length > 0) {
          let detailError = ''
          for (const [key, value] of Object.entries(errorData.detail)) {
            if (key !== 'parameters') {
              const messages: string[] = value
              detailError += `${messages.join('\n')}\n`
            }
          }
          if (detailError.replaceAll('\n', '') !== '') {
            errorMessage = detailError
          }
        } else if (errorData.message) {
          errorMessage = errorData.message
        }
      }
      const regeneratedRequestId = generateReserveRequestId(
        fund,
        reservePlanInfo
      )
      setReservePlanInfo({
        ...reservePlanInfo,
        requestId: regeneratedRequestId,
      })
      setErrorMessaqe(errorMessage)
      window.scrollTo(0, document.body.scrollHeight)
    }
    setOrdering(false)
  }

  return (
    <div>
      <ProgressStepBar
        currentStep={RESERVE_PLAN_STEP_ORDER_CONFIRMATION}
        isReserve
      />
      <div className="l-content01 order-confirmation">
        <div className="l-content01__head">
          この内容で問題なければ
          <br />
          『auカブコム証券で積立申込みする』を押してください。
        </div>
        {isLoading ? (
          <Spinner isBlack />
        ) : (
          <ReservePlanDetails
            isCompletionPage={false}
            prospectuses={prospectuses}
          />
        )}

        <div className="l-notes01 notice">
          <p>ご注意事項</p>
          <ul>
            <li>
              auじぶん銀行は、auカブコム証券の金融商品仲介業者として委託商品取引業者であるauカブコム証券株式会社の証券口座開設のお申し込み、取次ぎおよびauカブコム証券株式会社の取扱う「株式、投資信託、債券」をご案内しております。
            </li>
            <li>
              「株式、投資信託、債券（金融商品仲介）」に関するお申込み、お問い合わせなどの各種お手続きはauカブコム証券株式会社が受付します。
            </li>
            <li>
              お申込み前に交付目論見書及び目論見書補完書面をご確認ください。
            </li>
            <li>
              ご注文後に基準価額が上昇した場合、約定口数が当初の買付口数見込みを下回ったり、買付できないことがありますのでご留意ください。
            </li>
            <li>
              実際の約定日はauカブコム証券サイトの取引履歴よりご確認ください。
            </li>
            <li>
              受渡日はファンドごとに異なります。本注文に係る受渡日はauカブコム証券サイトの取引履歴より確認ください。
            </li>
            <li>
              償還優遇枠を使用してのご注文は平日9:00～20:00までの受付となります。
            </li>
            <li>
              投資信託等（前金商品）のご注文を発注された後に、最大買付可能額を利用して株式等の買付（品受含む）のご注文を発注した場合には、約定・未約定の如何に係わらずお預り金は、株式等の買付のご注文にたいして優先して拘束されます。その結果、投資信託等（前金商品）買付可能額が不足していた場合、投資信託の買付のご注文は、前金条件を満たさないこととなり取り消しとなります。
            </li>
            <li>
              本注文（積立申込）に係る投資信託定期積立取引には、auカブコム証券所定の
              <a
                target="_blank"
                className="kabucom-link"
                href={EXTERNAL_URL_TSUMITATE_RULES}
              >
                投資信託定期積立取引取扱規定
              </a>
              が適用されます。また、決済方法としてクレジットカードを選択された場合には、あわせて同証券所定の
              <a
                target="_blank"
                className="kabucom-link"
                href={EXTERNAL_URL_CREDITCARD_RULES}
              >
                投資信託積立取引クレジットカード決済約款
              </a>
              が適用されます。
            </li>
          </ul>
        </div>

        <div className="l-button02">
          {!isLoading && !isOrdering && (
            <StyledBackLinkButton onClick={onPrevButtonClicked}>
              {`< `}
              <span className="underlined">戻る</span>
            </StyledBackLinkButton>
          )}
          <button
            disabled={disabled}
            className={`btn-order -short ${isOrdering ? '-is-ordering' : ''}`}
            onClick={onOrderButtonClicked}
          >
            {!isOrdering ? (
              `auカブコム証券で積立申込みする`
            ) : (
              <Spinner small={false} short={true} />
            )}
          </button>
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  )
}

export default ReserveOrderConfirmationPage

const StyledBackLinkButton = styled.span`
  & {
    position: absolute;
    left: 20px;
    color: #999;
    span.underlined {
      text-decoration: underline;
      color: #999;
    }
  }
`
