import ReservePlanDetails from '../../../components/organisms/ReservePlanDetails'
import React from 'react'
import styled from 'styled-components'

const ReserveOrderCompletionPage = () => {
  return (
    <StyledReserveOrderCompletionPage>
      <div className="l-content01 order-completion">
        <div className="l-content01__head">
          ご注文ありがとうございます。
          <br />
          auカブコム証券にて積立申込を受け付けました。
        </div>
        <div className="message">
          取引報告書などは、auカブコム証券サイトにログインし、資産管理タブの電子交付からご確認いただけます。
          <br />
          <br />
          以下の注文内容をご確認いただき、画面右上の閉じるボタンを押してください。
        </div>
        <ReservePlanDetails isCompletionPage={true} />
      </div>
    </StyledReserveOrderCompletionPage>
  )
}

export default ReserveOrderCompletionPage

const StyledReserveOrderCompletionPage = styled.div`
  .message {
    margin-top: 16px;
  }
`
