import React from 'react'
import styled from 'styled-components'

type SectionHeaderProps = {
  title: string
  icon?: string
}

const SectionHeader = ({ title, icon }: SectionHeaderProps) => {
  return (
    <StyledSectionHeader className="section-header">
      {title}
      {icon && <img src={icon} alt="" />}
    </StyledSectionHeader>
  )
}

export default SectionHeader

const StyledSectionHeader = styled.div`
  & {
    display: block;
    margin-top: -1px;
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    position: relative;
    font-size: 1.6rem;
    font-weight: var(--accordion-title-font-weight);
    padding: 10px var(--app-right-padding) 10px var(--app-left-padding);

    img {
      float: right;
      width: 18px;
      height: 18px;
      margin-top: 2px;
    }
  }
`
