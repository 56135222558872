import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { ROUTE_AUTH_FAILURE } from './common/utils/constants'
import { initApiCallerWithAbrParams } from './common/utils/fetcher'
import { useAppContext } from './contexts/AppContext'

interface PrivateRouteProps {
  component: any
  path?: string
  exact?: boolean
}

const PrivateRoute = ({
  component: Component,
  path,
  ...rest
}: PrivateRouteProps) => {
  const { abrParams, setAbrParams } = useAppContext()
  const [isAuthenticating, setAuthenticating] = useState(true)

  useEffect(() => {
    if (!abrParams.param1 || !abrParams.param2 || !abrParams.param3) {
      const params = initApiCallerWithAbrParams()
      setAbrParams(params)
    }
    setAuthenticating(false)
    // eslint-disable-next-line
  }, [])

  return (
    <Route
      {...rest}
      render={(props) =>
        abrParams.param1 && abrParams.param2 && abrParams.param3 ? (
          <Component {...props} />
        ) : isAuthenticating ? (
          <></>
        ) : (
          <Redirect to={ROUTE_AUTH_FAILURE} />
        )
      }
    />
  )
}

export default PrivateRoute
