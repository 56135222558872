import ProgressStepBar from '../../components/organisms/ProgressStepBar'

import React, { useCallback, useEffect, useState } from 'react'
import FundInfo from '../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  formatWithComma,
  generateRequestId,
  getKeyByValue,
} from '../../common/utils/util'
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPE_NISA,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPE_ACCUMULATIVE,
  PURCHASE_STEP_INVESTMENT,
  ROUTE_ORDER_CONFIRMATION,
  ROUTE_PROSPECTUS,
  POINT_AVAILABLE_STATUS,
  TRADE_AMOUNT,
  INPUT_ERROR,
  EXTERNAL_URL_FAQ,
} from '../../common/utils/constants'
import { useAppContext } from '../../contexts/AppContext'
import {
  fetchFundWallet,
  fetchPontaPoints,
  parallelRun,
} from '../../common/utils/fetcher'
import Spinner from '../molecules/Spinner'
import PurchaseWizardButtons from '../../components/molecules/PurchaseWizardButtons'
import {
  FundWalletResponseType,
  PontaPointsResponseType,
} from 'common/utils/types'

const POINTS_SELECTION = {
  NONE: 'none',
  PARTIAL: 'partial',
  ALL: 'all',
}

const InvestmentAmountPage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }

  const { fund, purchaseInfo, setPurchaseInfo } = useAppContext()

  const [availableAmount, setAvailableAmount] = useState(-1)
  const [availablePoints, setAvailablePoints] = useState(-1)
  const [availableNisa, setAvailableNisa] = useState(-1)

  const [minAmount] = useState(
    Number(
      (purchaseInfo.investmentType.toString() ===
      getKeyByValue(DISTRIBUTION_TYPES, DISTRIBUTION_TYPE_ACCUMULATIVE)
        ? fund.buyingInfo.accumulating?.minTradeAmount || '0'
        : fund.buyingInfo.distributing?.minTradeAmount || '0'
      ).replace(/,/g, '')
    )
  )
  const [minTradeUnit] = useState(
    Number(
      (purchaseInfo.investmentType.toString() ===
      getKeyByValue(DISTRIBUTION_TYPES, DISTRIBUTION_TYPE_ACCUMULATIVE)
        ? fund.buyingInfo.accumulating?.tradeUnitAmount || '0'
        : fund.buyingInfo.distributing?.tradeUnitAmount || '0'
      ).replace(/,/g, '')
    )
  )
  const [amountErrors, setAmountErrors] = useState([] as string[])
  const [pointErrors, setPointErrors] = useState([] as string[])
  const [globalErrors, setGlobalErrors] = useState([] as string[])
  const [nonePointsOptionDisabled, setNonePointsOptionDisabled] = useState(
    false
  )

  const history = useHistory()

  const loadData = useCallback(async () => {
    // TODO (@sercant): @ping-alpaca I think we should not have default investmentType
    // in this page and somehow break or navigate to some other page.
    const investmentType = purchaseInfo.investmentType || 1
    const responses = await parallelRun(
      fetchFundWallet(fundCode, investmentType),
      fetchPontaPoints()
    )
    const walletRes: FundWalletResponseType = responses[0]
    const pointRes: PontaPointsResponseType = responses[1]

    setAvailableAmount(walletRes.data.fundTradeLimit)
    if (
      ACCOUNT_TYPES[purchaseInfo.accountType] === ACCOUNT_TYPE_NISA &&
      walletRes.data.nisaAllowance !== null &&
      typeof walletRes.data.nisaAllowance !== 'undefined'
    ) {
      setAvailableNisa(walletRes.data.nisaAllowance)
    }
    if (
      pointRes.data.availableStatus === POINT_AVAILABLE_STATUS.AVAILABLE &&
      pointRes.data.pontaPoint !== null &&
      typeof pointRes.data.pontaPoint !== 'undefined'
    ) {
      setAvailablePoints(pointRes.data.pontaPoint)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (purchaseInfo.price) {
      // check if no points option should be disabled
      const selectedPrice = Number(purchaseInfo.price.replace(/,/g, ''))
      checkAmountError(selectedPrice)
      const disableNonePointsOption =
        availablePoints === 0 ? false : availableAmount < selectedPrice
      setNonePointsOptionDisabled(disableNonePointsOption)
    }

    if (availablePoints > -1) {
      if (!purchaseInfo.pointsSelect) {
        // init points selection
        setPurchaseInfo({
          ...purchaseInfo,
          points: '0',
          pointsSelect: POINTS_SELECTION.NONE,
        })
      } else {
        // check if there are points input errors
        const selectedPoints = Number(purchaseInfo.points.replace(/,/g, ''))
        checkPointsError(selectedPoints)

        if (purchaseInfo.price) {
          const selectedPrice = Number(purchaseInfo.price.replace(/,/g, ''))
          checkGlobalErrors(selectedPrice, selectedPoints)
        }
      }
    }
  }, [availableAmount, availableNisa, availablePoints])

  const onPrevButtonClicked = () => {
    history.push(
      ROUTE_PROSPECTUS.replace(':fundCode', fundCode)
        .replace(':investmentType', purchaseInfo.investmentType.toString())
        .replace(':accountType', purchaseInfo.accountType.toString())
    )
  }

  const onNextButtonClicked = () => {
    if (purchaseInfo.price) {
      const selectedPrice = Number(purchaseInfo.price.replace(/,/g, ''))
      const selectedPoints = purchaseInfo.points
        ? Number(purchaseInfo.points.replace(/,/g, ''))
        : 0

      // Last sanity checks that should NEVER fail
      // It stops the flow before something goes wrong
      if (selectedPrice < selectedPoints) {
        console.error('Amount of points greater than price')
        return
      }
      if (selectedPrice > selectedPoints + availableAmount) {
        console.error('Not enough funds to purchase')
        return
      }

      let requestId = purchaseInfo.requestId
      if (!requestId) {
        requestId = generateRequestId(fund, purchaseInfo)

        setPurchaseInfo({
          ...purchaseInfo,
          requestId: requestId,
        })
      }
      history.push(
        ROUTE_ORDER_CONFIRMATION.replace(':fundCode', fundCode)
          .replace(':accountType', purchaseInfo.accountType.toString())
          .replace(':investmentType', purchaseInfo.investmentType.toString())
          .replace(':investmentAmount', purchaseInfo.price)
          .replace(':requestId', requestId)
      )
    }
  }

  const checkAmountError = (num: number) => {
    const amountErrors = []

    let availableTotal = availableAmount
    if (availablePoints > -1) {
      availableTotal += availablePoints
    }

    if (num > TRADE_AMOUNT.MAX) {
      amountErrors.push(INPUT_ERROR.EXCEED_DIGITS)
    }

    if (num > availableTotal) {
      amountErrors.push(INPUT_ERROR.EXCEED_MAX_AMOUNT)
    }

    if (num < minAmount) {
      amountErrors.push(INPUT_ERROR.LESS_THAN_MIN_AMOUNT)
    }

    if (num % minTradeUnit !== 0) {
      amountErrors.push(INPUT_ERROR.INVALID_MIN_UNIT)
    }

    if (
      ACCOUNT_TYPES[purchaseInfo.accountType] === ACCOUNT_TYPE_NISA &&
      num > availableNisa
    ) {
      amountErrors.push(INPUT_ERROR.EXCEED_NISA_ALLOWANCE)
    }

    setAmountErrors(amountErrors)
  }

  const checkPointsError = (num: number) => {
    const pointErrors = []

    if (num > TRADE_AMOUNT.MAX) {
      pointErrors.push(INPUT_ERROR.EXCEED_DIGITS)
    }

    if (num > availablePoints) {
      pointErrors.push(INPUT_ERROR.EXCEED_MAX_POINTS)
    }

    setPointErrors(pointErrors)
  }

  const checkGlobalErrors = (selectedPrice: number, selectedPoints: number) => {
    const globalErrors = []

    if (selectedPoints + availableAmount < selectedPrice) {
      globalErrors.push(INPUT_ERROR.SUM_NOT_EQUAL)
    }

    if (selectedPoints > selectedPrice) {
      globalErrors.push(INPUT_ERROR.EXCEED_PURCHASE_AMOUNT)
    }

    setGlobalErrors(globalErrors)
  }

  const onAmountChange = (e: any) => {
    const input = e.target.value

    // return if input contains non-numeric and non-comma characters
    if (
      isNaN(input.replace(/,/g, '')) ||
      input.includes('.') ||
      input.length > TRADE_AMOUNT.STR_LENGTH
    ) {
      e.target.value = purchaseInfo.price || ''
      return
    }

    // get original cursor position
    let position = e.target.selectionStart

    // get original num of commas before cursor (for new cursor position calculation)
    const numOfCommas = purchaseInfo.price
      ? (purchaseInfo.price.slice(0, position - 1).match(/,/g) || []).length
      : 0

    // remove commas and leading 0s
    let num = input && Number(input.replace(/,/g, '').replace(/^0+/, ''))

    // check if there is input error
    checkAmountError(num)

    // change point settings if needed
    let newPoints = purchaseInfo.points
    let newPointsSelect = purchaseInfo.pointsSelect
    const nextDisableNonePointsOption =
      availableAmount < num && availablePoints > 0

    if (
      nextDisableNonePointsOption &&
      purchaseInfo.pointsSelect === POINTS_SELECTION.NONE
    ) {
      newPoints = ''
      newPointsSelect = POINTS_SELECTION.PARTIAL
    } else if (
      nonePointsOptionDisabled &&
      !nextDisableNonePointsOption &&
      purchaseInfo.pointsSelect === POINTS_SELECTION.PARTIAL &&
      !purchaseInfo.points
    ) {
      newPoints = '0'
      newPointsSelect = POINTS_SELECTION.NONE
    } else if (purchaseInfo.pointsSelect === POINTS_SELECTION.ALL) {
      const useablePoints = Math.min(availablePoints, num)
      newPoints = formatWithComma(useablePoints)
    }

    // check if there is point error
    if (availablePoints > -1) {
      const selectedPoints = Number(newPoints.replace(/,/g, ''))
      checkGlobalErrors(num, selectedPoints)
    }

    // format number with comma
    const newPrice = num > 0 ? formatWithComma(num) : ''

    setPurchaseInfo({
      ...purchaseInfo,
      points: newPoints,
      pointsSelect: newPointsSelect,
      price: newPrice,
    })
    setNonePointsOptionDisabled(nextDisableNonePointsOption)

    // calculate new num of commas
    const newNumOfCommas = (newPrice.slice(0, position).match(/,/g) || [])
      .length

    // calculate new cursor position
    const diff = newNumOfCommas - numOfCommas
    if (diff !== 0) {
      position += diff
    }
    // set new value to input field
    e.target.value = newPrice

    // set new cursor position
    e.target.selectionEnd = position
  }

  const onPointsChange = (e: any) => {
    const input = e.target.value

    // return if input contains non-numeric and non-comma characters
    if (
      isNaN(input.replace(/,/g, '')) ||
      input.includes('.') ||
      input.length > TRADE_AMOUNT.STR_LENGTH
    ) {
      return
    }

    // get original cursor position
    let position = e.target.selectionStart

    // get original num of commas before cursor (for new cursor position calculation)
    const numOfCommas = purchaseInfo.points
      ? (purchaseInfo.points.slice(0, position - 1).match(/,/g) || []).length
      : 0

    // remove commas and leading 0s
    let num = input && Number(input.replace(/,/g, '').replace(/^0+/, ''))

    // check if there is input error
    checkPointsError(num)
    if (purchaseInfo.price) {
      const selectedPrice = Number(purchaseInfo.price.replace(/,/g, ''))
      checkGlobalErrors(selectedPrice, num)
    }

    // format number with comma
    const newPoints = num > 0 ? formatWithComma(num) : ''

    setPurchaseInfo({
      ...purchaseInfo,
      points: newPoints,
      pointsSelect: POINTS_SELECTION.PARTIAL,
    })

    // calculate new num of commas
    const newNumOfCommas = (newPoints.slice(0, position).match(/,/g) || [])
      .length

    // calculate new cursor position
    const diff = newNumOfCommas - numOfCommas
    if (diff !== 0) {
      position += diff
    }
    // set new value to input field
    e.target.value = newPoints

    // set new cursor position
    e.target.selectionEnd = position
  }

  const onPointsSelectChange = (e: any) => {
    const selection = e.target.value

    const selectedPrice = purchaseInfo.price
      ? Number(purchaseInfo.price.replace(/,/g, ''))
      : 0
    let newPoints = ''
    let newPointsSelect = ''

    if (selection === POINTS_SELECTION.ALL) {
      const useablePoints = Math.min(availablePoints, selectedPrice)
      newPoints = formatWithComma(useablePoints)
      newPointsSelect = POINTS_SELECTION.ALL
    } else if (selection === POINTS_SELECTION.PARTIAL) {
      newPoints = ''
      newPointsSelect = POINTS_SELECTION.PARTIAL
    } else if (selection === POINTS_SELECTION.NONE) {
      newPoints = '0'
      newPointsSelect = POINTS_SELECTION.NONE
    }

    setPointErrors([])
    checkGlobalErrors(selectedPrice, Number(newPoints.replace(/,/g, '')))

    setPurchaseInfo({
      ...purchaseInfo,
      points: newPoints,
      pointsSelect: newPointsSelect,
    })
  }

  return (
    <div>
      <ProgressStepBar currentStep={PURCHASE_STEP_INVESTMENT} />
      <div className="l-price02 investment-amount">
        <p className="l-price02__head">購入金額を入力してください。</p>
        <div className="l-price02__body">
          <div className={`l-price02__body__price`}>
            <input
              className={`${amountErrors.length !== 0 ? '-error' : ''}`}
              onChange={onAmountChange}
              placeholder="金額をご入力ください"
              inputMode="numeric"
              defaultValue={purchaseInfo.price}
              disabled={availableAmount === -1}
            />
            円
          </div>
          <p className="l-price02__body__caption">
            <span
              className={
                amountErrors.includes(INPUT_ERROR.LESS_THAN_MIN_AMOUNT)
                  ? '-error'
                  : ''
              }
            >
              ※{formatWithComma(minAmount)}円以上
            </span>
            {'　'}
            <span
              className={
                amountErrors.includes(INPUT_ERROR.INVALID_MIN_UNIT)
                  ? '-error'
                  : ''
              }
            >
              {formatWithComma(minTradeUnit)}円単位
            </span>
            {ACCOUNT_TYPES[purchaseInfo.accountType] === ACCOUNT_TYPE_NISA && (
              <>
                {availableNisa !== -1 && (
                  <p
                    className={`nisa-allowance ${
                      amountErrors.includes(INPUT_ERROR.EXCEED_NISA_ALLOWANCE)
                        ? '-error'
                        : ''
                    }`}
                  >
                    （非課税投資可能額: {formatWithComma(availableNisa)}円）
                  </p>
                )}
                <p
                  className={
                    amountErrors.includes(INPUT_ERROR.EXCEED_NISA_ALLOWANCE)
                      ? '-error'
                      : ''
                  }
                >
                  ※NISA口座を選択された場合は、非課税投資可能額の範囲内で入力してください。
                </p>
              </>
            )}
            {amountErrors.includes(INPUT_ERROR.EXCEED_DIGITS) && (
              <p className="-error">
                ※購入金額は10,000,000,000円未満でご入力ください。
              </p>
            )}
            {amountErrors.includes(INPUT_ERROR.EXCEED_MAX_AMOUNT) &&
              availablePoints > -1 && (
                <p className="-error">
                  ※以下に表示する「お預り金残高」の範囲内（以下で「Pontaポイント」のご利用を選択した場合は「お預り金残高」と「利用するPontaポイント（1P=1円）」の合計額の範囲内）で入力してください。
                </p>
              )}
          </p>
          <div
            className={`l-price02__body__num ${
              amountErrors.includes(INPUT_ERROR.EXCEED_MAX_AMOUNT)
                ? '-error'
                : ''
            }`}
          >
            <p>auカブコム証券お預り金残高</p>
            <div className={availableAmount === -1 ? '' : 'amount'}>
              {availableAmount === -1 ? (
                <Spinner small={true} isBlack={true} />
              ) : (
                <>{formatWithComma(availableAmount)}円</>
              )}
            </div>
          </div>
          {availablePoints > -1 && (
            <div className="l-price02__body__points">
              <p
                className={`${
                  pointErrors.includes(INPUT_ERROR.EXCEED_MAX_POINTS) ||
                  amountErrors.includes(INPUT_ERROR.EXCEED_MAX_AMOUNT)
                    ? '-error'
                    : ''
                }`}
              >
                保有Pontaポイント：
                <b>{formatWithComma(availablePoints) + 'P'}</b>
              </p>
              <label>
                <input
                  type="radio"
                  id={POINTS_SELECTION.ALL}
                  value={POINTS_SELECTION.ALL}
                  onChange={onPointsSelectChange}
                  checked={purchaseInfo.pointsSelect === POINTS_SELECTION.ALL}
                  name="usePoints"
                  disabled={availablePoints === 0}
                />{' '}
                すべてのポイントを利用
              </label>
              <label>
                <input
                  type="radio"
                  id={POINTS_SELECTION.PARTIAL}
                  value={POINTS_SELECTION.PARTIAL}
                  onChange={onPointsSelectChange}
                  checked={
                    purchaseInfo.pointsSelect === POINTS_SELECTION.PARTIAL
                  }
                  name="usePoints"
                  disabled={availablePoints === 0}
                />{' '}
                一部利用
                <input
                  className={`numeric ${
                    pointErrors.length !== 0 ||
                    (globalErrors.length !== 0 &&
                      !amountErrors.includes(INPUT_ERROR.EXCEED_MAX_AMOUNT))
                      ? '-error'
                      : ''
                  }`}
                  onChange={onPointsChange}
                  inputMode="numeric"
                  value={
                    purchaseInfo.pointsSelect === POINTS_SELECTION.PARTIAL
                      ? purchaseInfo.points
                      : ''
                  }
                  placeholder="ーーー"
                  disabled={availablePoints === 0}
                />{' '}
                P
              </label>
              {pointErrors.includes(INPUT_ERROR.EXCEED_DIGITS) && (
                <span className="-error">
                  ※利用するPontaポイントは10,000,000,000P未満でご入力ください。
                </span>
              )}
              {globalErrors.includes(INPUT_ERROR.EXCEED_PURCHASE_AMOUNT) && (
                <span className="-error">
                  ※購入金額以内の数字を入力してください。
                </span>
              )}
              {pointErrors.includes(INPUT_ERROR.EXCEED_MAX_POINTS) && (
                <span className="-error">
                  ※保有Pontaポイント以内の数字を入力してください。
                </span>
              )}
              <label>
                <input
                  type="radio"
                  id={POINTS_SELECTION.NONE}
                  value={POINTS_SELECTION.NONE}
                  onChange={onPointsSelectChange}
                  checked={purchaseInfo.pointsSelect === POINTS_SELECTION.NONE}
                  name="usePoints"
                  disabled={nonePointsOptionDisabled}
                />{' '}
                利用しない
              </label>
            </div>
          )}
          {globalErrors.includes(INPUT_ERROR.SUM_NOT_EQUAL) &&
            !amountErrors.includes(INPUT_ERROR.EXCEED_MAX_AMOUNT) && (
              <p className="-error">
                ※購入金額は、「お預り金残高」と「利用するPontaポイント（1P=1円）」の合計額の範囲内で入力してください。
              </p>
            )}
          <div className="l-notes01">
            <p>ご注意事項</p>
            <ul>
              <li>
                基準価額確定後、入力した金額の範囲内で購入できる口数が、買付口数になります。
              </li>
              <li>
                購入金額の受渡方法（決済方法）は、お預り金からの充当となります。
              </li>
              {availablePoints > -1 && (
                <>
                  <li>
                    auカブコム証券にご登録のau
                    IDのPontaポイントをご利用できます。
                  </li>
                  <li>
                    「Pontaポイント」のご利用を選択した場合、ご利用ポイント（1P=1円）をauカブコム証券が買取り、買取ったポイント分の現金をお客さまの証券口座に振替えの上、決済処理を行います。また、「Pontaポイント」と「現金（お預り金残高）」を両方利用した場合で、発注金額（購入金額）よりも約定金額が下がった場合は、ポイントが優先利用されます。詳しくは、auカブコム証券の
                    <a
                      target="_blank"
                      className="kabucom-link"
                      href={EXTERNAL_URL_FAQ}
                    >
                      「よくあるご質問」
                    </a>
                    をご確認ください。
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={
          !purchaseInfo.price ||
          (purchaseInfo.pointsSelect === POINTS_SELECTION.PARTIAL &&
            !purchaseInfo.points) ||
          amountErrors.length !== 0 ||
          pointErrors.length !== 0 ||
          globalErrors.length !== 0
        }
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </div>
  )
}

export default InvestmentAmountPage
