import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ACCOUNT_TYPES } from '../../common/utils/constants'
import { fetchMyFunds } from '../../common/utils/fetcher'
import { Position } from '../../common/utils/types'
import AuthErrorText from '../molecules/AuthErrorText'
import ServerErrorText from '../molecules/ServerErrorText'
import FundRanking from '../organisms/FundRanking'
import PositionTable from '../organisms/PositionTable'
import TradeDisclaimer from '../organisms/TradeDisclaimer'
import Accordion from '../organisms/Accordion'

const FundListPage = () => {
  const [positions, setPositions] = useState([] as Position[])

  const [isLoadingPositions, setLoadingPositions] = useState(true)

  const [serverError, setServerError] = useState(false)

  const [authError, setAuthError] = useState(false)

  const [isMyFundsHidden, setMyFundsHidden] = useState(false)

  const [isFundRankingHidden, setFundRankingHidden] = useState(false)

  const loadData = useCallback(async () => {
    const fundRes = await fetchMyFunds()
    if (fundRes.status === 401 || fundRes.status === 403) {
      setAuthError(true)
    } else if (fundRes.status !== 200 || !fundRes.data) {
      setServerError(true)
    } else {
      setPositions(
        fundRes &&
          fundRes.data &&
          fundRes.data.items &&
          fundRes.data.items.map((item) => ({
            fundCode: item.fundCode,
            fundName: item.fundName,
            accountType: ACCOUNT_TYPES[item.accountType.toString()],
            acquisitionPrice: item.acquisitionPrice,
            evaluationPrice: item.hyoukagaku,
            evaluationProfit: item.hyoukaSoneki,
          }))
      )
    }
    setLoadingPositions(false)
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const onViewFundRanking = () => {
    setMyFundsHidden(true)
    setFundRankingHidden(false)
  }

  return (
    <StyledFundListPage>
      <div className="fund-ranking-btn-container-outer">
        <div className="fund-ranking-btn-container-inner">
          <p>
            投資信託ランキングから
            <br />
            投資信託を購入することができます。
          </p>
          <button className="fund-ranking-btn" onClick={onViewFundRanking}>
            ▼ランキングを見る
          </button>
        </div>
      </div>
      <Accordion
        title={
          isMyFundsHidden
            ? '投資信託保有資産明細を見る'
            : '投資信託保有資産明細'
        }
        boldTitle
        isHiddenState={isMyFundsHidden}
        setHiddenState={setMyFundsHidden}
      >
        {authError ? (
          <AuthErrorText />
        ) : serverError ? (
          <ServerErrorText />
        ) : (
          <PositionTable positions={positions} isLoading={isLoadingPositions} />
        )}
      </Accordion>
      <FundRanking
        isHiddenState={isFundRankingHidden}
        setHiddenState={setFundRankingHidden}
      />
      <TradeDisclaimer />
    </StyledFundListPage>
  )
}

export default FundListPage

const StyledFundListPage = styled.div`
  div.fund-ranking-btn-container-outer {
    background: #c36833;
    display: flex;
  }

  div.fund-ranking-btn-container-inner {
    width: 100%;
    margin: 20px;
    padding: 25px 15px;
    background: #ffffff;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center;
  }

  button.fund-ranking-btn {
    width: 100%;
    outline: none;
    background: #ff6400;
    border: none;
    padding: 10px 0;
    margin: 10px 0 0 0;
    font-size: 1.4rem;
    color: #ffffff;
    border-radius: 21px;
  }
`
