import React, { useState } from 'react'
import styled from 'styled-components'
import {
  PURCHASE_STEPS,
  RESERVE_PLAN_STEPS,
} from '../../common/utils/constants'

type ProgressStepBarProps = {
  currentStep: string
  isReserve?: boolean
}

const ProgressStepBar = ({ currentStep, isReserve }: ProgressStepBarProps) => {
  const [STEPS] = useState(isReserve ? RESERVE_PLAN_STEPS : PURCHASE_STEPS)

  const [stepNames] = useState(Object.values(STEPS).map((step) => step.name))

  return (
    <StyledProgressStepBar>
      <ol className="progress">
        {stepNames.map((stepName, idx) => {
          const stepNum = idx + 1
          const currentStepNum = Number(
            Object.keys(STEPS).find((key) => STEPS[key].name === currentStep)
          )
          return (
            <li
              key={`step-${stepNum}`}
              className={
                stepNum < currentStepNum
                  ? 'done'
                  : stepNum === currentStepNum
                  ? 'active'
                  : ''
              }
            >
              <span className="step">
                <span>{stepNum}</span>
              </span>
              <span className="name">{stepName}</span>
            </li>
          )
        })}
      </ol>
    </StyledProgressStepBar>
  )
}

export default ProgressStepBar

const StyledProgressStepBar = styled.div`
  .progress {
    width: 100%;
    list-style: none;
    list-style-image: none;
    margin: 20px 0 20px 0;
    padding: 0;

    li {
      float: left;
      text-align: center;
      position: relative;
      list-style: none;
      width: 20%;
    }

    .name {
      display: block;
      text-align: center;
      margin-bottom: 1em;
      color: black;
      opacity: 0.3;
      white-space: pre-wrap;
    }

    .done .name,
    .active .name {
      opacity: 1;
    }
  }

  .progress .step {
    border: 3px solid #eee;
    background-color: #eee;
    border-radius: 50%;
    line-height: 1.2;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    z-index: 0;
    span {
      color: white;
    }
  }

  .progress .active .name,
  .progress .active .step span {
    opacity: 1;
  }

  .progress .step:before {
    content: '';
    display: block;
    background-color: #eee;
    height: 0.4em;
    width: 50%;
    position: absolute;
    top: 0.7em;
    left: 0;
    z-index: -1;
  }

  .progress .step:after {
    content: '';
    display: block;
    background-color: #eee;
    height: 0.4em;
    width: 50%;
    position: absolute;
    top: 0.7em;
    right: 0;
    z-index: -1;
  }

  .progress li:first-of-type .step:before {
    display: none;
  }

  .progress li:last-of-type .step:after {
    display: none;
  }

  .progress .done .step,
  .progress .done .step:before,
  .progress .done .step:after,
  .progress .active .step,
  .progress .active .step:before {
    background-color: #ff6400;
  }

  .progress .done .step,
  .progress .active .step {
    border: 3px solid #ff6400;
  }
`
