import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import {
  ROUTE_MY_FUND_LIST,
  ROUTE_FUND_RANKING,
  ROUTE_INDIVIDUAL_FUND,
  ROUTE_PROSPECTUS,
  ROUTE_ACCOUNT_SELECTION,
  ROUTE_DISTRIBUTION_TYPE,
  ROUTE_INVESTMENT_AMOUNT,
  ROUTE_ORDER_CONFIRMATION,
  ROUTE_ORDER_COMPLETION,
  ROUTE_AUTH_DISCLAIMER,
  ROUTE_AUTH_SUCCESS,
  ROUTE_AUTH_FAILURE,
  ROUTE_SERVER_FAILURE,
  RESERVE_ROUTES,
} from './common/utils/constants'
import AccountSelectionPage from './components/pages/AccountSelectionPage'
import DistributionTypePage from './components/pages/DistributionTypePage'
import FundListPage from './components/pages/FundListPage'
import IndividualFundPage from './components/pages/IndividualFundPage'
import InvestmentAmountPage from './components/pages/InvestmentAmountPage'
import UnauthorizedPage from './components/pages/UnauthorizedPage'
import OrderCompletionPage from './components/pages/OrderCompletionPage'
import OrderConfirmationPage from './components/pages/OrderConfirmationPage'
import ProspectusPage from './components/pages/ProspectusPage'
import FundRankingPage from './components/pages/FundRankingPage'
import AuthorizedPage from './components/pages/AuthorizedPage'
import PrivateRoute from './PrivateRoute'
import ServerErrorPage from './components/pages/ServerErrorPage'
import AuthDisclaimerPage from './components/pages/AuthDisclaimerPage'
import ReserveAccountSelectionPage from './components/pages/reserve/ReserveAccountSelectionPage'
import ReserveDistributionTypePage from './components/pages/reserve/ReserveDistributionTypePage'
import ReserveProspectusPage from './components/pages/reserve/ReserveProspectusPage'
import ReservePlanSettingPage from './components/pages/reserve/ReservePlanSettingPage'
import ReserveOrderConfirmationPage from './components/pages/reserve/ReserveOrderConfirmationPage'
import ReserveOrderCompletionPage from './components/pages/reserve/ReserveOrderCompletionPage'

const ScrollToTop = () => {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}
export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PrivateRoute
          path={ROUTE_MY_FUND_LIST}
          component={FundListPage}
          exact
        />
        <Route path={ROUTE_FUND_RANKING} component={FundRankingPage} exact />
        <PrivateRoute
          path={ROUTE_INDIVIDUAL_FUND}
          component={IndividualFundPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_PROSPECTUS}
          component={ProspectusPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_ACCOUNT_SELECTION}
          component={AccountSelectionPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_DISTRIBUTION_TYPE}
          component={DistributionTypePage}
          exact
        />
        <PrivateRoute
          path={ROUTE_INVESTMENT_AMOUNT}
          component={InvestmentAmountPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_ORDER_CONFIRMATION}
          component={OrderConfirmationPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_ORDER_COMPLETION}
          component={OrderCompletionPage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.ACCOUNT_SELECTION}
          component={ReserveAccountSelectionPage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.DISTRIBUTION_TYPE}
          component={ReserveDistributionTypePage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.PROSPECTUS}
          component={ReserveProspectusPage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.PLAN_SETTING}
          component={ReservePlanSettingPage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.ORDER_CONFIRMATION}
          component={ReserveOrderConfirmationPage}
          exact
        />
        <PrivateRoute
          path={RESERVE_ROUTES.ORDER_COMPLETION}
          component={ReserveOrderCompletionPage}
          exact
        />
        <PrivateRoute
          path={ROUTE_AUTH_DISCLAIMER}
          component={AuthDisclaimerPage}
          exact
        />
        <Route path={ROUTE_AUTH_SUCCESS} component={AuthorizedPage} exact />
        <Route path={ROUTE_AUTH_FAILURE} component={UnauthorizedPage} exact />
        <Route path={ROUTE_SERVER_FAILURE} component={ServerErrorPage} exact />
      </Switch>
    </BrowserRouter>
  )
}
