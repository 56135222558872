import { ProgressStep } from './types'

export const ROUTE_MY_FUND_LIST = '/my-page/my-assets/fund'
export const ROUTE_FUND_RANKING = '/my-page/ranking/fund'
export const ROUTE_INDIVIDUAL_FUND = '/fund/:fundCode'
export const ROUTE_PROSPECTUS =
  '/fund/:fundCode/purchase/prospectus/:accountType/:investmentType'
export const ROUTE_ACCOUNT_SELECTION =
  '/fund/:fundCode/purchase/account-selection'
export const ROUTE_DISTRIBUTION_TYPE =
  '/fund/:fundCode/purchase/distribution-type'
export const ROUTE_INVESTMENT_AMOUNT =
  '/fund/:fundCode/purchase/investment-amount'
export const ROUTE_ORDER_CONFIRMATION =
  '/fund/:fundCode/purchase/order-confirmation/:accountType/:investmentType/:investmentAmount/:requestId'
export const ROUTE_ORDER_COMPLETION =
  '/fund/:fundCode/purchase/order-completion'
export const RESERVE_ROUTES = {
  ACCOUNT_SELECTION: '/fund/:fundCode/reserve/account-selection',
  DISTRIBUTION_TYPE: '/fund/:fundCode/reserve/distribution-type',
  PROSPECTUS: '/fund/:fundCode/reserve/prospectus/:accountType/:investmentType',
  PLAN_SETTING: '/fund/:fundCode/reserve/plan-setting',
  ORDER_CONFIRMATION: '/fund/:fundCode/reserve/order-confirmation',
  ORDER_COMPLETION: '/fund/:fundCode/reserve/order-completion',
}
export const ROUTE_AUTH_DISCLAIMER = '/auth/request'
export const ROUTE_AUTH_SUCCESS = '/auth/success'
export const ROUTE_AUTH_FAILURE = '/auth/failure'
export const ROUTE_SERVER_FAILURE = '/server/failure'
export const URL_PROSPECTUS_CALLBACK = ':apiUrl/prospectus/callback'

export const ACCOUNT_TYPE_ALL = '全て'
export const ACCOUNT_TYPE_NORMAL = '一般'
export const ACCOUNT_TYPE_SPECIAL = '特定'
export const ACCOUNT_TYPE_NISA = 'NISA'
export const ACCOUNT_TYPES: { [key: string]: string } = {
  '1': ACCOUNT_TYPE_NORMAL,
  '2': ACCOUNT_TYPE_SPECIAL,
  '4': ACCOUNT_TYPE_NISA,
}

export const PURCHASE_TYPE_SPOT = '購入'
export const PURCHASE_TYPE_RESERVE = '積立'
export const PURCHASE_TYPES = [PURCHASE_TYPE_SPOT, PURCHASE_TYPE_RESERVE]

export const PURCHASE_STEP_ACCOUNT = '口座'
export const PURCHASE_STEP_DISTRIBUTION = '分配金'
export const PURCHASE_STEP_PROSPECTUS = '目論見書と\n注意事項'
export const PURCHASE_STEP_INVESTMENT = '購入金額'
export const PURCHASE_STEP_ORDER_CONFIRMATION = '注文内容\n確認'
export const PURCHASE_STEPS: {
  [key: string]: ProgressStep
} = {
  '1': {
    name: PURCHASE_STEP_ACCOUNT,
    route: ROUTE_ACCOUNT_SELECTION,
  },
  '2': {
    name: PURCHASE_STEP_DISTRIBUTION,
    route: ROUTE_DISTRIBUTION_TYPE,
  },
  '3': {
    name: PURCHASE_STEP_PROSPECTUS,
    route: ROUTE_PROSPECTUS,
  },
  '4': {
    name: PURCHASE_STEP_INVESTMENT,
    route: ROUTE_INVESTMENT_AMOUNT,
  },
  '5': {
    name: PURCHASE_STEP_ORDER_CONFIRMATION,
    route: ROUTE_ORDER_CONFIRMATION,
  },
}

export const RESERVE_PLAN_STEP_ACCOUNT = '口座'
export const RESERVE_PLAN_STEP_DISTRIBUTION = '分配金'
export const RESERVE_PLAN_STEP_PROSPECTUS = '目論見書と\n注意事項'
export const RESERVE_PLAN_STEP_PLAN_SETTING = '積立設定'
export const RESERVE_PLAN_STEP_ORDER_CONFIRMATION = '注文内容\n確認'
export const RESERVE_PLAN_STEPS: {
  [key: string]: ProgressStep
} = {
  '1': {
    name: RESERVE_PLAN_STEP_ACCOUNT,
    route: RESERVE_ROUTES.ACCOUNT_SELECTION,
  },
  '2': {
    name: RESERVE_PLAN_STEP_DISTRIBUTION,
    route: RESERVE_ROUTES.DISTRIBUTION_TYPE,
  },
  '3': {
    name: RESERVE_PLAN_STEP_PROSPECTUS,
    route: RESERVE_ROUTES.PROSPECTUS,
  },
  '4': {
    name: RESERVE_PLAN_STEP_PLAN_SETTING,
    route: RESERVE_ROUTES.PLAN_SETTING,
  },
  '5': {
    name: RESERVE_PLAN_STEP_ORDER_CONFIRMATION,
    route: RESERVE_ROUTES.ORDER_CONFIRMATION,
  },
}

export const DISTRIBUTION_TYPE_DISTRIBUTIVE = '受け取る\n（一般型）'
export const DISTRIBUTION_TYPE_ACCUMULATIVE = '投資にまわす\n（累投型）'
export const DISTRIBUTION_TYPES: { [key: string]: string } = {
  '1': DISTRIBUTION_TYPE_DISTRIBUTIVE,
  '2': DISTRIBUTION_TYPE_ACCUMULATIVE,
}

export const PAYMENT_TYPE_DEPOSIT = 'お預り金'
export const PAYMENT_TYPE_CREDIT_CARD = 'クレジットカード'
export const PAYMENT_TYPES: { [key: string]: string } = {
  '0': PAYMENT_TYPE_DEPOSIT,
  '5': PAYMENT_TYPE_CREDIT_CARD,
}

export const CODE_ORDER_SUCCESS = '0'

export const DOCUMENT_STATUS_CONFIRMED = 10

export const FUND_RANKING_TYPES: { [rankingType: string]: string } = {
  '101-1W': '販売金額（週間）',
  '101-1M': '販売金額（月間）',
  '106-6M': 'リターン（半年）',
  '106-1Y': 'リターン（年）',
  '103-1M': '積立設定金額（月間）',
  '107-1M': '分配金利回り（月間）',
  '110-1M': '純資産増加額（月間）',
}

export const EXTERNAL_URL_STOCK =
  'https://www.jibunbank.co.jp/redirect/sec-login.html'
export const EXTERNAL_URL_TRADE_DISCLAIMER =
  'https://www.jibunbank.co.jp/redirect/kabu_51.html'
export const EXTERNAL_URL_TRADE_DISCLAIMER_2 =
  'https://www.jibunbank.co.jp/redirect/kabu_05.html'
export const EXTERNAL_URL_TRADE_DISCLAIMER_3 =
  'https://kabu.com/company/info/escapeclause.html?mflg=1'
// TBD
export const EXTERNAL_URL_POSITION_TABLE =
  'https://www.jibunbank.co.jp/redirect/sec-login.html'
export const EXTERNAL_URL_FUND_INFO =
  'https://fundinfo.kabu.co.jp/fund-details-snapshot?fundCode=${fundCode}'
export const EXTERNAL_URL_API_SERVICE_RULES =
  'https://s10.kabu.co.jp/pdf/Gmkpdf/service/APIServiceRules.pdf'
export const EXTERNAL_URL_KABUCOM =
  'https://s10.kabu.co.jp/iPhone/Trade/NisaMenu.asp'
export const EXTERNAL_URL_FAQ = 'https://faq.kabu.com/s/article/k002564'
export const EXTERNAL_URL_TSUMITATE_RULES =
  'https://kabu.com/pdf/Gmkpdf/service/toushi_tumitate.pdf'
export const EXTERNAL_URL_CREDITCARD_RULES =
  'https://kabu.com/pdf/Gmkpdf/service/creditcard.pdf'
export const EXTERNAL_URL_KABUCOM_USER = 'https://s20.si0.kabu.co.jp/members/'

export const NO_DATA = '-'

export const UNITS: { [key: string]: string } = {
  '1': '円',
  '2': '口',
}

export const SIGN_PLUS = '＋'
export const SIGN_MINUS = '−'

export const ORDER_RESULT_MAPPING: { [key: string]: string } = {
  '0': '正常終了コード',
  '-1': '異常終了コード',
  '2': '口座番号未存在エラー',
  '5': '注文有効期限不正エラー',
  '8': '返済建玉情報不正エラー',
  '11': '銘柄情報不正エラー',
  '12': '先物夕場取引日付エラー',
  '15': '取引数量単位エラー',
  '16': '取引数量不正エラー',
  '17': '呼値不正エラー',
  '18': '制限値幅エラー',
  '19': '取引値段不正エラー',
  '21': '可能額不正エラー',
  '22': '注文制限超エラー',
  '26': '信用取引情報不正エラー',
  '32': '口座種別不正エラー',
  '38': '取引所重複電文エラー',
  '41': '指定注文未存在エラー',
  '42': '指定注文有効期限切れエラー',
  '43': '指定注文約定済エラー',
  '44': '指定注文明細未存在エラー',
  '45': '建玉返済期日超過エラー',
  '47': '指定注文終了済エラー',
  '49': '訂正内容不正エラー',
  '50': 'トリガ訂正エラー',
  '60': '取消依頼済エラー',
  '105': '子注文受付エラー',
  '108': '空売りエラー',
  '109': '売買区分エラー',
  '110': '自己委託区分エラー',
  '111': '注文属性区分エラー',
  '112': '執行条件エラー',
  '113': '前場指定エラー',
  '114': '値幅自動調整エラー',
  '115': 'カブコールID不正エラー',
  '116': '有効期間種別不正',
  '117': '値段不正エラー',
  '118': '有効セッション不正',
  '119': '値段種別不正',
  '120': '動的値段設定不正',
  '121': '成行発注不正',
  '122': '訂正数量チェックエラー',
  '123': '自動注文不可エラー',
  '126': '執行条件エラー',
  '127': 'システムエラー',
  '10017': '可能額戻り値エラー',
  '10020': 'ザラバ以外での発注エラー',
  '10030': 'Pontaポイント処理エラー',
  '10031': '顧客規制エラー',
  '10032': 'Pontaポイント処理エラー',
  '10033': 'auID取得エラー',
  '100001': '受渡方法不正エラー',
  '100002': '自動引落選択エラー',
  '100003': '自動引落選択エラー',
  '100004': '自動引落選択エラー',
  '100029': '手数料コード不正エラー',
  '100031': '預り区分エラー',
  '100033': 'コンプライアンスエラー',
  '100051': 'ハードリミットエラー',
  '100052': '派生建玉枚数制限エラー',
  '100053': '信用建玉枚数制限エラー',
  '100202': '約諾書未読エラー',
  '100203': '特定口座指定エラー',
  '100204': '信用取引指定エラー',
  '100205': '派生取引指定エラー',
  '100206': 'インサイダエラー',
  '100207': '立替金発生エラー',
  '100208': '追証発生エラー',
  '100209': '保証金不足発生エラー',
  '100211': '即金銘柄取引区分エラー',
  '100212': 'PTS貸株取引エラー',
  '100213': '最大決済件数エラー',
  '100215': '即金銘柄訂正エラー',
  '100216': 'ワンショット制限エラー',
  '100217': '即ビンゴエラー',
  '100218': 'W指値条件指定エラー',
  '100220': 'リレー条件不正エラー',
  '100221': '±指値訂正エラー',
  '100222': 'Uターン子注文複数エラー',
  '100223': '新株予約権証券同意書エラー',
  '100224': 'PTS同意書エラー',
  '100225': '信用取引規制エラー',
  '100226': '外国証券取引規制エラー',
  '100230': 'トレーリングストップ規制エラー',
  '100231': 'トレーリングストップ規制エラー',
  '100232': 'トレーリングストップ条件エラー',
  '100233': '必要証拠金未算出エラー',
  '100234': '訂正時最大決済件数エラー',
  '100240': '時間指定注文取引時間外エラー',
  '100244': '場引けエラー',
  '100246': 'ザラバ以外での発注エラー',
  '100247': 'OMX移行時取引規制エラー',
  '100250': '時間指定注文取引時間外エラー',
  '100251': '時間指定注文取引時間外エラー',
  '100252': '時間指定注文指定時刻エラー',
  '100253': '時間指定注文指定時刻エラー',
  '100254': '時間指定注文指定時刻エラー',
  '100255': '時間指定注文指定時刻エラー',
  '100256': '時間指定注文指定時刻エラー',
  '100257': '時間指定注文指定時刻エラー',
  '100258': '時間指定注文指定時刻エラー',
  '100259': '時間指定注文指定時刻エラー',
  '100260': '時間指定注文指定時刻エラー',
  '100261': '時間指定注文指定時刻エラー',
  '100262': '時間指定注文指定時刻エラー',
  '100263': '一般信用キャップ付き残数超過エラー',
  '100264': '一般信用売建可能銘柄注文受付時間外エラー',
  '100265': '一般信用キャップ付き有効期限エラー',
  '100266': '空売り規制銘柄信用新規売注文単元超過エラー',
  '100267': '代用不適格銘柄発注エラー',
  '100268': '即金銘柄有効期限エラー',
  '100269': '即金銘柄値段種別エラー',
  '100270': '日経VI先物発注場エラー',
  '100271': '一般信用期日指定エラー',
  '100272': '整理銘柄新規発注エラー',
  '100273': '権利修正前日以降発注エラー',
  '100274': '受付時間外(抽選・入札銘柄)',
  '100282': 'FrontChannelID不正',
  '100285': 'NISA不可銘柄発注エラー',
  '100287': '同一銘柄予約注文複数発注エラー',
  '100294': '注文上限エラー',
  '100295': '内部者登録銘柄エラー',
  '100301': 'デイトレ新規建注文条件エラー',
  '100302': '売建抑止エラー',
  '100303': 'プレミアム料項目指定エラー',
  '100304': 'プレミアム料指定エラー',
  '100305': 'プレミアム料範囲エラー',
  '100306': 'プレミアム料刻値エラー',
  '100307': '信用新規買注文訂正エラー(保証金増加)',
  '100308': '信用新規買時間指定注文/W指値注文エラー',
  '100309': '信用新規買時間指定注文/W指値注文訂正エラー',
  '100310': '信用新規買不成後場指定エラー',
  '100311': '手数料コース変更申込エラー',
  '100312': '手数料コース変更申込エラー',
  '100313': 'クロス取引発注エラー',
  '100314': 'クロス取引訂正エラー',
  '100315': '適格投資家で発注できない成行エラー',
  '100316': '適格投資家で訂正できない成行エラー',
  '100317': '累計で51単元以上(前場)の成行エラー',
  '100318': '累計で51単元以上(後場)の成行エラー',
  '100319': '累計で51単元以上(前場)の成行訂正エラー',
  '100320': '累計で51単元以上(後場)の成行訂正エラー',
  '100321': 'ワンショット51単元以上の成行エラー',
  '100322': '訂正できない成行エラー',
  '100323': 'プレミアム料範囲エラー',
  '100324': 'プレミアム料刻値エラー',
  '100325': 'プレミアム料訂正エラー',
  '100326': '建玉返済期日超過エラー',
  'K00795': '可能額チェックエラー(品受取引)\n日計りチェックエラー(品渡取引)',
  'K01321': '返済可能数量チェックエラー',
  'K01335': '代用不適格チェックエラー\n(代用不適格銘柄で代用指定)\nシステムエラー\n(パラメータ不正)',
  'K01360': '信用建玉返済可能数量チェックエラー',
  'K01361': '信用建玉ID存在チェックエラー',
  'K01362': '現物残高数量チェックエラー',
  'K01363': 'コンプラチェックエラー',
  'K01364': '取引番号存在チェックエラー',
  'K01792': 'デイトレ品受品渡返済期日チェックエラー',
  'S10001': '顧客レベルエラー'
}

export const FREE_COMMISSION_DATA = '無料'
export const FREE_COMMISSION_DISPLAY = 'なし'

export const INPUT_ERROR = {
  EXCEED_MAX_AMOUNT: 'max', // amount error
  LESS_THAN_MIN_AMOUNT: 'min', // amount error
  INVALID_MIN_UNIT: 'unit', // amount error
  EXCEED_MAX_POINTS: 'max-points', // point error
  EXCEED_DIGITS: 'max-digits', // amount and point error
  EXCEED_PURCHASE_AMOUNT: 'exceed-purchase', // global error
  SUM_NOT_EQUAL: 'sum-mismatch', // global error
  EXCEED_NISA_ALLOWANCE: 'nisa-allowance', // amount error
}

export const NO_INCREASE_MONTH = 'なし'
export const NO_INCREASE_AMOUNT = 'なし'

export const INCREASE_AMOUNT = {
  MAX: 9999999999,
  MIN: 1,
  MIN_TRADE_UNIT: 1,
  STR_LENGTH: 14,
}

export const TRADE_AMOUNT = {
  MAX: 9999999999,
  MIN: 100,
  MIN_TRADE_UNIT: 1,
  STR_LENGTH: 14,
}

export const POINT_AVAILABLE_STATUS = {
  AVAILABLE: 1,
}
