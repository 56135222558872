type Env = 'dev' | 'stg' | 'prod'

interface Environment {
  ENV: Env
  API_URL: string
  FUND_RANKING_URL: string
}

const dev: Environment = {
  ENV: 'dev',
  API_URL: `http://localhost:8888/api/v1`,
  FUND_RANKING_URL: `${window.location.origin}/fund_ranking/v1/latest.json`,
}

const stg: Environment = {
  ENV: 'stg',
  API_URL: `${window.location.origin}/api/v1`,
  FUND_RANKING_URL: `${window.location.origin}/fund_ranking/v1/latest.json`,
}

const prod: Environment = {
  ENV: 'prod',
  API_URL: `${window.location.origin}/api/v1`,
  FUND_RANKING_URL: `${window.location.origin}/fund_ranking/v1/latest.json`,
}

const getConfig = (): Environment => {
  const host: string = window.location.host
  if (host.includes('localhost')) {
    return dev
  } else if (host.includes('stg')) {
    return stg
  } else {
    return prod
  }
}

const config = getConfig()
export default config
