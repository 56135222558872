import ProgressStepBar from '../../components/organisms/ProgressStepBar'
import React, { useCallback, useEffect, useState } from 'react'
import FundInfo from '../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPE_NISA,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  PURCHASE_STEP_ACCOUNT,
  ROUTE_DISTRIBUTION_TYPE,
  ROUTE_INDIVIDUAL_FUND,
} from '../../common/utils/constants'
import { useAppContext } from '../../contexts/AppContext'
import PurchaseWizardButtons from '../../components/molecules/PurchaseWizardButtons'
import { fetchFundWallet } from '../../common/utils/fetcher'
import Spinner from '../molecules/Spinner'
import styled from 'styled-components'
import { isDistributionTypeDisabled } from '../../common/utils/fund'

const AccountSelectionPage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }
  const [accountTypeNames] = useState(Object.values(ACCOUNT_TYPES))

  const { fund, purchaseInfo, setPurchaseInfo } = useAppContext()

  const [isNisaDisabled, setNisaDisabled] = useState(true)

  const [isLoading, setLoading] = useState(true)

  const history = useHistory()

  const loadData = useCallback(async () => {
    const investmentType = isDistributionTypeDisabled(
      DISTRIBUTION_TYPE_DISTRIBUTIVE,
      fund
    )
      ? 2
      : 1
    const walletRes = await fetchFundWallet(fundCode, investmentType)
    const nisaTradeLimit = walletRes.data.nisaTradeLimit
    if (nisaTradeLimit) {
      setNisaDisabled(false)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const onPrevButtonClicked = () => {
    history.push(ROUTE_INDIVIDUAL_FUND.replace(':fundCode', fundCode))
  }

  const onNextButtonClicked = () => {
    if (purchaseInfo.accountType) {
      history.push(ROUTE_DISTRIBUTION_TYPE.replace(':fundCode', fundCode))
    }
  }

  const onAccountButtonClicked = (accountTypeName: string) => {
    if (accountTypeName === ACCOUNT_TYPE_NISA && isNisaDisabled) {
      return
    }
    setPurchaseInfo({
      ...purchaseInfo,
      accountType: Number(
        Object.keys(ACCOUNT_TYPES).find(
          (key) => ACCOUNT_TYPES[key] === accountTypeName
        )
      ),
    })
  }

  return (
    <StyledAccountSelectionPage>
      <ProgressStepBar currentStep={PURCHASE_STEP_ACCOUNT} />
      <div
        className={`l-link03 account-selection ${isLoading ? '-loading' : ''}`}
      >
        <p className="l-link03__ttl">取引される口座区分は？</p>
        {isLoading ? (
          <Spinner isBlack short />
        ) : (
          <ul className="l-link03__list">
            {accountTypeNames.map((accountTypeName) => {
              return (
                <li
                  key={accountTypeName}
                  className={`
                  ${
                    ACCOUNT_TYPES[purchaseInfo.accountType] === accountTypeName
                      ? 'in-active'
                      : ''
                  } ${
                    accountTypeName === ACCOUNT_TYPE_NISA && isNisaDisabled
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={() => {
                    onAccountButtonClicked(accountTypeName)
                  }}
                >
                  <button>
                    <span>{accountTypeName}</span>
                  </button>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={!purchaseInfo.accountType || isLoading}
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </StyledAccountSelectionPage>
  )
}

export default AccountSelectionPage

const StyledAccountSelectionPage = styled.div`
  .account-selection ul span {
    font-size: 1.5rem;
  }
`
