import moment from 'moment-timezone'
import 'moment/locale/ja'
import { Fund, PurchaseInfo, ReservePlanInfo } from './types'

export const formatWithComma = (num: number | string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// Generates random string consisting of alphabet and numeric characters.
// Taken from https://stackoverflow.com/a/1349426
export const generateRandomId = (length: number = 20): string => {
  let result = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charsLength = chars.length
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength))
  }
  return result
}

// Generates requestId required for fund ordering API
export const generateRequestId = (
  fund: Fund,
  purchaseInfo: PurchaseInfo
): string => {
  return `${generateRandomId(5)}${fund.fundCode}${purchaseInfo.accountType}${
    purchaseInfo.investmentType
  }${new Date().getTime()}`
}

// Generates requestId required for fund reserve plan API
export const generateReserveRequestId = (
  fund: Fund,
  reservePlanInfo: ReservePlanInfo
): string => {
  return `${generateRandomId(5)}${fund.fundCode}${reservePlanInfo.accountType}${
    reservePlanInfo.investmentType
  }${reservePlanInfo.paymentType}${new Date().getTime()}`
}

export const getKeyByValue = (
  object: { [key: string]: any },
  value: any
): string | undefined => {
  return Object.keys(object).find((key: string) => object[key] === value)
}

// format YYYYMMDD to YYYY/MM/DD
export const formatStandardDate = (date: string) => {
  return moment(date, 'YYYYMMDD').format('YYYY/MM/DD')
}

// get weekday in Japanese: '月', '火'...
export const getWeekday = (date: string, format: string = 'YYYY/MM/DD') => {
  return moment.tz(date, format, 'Asia/Tokyo').format('ddd')
}
