import React from 'react'
import CenteredText from './CenteredText'

const ServerErrorText = () => (
  <CenteredText>
    <p>
      サーバー側で問題が発送しました。
      <br />
      しばらく待って再度お試しください。
    </p>
  </CenteredText>
)

export default ServerErrorText
