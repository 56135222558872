import { DOCUMENT_STATUS_CONFIRMED } from './constants'
import { Prospectus, ProspectusResponseType } from './types'

export const createProspectusList = (
  prospectusRes: ProspectusResponseType,
  fundCode: string
): Prospectus[] => {
  return prospectusRes.data.documentInfo.map((document) => {
    return {
      fileName: document.fileName,
      isConfirmed: document.status === DOCUMENT_STATUS_CONFIRMED,
      requiresConfirmation: document.documentType !== '3',
      url: `${prospectusRes.data.url}?KeyCode=${prospectusRes.data.confirmCode}&FundCode=${fundCode}&documentType=${document.documentType}`,
    }
  })
}
