import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { ButtonProps } from './Button'

const CenteredButton = ({ ...props }: ButtonProps) => {
  return (
    <StyledCenteredButton>
      <Button {...props}></Button>
    </StyledCenteredButton>
  )
}

export default CenteredButton

const StyledCenteredButton = styled.div`
  & {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
`
