import ProgressStepBar from '../../components/organisms/ProgressStepBar'
import React, { useEffect, useState } from 'react'
import ProspectusLinks from '../../components/molecules/ProspectusLinks'
import FundInfo from '../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  ACCOUNT_TYPES,
  DISTRIBUTION_TYPES,
  PURCHASE_STEPS,
  PURCHASE_STEP_PROSPECTUS,
  ROUTE_DISTRIBUTION_TYPE,
  ROUTE_INVESTMENT_AMOUNT,
  ROUTE_PROSPECTUS,
  URL_PROSPECTUS_CALLBACK,
} from '../../common/utils/constants'
import {
  Prospectus,
  ProspectusResponseType,
  StockFundResponseType,
} from '../../common/utils/types'
import {
  fetchFund,
  fetchProspectus,
  parallelRun,
} from '../../common/utils/fetcher'
import { useAppContext } from '../../contexts/AppContext'
import { mapStockFundResponseToFund } from '../../common/utils/fund'
import PurchaseWizardButtons from '../../components/molecules/PurchaseWizardButtons'
import config from '../../config/config'
import { createProspectusList } from '../../common/utils/prospectus'

const ProspectusPage = () => {
  const history = useHistory()

  const { fundCode, accountType, investmentType } = useParams() as {
    fundCode: string
    accountType: string
    investmentType: string
  }

  const [handler, setHandler] = useState(
    null as null | ReturnType<typeof setTimeout>
  )

  const { fund, setFund, purchaseInfo, setPurchaseInfo } = useAppContext()

  const [prospectuses, setProspectuses] = useState(
    fund.prospectuses || ([] as Prospectus[])
  )

  const [loading, setLoading] = useState(true)

  const [disabled, setDisabled] = useState(false)

  const [isPollingStarted, setPollingStarted] = useState(false)

  useEffect(() => {
    let disabled = true
    if (!loading && purchaseInfo.confirmCode) {
      if (prospectuses.length > 0) {
        if (
          prospectuses
            .filter((prospectus) => prospectus.requiresConfirmation)
            .every((prospectus) => prospectus.isConfirmed)
        ) {
          disabled = false
        }
      }
    }
    setDisabled(disabled)
  }, [prospectuses, loading, purchaseInfo])

  useEffect(() => {
    if (
      !purchaseInfo.confirmCodeState ||
      !purchaseInfo.confirmCodeState[purchaseInfo.investmentType]
    ) {
      checkStatesAndLoadData()
    }
  }, [])

  useEffect(() => {
    if (
      !isPollingStarted &&
      purchaseInfo.confirmCodeState &&
      purchaseInfo.confirmCodeState[purchaseInfo.investmentType]
    ) {
      setPollingStarted(true)
      pollData()
    }
  }, [purchaseInfo.confirmCodeState])

  const loadData = async (
    investmentTypeFromParams?: number,
    accountTypeFromParams?: number
  ) => {
    const investmentType =
      investmentTypeFromParams || purchaseInfo.investmentType
    const accountType = accountTypeFromParams || purchaseInfo.accountType
    const confirmCode =
      purchaseInfo.confirmCodeState &&
      purchaseInfo.confirmCodeState[investmentType]
    const callbackUrl = URL_PROSPECTUS_CALLBACK.replace(
      ':apiUrl',
      config.API_URL
    )
    let newFund = fund
    let prospectusRes: ProspectusResponseType, fundRes: StockFundResponseType

    // if fundCode is not in states, i.e. after page redirection from prospectus site,
    // call fund API endpoint together with prospectus API endpoint;
    // otherwise, just fetch prospectus.
    if (!fund.fundCode) {
      const responses = await parallelRun(
        fetchProspectus(fundCode, investmentType, callbackUrl, confirmCode),
        fetchFund(fundCode)
      )
      prospectusRes = responses[0]
      fundRes = responses[1]
      newFund = mapStockFundResponseToFund(fundRes)
    } else {
      prospectusRes = await fetchProspectus(
        fundCode,
        investmentType,
        callbackUrl,
        confirmCode
      )
    }

    const prospectuses = createProspectusList(prospectusRes, fundCode)
    setProspectuses(prospectuses)
    setFund({
      ...newFund,
      prospectuses,
    })

    setPurchaseInfo({
      ...purchaseInfo,
      confirmCode: prospectusRes.data.confirmCode,
      confirmCodeState: {
        ...purchaseInfo.confirmCodeState,
        [investmentType]: prospectusRes.data.confirmCode,
      },
      investmentType: investmentType,
      accountType: accountType,
    })
    setLoading(false)
    return prospectuses
  }

  const checkStatesAndLoadData = async () => {
    let fetchedProspectuses: Prospectus[] = []
    if (purchaseInfo.investmentType && purchaseInfo.accountType) {
      fetchedProspectuses = await loadData()
    } else {
      const investmentTypeFromParams = Number(investmentType)
      const accountTypeFromParams = Number(accountType)
      if (
        investmentTypeFromParams &&
        Object.keys(DISTRIBUTION_TYPES).includes(
          investmentTypeFromParams.toString()
        ) &&
        accountTypeFromParams &&
        Object.keys(ACCOUNT_TYPES).includes(accountTypeFromParams.toString())
      ) {
        fetchedProspectuses = await loadData(
          investmentTypeFromParams,
          accountTypeFromParams
        )
      } else {
        // If there is no investment type in query param nor state,
        // return to Account Selection page
        history.replace(PURCHASE_STEPS['1'].route)
      }
    }
    return fetchedProspectuses
  }

  const pollData = async () => {
    const prospectuses = await checkStatesAndLoadData()
    const isProspectusPage = window.location.href.includes(
      ROUTE_PROSPECTUS.replace(':fundCode', fund.fundCode)
        .replace(':investmentType', purchaseInfo.investmentType.toString())
        .replace(':accountType', purchaseInfo.accountType.toString())
    )
    if (
      isProspectusPage &&
      prospectuses?.length > 0 &&
      prospectuses?.some(
        (prospectus) =>
          prospectus.requiresConfirmation && !prospectus.isConfirmed
      )
    ) {
      handler && clearTimeout(handler)
      setHandler(setTimeout(pollData, 1000))
    }
  }

  const removePolling = () => {
    handler && clearTimeout(handler)
  }

  const onPrevButtonClicked = () => {
    removePolling()
    history.push(ROUTE_DISTRIBUTION_TYPE.replace(':fundCode', fundCode))
  }

  const onNextButtonClicked = () => {
    if (purchaseInfo.confirmCode) {
      removePolling()
      history.push(ROUTE_INVESTMENT_AMOUNT.replace(':fundCode', fundCode))
    }
  }

  return (
    <div>
      <ProgressStepBar currentStep={PURCHASE_STEP_PROSPECTUS} />
      <ProspectusLinks prospectuses={prospectuses} loading={loading} />
      <div className="l-notes01 notice">
        <p>ご注意事項</p>
        <ul>
          <li>
            ご注文後に基準価額が上昇した場合、約定口数が当初の買付口数見込みを下回ったり、買付できないことがありますのでご留意ください。
          </li>
          <li>注文締切時間を過ぎたご注文は翌営業日扱いとなります。</li>
          <li>
            償還優遇枠を使用してのご注文は平日9:00～20:00までの受付となります。
          </li>
        </ul>
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={disabled}
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </div>
  )
}

export default ProspectusPage
