import ProgressStepBar from '../../../components/organisms/ProgressStepBar'

import React, { useCallback, useEffect, useState } from 'react'
import FundInfo from '../../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  formatWithComma,
  generateReserveRequestId,
  getKeyByValue,
} from '../../../common/utils/util'
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPE_NISA,
  EXTERNAL_URL_KABUCOM_USER,
  INCREASE_AMOUNT,
  NO_INCREASE_MONTH,
  PAYMENT_TYPES,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_DEPOSIT,
  RESERVE_PLAN_STEP_PLAN_SETTING,
  RESERVE_ROUTES,
  TRADE_AMOUNT,
} from '../../../common/utils/constants'
import { useAppContext } from '../../../contexts/AppContext'
import { fetchFundWallet } from '../../../common/utils/fetcher'
import Spinner from '../../molecules/Spinner'
import PurchaseWizardButtons from '../../../components/molecules/PurchaseWizardButtons'
import styled from 'styled-components'
import AmountInput from '../../../components/molecules/AmountInput'

const ReservePlanSettingPage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }

  const { fund, reservePlanInfo, setReservePlanInfo } = useAppContext()

  const [availableAmount, setAvailableAmount] = useState(-1)

  const [isBadInputSpecifiedAmount, setBadInputSpecifiedAmount] = useState(
    false
  )
  const [isBadInputIncreaseAmount, setBadInputIncreaseAmount] = useState(false)

  const [creditCardLimit, setCreditCardLimit] = useState(0)

  const [acceptCreditCardPayment, setAcceptCreditCardPayment] = useState(false)

  const history = useHistory()

  const loadData = useCallback(async () => {
    // TODO (@sercant): @ping-alpaca I think we shouldn't have the investment type
    // default here. But in case of reserve there is only one possibility so it is
    // ok I think.
    const investmentType = reservePlanInfo.investmentType || 2
    const specifiedDate = reservePlanInfo.specifiedDate || 1

    const walletRes = await fetchFundWallet(fundCode, investmentType)
    const tradeLimit =
      ACCOUNT_TYPES[reservePlanInfo.accountType] === ACCOUNT_TYPE_NISA &&
      walletRes.data.nisaTradeLimit !== null &&
      typeof walletRes.data.nisaTradeLimit !== 'undefined'
        ? walletRes.data.nisaTradeLimit
        : walletRes.data.fundTradeLimit
    setAvailableAmount(tradeLimit)

    const canUseCreditCard = walletRes.data.creditCardLimit !== null
    setAcceptCreditCardPayment(canUseCreditCard)
    setCreditCardLimit(walletRes.data.creditCardLimit || 0)

    const paymentType =
      reservePlanInfo.paymentType && canUseCreditCard
        ? reservePlanInfo.paymentType
        : 0

    setReservePlanInfo({
      ...reservePlanInfo,
      specifiedDate: specifiedDate,
      paymentType: paymentType,
    })
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const onPrevButtonClicked = () => {
    history.push(
      RESERVE_ROUTES.PROSPECTUS.replace(':fundCode', fundCode)
        .replace(':investmentType', reservePlanInfo.investmentType.toString())
        .replace(':accountType', reservePlanInfo.accountType.toString())
    )
  }

  const onNextButtonClicked = () => {
    if (reservePlanInfo.specifiedAmount) {
      let requestId = reservePlanInfo.requestId
      if (!requestId) {
        requestId = generateReserveRequestId(fund, reservePlanInfo)

        setReservePlanInfo({
          ...reservePlanInfo,
          requestId: requestId,
        })
      }
      history.push(
        RESERVE_ROUTES.ORDER_CONFIRMATION.replace(':fundCode', fundCode)
      )
    }
  }

  const onSpecifiedAmountChanged = (specifiedAmount: string) => {
    setReservePlanInfo({
      ...reservePlanInfo,
      specifiedAmount: specifiedAmount,
    })
  }

  const onIncreaseAmountChanged = (increaseAmount: string) => {
    setReservePlanInfo({
      ...reservePlanInfo,
      increaseAmount: increaseAmount,
    })
  }

  const onIncreaseMonth1Changed = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const increaseMonth1 =
      event.target.value !== NO_INCREASE_MONTH
        ? Number(event.target.value)
        : null
    setReservePlanInfo({
      ...reservePlanInfo,
      increaseMonth1: increaseMonth1,
      increaseAmount:
        increaseMonth1 || reservePlanInfo.increaseMonth2
          ? reservePlanInfo.increaseAmount
          : null,
    })
  }

  const onIncreaseMonth2Changed = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const increaseMonth2 =
      event.target.value !== NO_INCREASE_MONTH
        ? Number(event.target.value)
        : null
    setReservePlanInfo({
      ...reservePlanInfo,
      increaseMonth2: increaseMonth2,
      increaseAmount:
        increaseMonth2 || reservePlanInfo.increaseMonth1
          ? reservePlanInfo.increaseAmount
          : null,
    })
  }

  const onPaymentTypeChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const paymentType = Number(getKeyByValue(PAYMENT_TYPES, event.target.value))
    const paymentTypeName = event.target.value
    setReservePlanInfo({
      ...reservePlanInfo,
      paymentType: paymentType,
      specifiedDate:
        paymentTypeName !== PAYMENT_TYPE_CREDIT_CARD
          ? reservePlanInfo.specifiedDate
          : 1,
      increaseMonth1:
        paymentTypeName !== PAYMENT_TYPE_CREDIT_CARD
          ? reservePlanInfo.increaseMonth1
          : null,
      increaseMonth2:
        paymentTypeName !== PAYMENT_TYPE_CREDIT_CARD
          ? reservePlanInfo.increaseMonth2
          : null,
      increaseAmount:
        paymentTypeName !== PAYMENT_TYPE_CREDIT_CARD
          ? reservePlanInfo.increaseAmount
          : null,
    })
  }

  const onSpecifiedDateChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setReservePlanInfo({
      ...reservePlanInfo,
      specifiedDate: Number(event.target.value),
    })
  }

  return (
    <StyledReservePlanSettingPage>
      <ProgressStepBar currentStep={RESERVE_PLAN_STEP_PLAN_SETTING} isReserve />
      <div className="l-price02 investment-amount">
        <p className="l-price02__head">下記内容を入力してください。</p>
        <div className="l-price02__body">
          <div className={`l-price02__body__num`}>
            <p>auカブコム証券口座残高</p>
            <div className={availableAmount === -1 ? '' : 'amount'}>
              {availableAmount === -1 ? (
                <Spinner small={true} isBlack={true} />
              ) : (
                <>{formatWithComma(availableAmount)}円</>
              )}
            </div>
          </div>
          <p className="l-price02__body__caption disclaimer-amount">
            ※NISA口座を選択された場合は、NISA買付可能額を上限としてauカブコム証券口座残高が表示されます。
          </p>
          <p className="field-title">
            毎月の買付金額
            <span>必須</span>
          </p>
          <AmountInput
            maxAmount={
              PAYMENT_TYPES[reservePlanInfo.paymentType] !==
              PAYMENT_TYPE_CREDIT_CARD
                ? TRADE_AMOUNT.MAX
                : creditCardLimit
            }
            minAmount={TRADE_AMOUNT.MIN}
            minTradeUnit={TRADE_AMOUNT.MIN_TRADE_UNIT}
            maxStrLength={TRADE_AMOUNT.STR_LENGTH}
            value={reservePlanInfo.specifiedAmount}
            onAmountChange={onSpecifiedAmountChanged}
            disabled={availableAmount === -1}
            setBadInput={setBadInputSpecifiedAmount}
            className={'specified-amount'}
          />
          {reservePlanInfo.specifiedAmount &&
            Number(reservePlanInfo.specifiedAmount.replace(/,/g, '')) >
              TRADE_AMOUNT.MAX && (
              <p className="l-price02__body__caption error">
                毎月の買付金額は10,000,000,000円未満でご入力ください。
              </p>
            )}
          {PAYMENT_TYPES[reservePlanInfo.paymentType] ===
            PAYMENT_TYPE_CREDIT_CARD &&
            reservePlanInfo.specifiedAmount &&
            Number(reservePlanInfo.specifiedAmount.replace(/,/g, '')) >
              creditCardLimit && (
              <p className="l-price02__body__caption error">
                ※毎月のクレジットカード決済金額が、決済可能額（50,000円）を超えています。
              </p>
            )}
          <p className="field-title">
            決済方法
            <span>必須</span>
          </p>
          <div className={`l-price02__body__price`}>
            <select
              className="payment-type"
              onChange={(e) => {
                onPaymentTypeChanged(e)
              }}
              value={PAYMENT_TYPES[reservePlanInfo.paymentType]}
            >
              <option value={PAYMENT_TYPE_DEPOSIT}>
                {PAYMENT_TYPE_DEPOSIT}
              </option>
              {acceptCreditCardPayment && (
                <option value={PAYMENT_TYPE_CREDIT_CARD}>
                  {PAYMENT_TYPE_CREDIT_CARD}
                </option>
              )}
            </select>
          </div>
          {PAYMENT_TYPES[reservePlanInfo.paymentType] ===
            PAYMENT_TYPE_DEPOSIT && (
            <>
              <p className="l-price02__body__caption">
                ※「お預り金」を選択された場合、ご指定の引落日（毎月の指定日）のauカブコム証券が定める時刻までにご指定の買付金額を証券口座にご入金ください。
              </p>
              <p className="l-price02__body__caption">
                ※「auカブコム証券の投資信託の定期積立サービス」においてクレジットカード決済をご利用いただいており、「毎月の買付金額」がクレジットカード決済可能額の範囲内である場合は、決済方法として「クレジットカード」もご選択いただけます。
              </p>
            </>
          )}
          {PAYMENT_TYPES[reservePlanInfo.paymentType] ===
            PAYMENT_TYPE_CREDIT_CARD && (
            <>
              <p className="credit-limit">
                （クレジットカード決済可能額: {formatWithComma(creditCardLimit)}
                円)
              </p>
              <p className="l-price02__body__caption">
                ※クレジットカードを選択された場合、お客さまが「auカブコム証券の投資信託の定期積立サービス」においてご利用されているクレジットカードによる決済となります。また、毎月の指定日は原則毎月1日（休業日の場合は翌営業日）となり、増額指定を設定できなくなります。
              </p>
              <p className="l-price02__body__caption">
                ※ご登録のクレジットカードについては、
                <a
                  target="_blank"
                  href={EXTERNAL_URL_KABUCOM_USER}
                  className="kabucom-link"
                >
                  auカブコム証券ウェブサイト
                </a>
                にてご確認ください。
              </p>
            </>
          )}
          <p className="field-title">
            毎月の指定日
            <span>必須</span>
          </p>
          <div className={`l-price02__body__price`}>
            <select
              className="plan-date"
              onChange={(e) => {
                onSpecifiedDateChanged(e)
              }}
              value={reservePlanInfo.specifiedDate}
              disabled={
                PAYMENT_TYPES[reservePlanInfo.paymentType] ===
                PAYMENT_TYPE_CREDIT_CARD
              }
            >
              {Array.from(Array(31), (_, i) => (
                <option value={i + 1} key={`specified-date-${i + 1}`}>
                  毎月{i + 1}日
                </option>
              ))}
            </select>
          </div>
          <p className="field-title">増額月1</p>
          <div className={`l-price02__body__price increase-month1`}>
            <select
              onChange={onIncreaseMonth1Changed}
              value={reservePlanInfo.increaseMonth1 || NO_INCREASE_MONTH}
              disabled={
                PAYMENT_TYPES[reservePlanInfo.paymentType] ===
                PAYMENT_TYPE_CREDIT_CARD
              }
            >
              <option value={NO_INCREASE_MONTH}>{NO_INCREASE_MONTH}</option>
              {Array.from(Array(12), (_, i) => (
                <option value={i + 1} key={`increase-month1-${i + 1}`}>
                  {i + 1}月
                </option>
              ))}
            </select>
          </div>
          <p className="field-title">増額月2</p>
          <div className={`l-price02__body__price increase-month2`}>
            <select
              onChange={(e) => onIncreaseMonth2Changed(e)}
              value={reservePlanInfo.increaseMonth2 || NO_INCREASE_MONTH}
              disabled={
                PAYMENT_TYPES[reservePlanInfo.paymentType] ===
                PAYMENT_TYPE_CREDIT_CARD
              }
            >
              <option value={NO_INCREASE_MONTH}>{NO_INCREASE_MONTH}</option>
              {Array.from(Array(12), (_, i) => (
                <option value={i + 1} key={`increase-month2-${i + 1}`}>
                  {i + 1}月
                </option>
              ))}
            </select>
          </div>

          <p className="field-title">
            増額金額
            {(reservePlanInfo.increaseMonth1 ||
              reservePlanInfo.increaseMonth2) && <span>必須</span>}
          </p>
          <AmountInput
            maxAmount={INCREASE_AMOUNT.MAX}
            minAmount={INCREASE_AMOUNT.MIN}
            minTradeUnit={INCREASE_AMOUNT.MIN_TRADE_UNIT}
            maxStrLength={INCREASE_AMOUNT.STR_LENGTH}
            value={reservePlanInfo.increaseAmount}
            onAmountChange={onIncreaseAmountChanged}
            disabled={
              availableAmount === -1 ||
              (!reservePlanInfo.increaseMonth1 &&
                !reservePlanInfo.increaseMonth2)
            }
            disabledPlaceholder="増加月をご選択ください"
            setBadInput={setBadInputIncreaseAmount}
            className={'increase-amount'}
          />
          {reservePlanInfo.increaseAmount &&
            Number(reservePlanInfo.increaseAmount.replace(/,/g, '')) >
              INCREASE_AMOUNT.MAX && (
              <p className="l-price02__body__caption error">
                増額金額は10,000,000,000円未満でご入力ください。
              </p>
            )}
          <div className="l-notes01">
            <p>ご注意事項</p>
          </div>
          <p className="l-price02__body__caption">
            ※ご指定日が休業日あるいはカレンダー上存在しない日付（例えば2月31日など）に該当する場合は翌営業日が指定日となります。また、指定日における入金の時限につきましては、auカブコム証券サイトに掲載の「プレミアム積立®（投資信託定期積立）取引取扱規定」をご確認ください。
          </p>
          {PAYMENT_TYPES[reservePlanInfo.paymentType] ===
            PAYMENT_TYPE_DEPOSIT && (
            <>
              <p className="l-price02__body__caption">
                ※auマネーコネクトをご利用中で、auカブコム証券にて自動入金サービスを登録済みの場合、毎月の指定日に証券口座残高不足の際は、当該不足額についてauじぶん銀行の円普通預金口座からauカブコム証券口座へ口座振替を行います。
              </p>
              <p className="l-price02__body__caption">
                ※ただし、auじぶん銀行の円普通預金口座の残高（auカブコム証券にてauじぶん銀行の円普通預金残置額を設定している場合には、auじぶん銀行の円普通預金口座の残高から当該残置額を控除した残額）が当該不足額未満である場合には、auカブコム証券口座へ口座振替は行われません。
              </p>
            </>
          )}
        </div>
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={
          !reservePlanInfo.specifiedAmount ||
          ((reservePlanInfo.increaseMonth1 || reservePlanInfo.increaseMonth2) &&
            !reservePlanInfo.increaseAmount) ||
          isBadInputSpecifiedAmount ||
          isBadInputIncreaseAmount
        }
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </StyledReservePlanSettingPage>
  )
}

export default ReservePlanSettingPage

const StyledReservePlanSettingPage = styled.div`
  select {
    width: 100%;
    height: 58px;
    border: 1px solid #c2c2c2;
    border-radius: 6px;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: linear-gradient(65deg, transparent 50%, #999 50%),
      linear-gradient(115deg, #999 50%, transparent 50%),
      linear-gradient(to right, #c2c2c2, #c2c2c2),
      linear-gradient(180deg, white 0%, #f1f1f1 100%);
    background-position: calc(100% - 25.5px) calc(1.5em + 2px),
      calc(100% - 18.5px) calc(1.5em + 2px), calc(100% - 50px) 0em, 0;
    background-size: 7px 14px, 7px 14px, 1px 58px, 100% 58px;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

  select:after {
    content: '';
    position: absolute;
    left: 18px;
    top: 50px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #c2c2c2;
    clear: both;
  }

  p.field-title {
    font-weight: bold;
    margin-top: 10px;

    span {
      font-weight: normal;
      color: #ff6400;
      margin-left: 8px;
    }
  }

  p.credit-limit {
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
  }

  .disclaimer-amount {
    width: calc(100% - 20px);
  }

  .error {
    color: red;
  }
`
