import OrderDetails from '../../components/organisms/OrderDetails'
import React from 'react'
import styled from 'styled-components'

const OrderCompletionPage = () => {
  return (
    <StyledOrderCompletionPage>
      <div className="l-content01 order-completion">
        <div className="l-content01__head">
          ご注文ありがとうございます。
          <br />
          auカブコム証券にて注文を受付ました。
        </div>
        <div className="message">
          取引報告書などは、auカブコム証券サイトにログインし、資産管理タブの電子交付からご確認いただけます。
          <br />
          <br />
          以下の注文内容をご確認いただき、画面右上の閉じるボタンを押してください。
          <br />
          <br />
          ※注意事項
          <br />
          実際の約定日はauカブコム証券サイトの取引履歴よりご確認ください。
        </div>
        <OrderDetails isCompletionPage={true} />
      </div>
    </StyledOrderCompletionPage>
  )
}

export default OrderCompletionPage

const StyledOrderCompletionPage = styled.div`
  .message {
    margin-top: 16px;
  }
`
