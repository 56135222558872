import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../atoms/Button'
import { fetchAuthUrl } from '../../common/utils/fetcher'
import { EXTERNAL_URL_API_SERVICE_RULES } from '../../common/utils/constants'

const AuthDisclaimerPage = () => {
  const [agreed, setAgreed] = useState(false)

  const [authUrl, setAuthUrl] = useState('')

  const getAuthUrl = async () => {
    const res = await fetchAuthUrl()
    const url = res?.data?.url
    url && setAuthUrl(url)
    return url
  }

  useEffect(() => {
    getAuthUrl()
  }, [])

  const navigateToAuthUrl = async () => {
    let url = authUrl
    if (!url) {
      url = await getAuthUrl()
    }
    window.location.href = url
  }

  const onInputChecked = () => {
    setAgreed(!agreed)
  }

  return (
    <StyledAuthDisclaimerPage>
      <div className="l-login01">
        <div>
          <p>
            ご利用にあたっては、以下の規定・規約にご同意（①はauカブコム証券宛ての同意、②はauじぶん銀行宛ての同意）をいただく必要があります。
          </p>
          <br />
          <p>※auカブコム証券認証の有効期間は30日間となります。</p>
          <br />
          <p>
            ①kabu.com APIサービス利用規定（auカブコム証券との間で適用されます）
          </p>
          <p>
            ②API連携（auカブコム証券）サービスご利用規約（auじぶん銀行との間で適用されます）
          </p>
          <br />
          <p>
            ①については以下のリンク先に、②については以下のウィンドウ内に全文が表示されておりますので、ご確認ください。
          </p>
          <p>
            <a href={EXTERNAL_URL_API_SERVICE_RULES} target="_blank">
              kabu.com APIサービス利用規定
            </a>
          </p>
          <p>※auカブコム証券が提供するページにリンクします。</p>
          <br />
          <div className="scrollable">
            {`API連携（auカブコム証券）サービスご利用規約

第1条（定義）

本規約において、以下の用語は、以下の定義する意味を有するものとします。
(1) 「アクセストークン」とは、お客さまからの申し出によりauカブコム証券株式会社（以下「auカブコム証券」という）が当行に対して発行し、当行がauカブコム証券のシステムにアクセスするために必要となるトークン（発行済みのトークンの有効期限が経過した場合において、再度お客さまの認証を経ることなく、auカブコム証券が当行に対して発行したトークンを含みます。）をいいます。
(2) 「アグリゲーションサービス」とは、お客さまの委託を受けて、電子情報処理組織を使用する方法により、当行がauカブコム証券からお客さまに関する情報（証券口座における取引情報や残高情報等を含みます。）およびauカブコム証券が保有する金融商品情報その他の公開情報のうち当行が指定する情報を取得し、これを本アプリ画面上で表示する方法でお客さまに提供するサービスです。
(3) 「取引注文伝達サービス」とは、お客さまの委託を受けて、電子情報処理組織を使用する方法により、お客さまのauカブコム証券に対する本件金融商品に係る取引注文を、当行からauカブコム証券に伝達するサービスです。
(4) 「本アプリ」とは、当行所定のスマートフォン向けアプリケーションであって、お客さまのスマートフォンにダウンロードされたものをいいます。
(5) 「本アプリ利用規約」とは、当行所定の「じぶん銀行スマートフォンアプリ」ご利用規約をいいます。
(6) 「本件金融商品」とは、auカブコム証券が取り扱う金融商品（但し、auカブコム証券が取引注文伝達サービスの対象としているものに限ります。）のうち、当行が登録金融機関としてご案内する金融商品仲介の対象とされている金融商品といいます。
(7) 「パスワード等」とは、本アプリ利用規約に定めるログインパスワードおよびアプリパスワードをいいます。
(8) 「APIサービス利用規定」とは、auカブコム証券所定の「kabu.com APIサービス利用規定」をいいます。
(9) 「auカブコムAPIサービス」とは、APIサービス利用規定に基づいてauカブコム証券が提供するサービスをいいます。

第2条　API連携（auカブコム証券）サービスの内容

API連携（auカブコム証券）サービス（以下「本サービス」という）とは、本アプリを使用して、アグリゲーションサービスおよび取引注文伝達サービスをご利用いただけるサービスです。

第3条　規約への同意

本サービスのご利用に際しては、あらかじめ本規約にご同意いただく必要があります。

第4条　ご利用条件

お客さまは、本規約にご同意いただいた上で、以下の条件をすべて充足する場合に限り、本サービスを利用することができるものとします。
(1) お客さまがauカブコム証券の証券総合口座を保有していること。
(2) あらかじめAPIサービス利用規定にご同意いただいたうえで、本アプリへのアクセス権限の付与に係るauカブコム証券所定の手続が完了しており、かつ、本アプリに付与されたアクセス権限が有効に存続していること。
(3) あらかじめ本アプリ利用規約にご同意いただいたうえで、本アプリをお客さまのスマートフォン（但し、指定機種に限るものとします。以下同じ。）において利用できる状態にしておくこと。
(4) 前号のほか、本アプリ利用規約に定める「じぶん銀行スマートフォンアプリ」サービスの利用条件をすべて充足していること。
(5) 本アプリ上で、本サービスを利用するための当行所定の利用設定（以下「サービス利用設定」という）が行われていること。

第5条 本サービスの利用

1. 本サービスの利用にあたり、サービス利用設定できるスマートフォンは、お客さまお一人につき1台のみとなります。
2. 以下の場合、当行は本サービスの利用をお断りする場合があります。
(1) 本サービスの提供が技術上その他の理由により困難なとき。
(2) お客さまが、本アプリの動作確認済み環境のスマートフォンを有していないとき。
(3) その他、当行が適当でないと判断したとき。
3. 本サービスの利用については、当行が提供する最新の本アプリをご使用ください。
4. サービス利用設定後において、登録スマートフォン（第6条第1項において定義）から本アプリを削除した場合には、本アプリを再ダウンロードしたうえで、改めてサービス利用設定を行ってください。
5. auカブコム証券が提供するサービスの利用に必要となる ID・パスワードその他の認 証情報（以下「外部サービス認証情報」という）は、お客さまの責任で厳重に管理し、 他人に教えたり、紛失・盗難に遭わないよう十分注意するものとします。
6. お客さまは、パスワード等（パスワード等が記載または記録された媒体を含みます。）の紛失もしくは漏洩が判明した場合または本アプリへの不正アクセスが生じた場合、およびこれらの懸念が生じた場合には、すみやかに当行に連絡してください。この連絡を受けたときは、当行は直ちに本サービスを停止する措置を講じます。
7. 本サービスの利用にあたり、以下の各号に該当する事象が発生した場合は、当行は、auカブコム証券と連携して情報収集にあたるため、必要に応じ、当行が保有するお客さまの情報をauカブコム証券に対し開示することができるものとします。また、この場合、auカブコム証券においてAPIサービス利用規定に基づくAPIの利用を停止する場合があります。
(1) お客さまの情報が外部に流出・漏洩した場合、またはそのおそれがある場合（前項に定める当行への連絡があった場合を含みます。）
(2) 不正利用が発生した場合、またはそのおそれがある場合
8. auカブコム証券によるアクセストークンやお客さま情報（前項により当行が開示した情報を含みますが、これに限りません。）の管理不十分、使用上の過誤、不正使用等により発生した損害または損失は、auカブコム証券が負うものとし、当行は責任を負いません。 
9. 本サービスの利用に伴い、以下に該当する事象が発生した場合に、お客さまに損害が生じたり、お客さま保護上のリスクが生じるおそれがあります。お客さまは、かかるリスクを十分に理解し、同意したうえで、本サービスを利用するものとします。
(1) アクセストークン、パスワード等または外部サービス認証情報が流出、漏洩しもしくは偽造され、auカブコム証券もしくは当行のシステムが不正にアクセスされ、またはauカブコム証券のシステム障害等により、お客さま情報の流出等が生じる
(2) auカブコム証券の責めに帰すべき事由（内部役職員の不正行為、システム管理 の不備、お客さま保護態勢の不備等を含みますが、これらに限られません。）によりauカブコム証券のサービス機能停止やお客さま情報の流出等が生じる
10. 本サービスの利用にあたっては、前各項のほかAPIサービス利用規定の定めに従うものとします。

第6条 本サービスの内容

1. お客さまは、お客さまがサービス利用設定をされたスマートフォン（ただし、第8条にもとづき別のスマートフォンでサービス利用設定が行われた場合は当該スマートフォンをいい、以下「登録スマートフォン」という）上で本アプリを起動して、本アプリ画面に表示された操作手順にしたがって必要な情報の入力等の操作を行うことにより、本サービスを利用することができます。
2. アグリゲーションサービスによりお客さまに提供される情報は、auカブコム証券と当行との間の契約に基づいてauカブコム証券から当行に提供されるお客さまの情報のうち、当行が指定する情報に限られます。
3. 取引注文伝達サービスにおいて、お客さまがauカブコム証券に対して取引注文を行うことができる金融商品は、当行が指定する金融商品に限られます。
4. 取引注文伝達サービスにおいて取引注文を行うことのできる金融商品は、当行が登録金融機関としてご案内する金融商品仲介の商品であって、当該金融商品はauカブコム証券が提供するものであり、当行が提供するものではありません。また、当行にはauカブコム証券とお客さまとの契約締結に関する代理権はありません（したがって、auカブコム証券とお客さまとの間の契約の締結権はありません。）。なお、金融商品仲介でご案内する商品に係るその他の注意事項につきましては、本アプリ上の取引注文伝達サービスに係る画面に表示されますので、併せてご確認ください。
5. 本サービスにおいては、取引注文の受付完了後における注文口数の変更や当該取引注文に係る取引の解約等の手続はできません。当該手続については、別途auカブコム証券所定の方法で行ってください。
6. 本サービスの利用には手数料はかかりませんが、取引注文伝達サービスを利用した取引注文に係るお客さまとauカブコム証券との間の取引においては、別途auカブコム証券が定める手数料等の支払いが必要となる場合があります。

第7条 登録スマートフォン等の管理

お客さまは、登録スマートフォンが第三者の手に渡り、かつ本アプリの利用パスワード（以下「アプリパスワード」という）等が知られた場合は、当該第三者により本サービスが不正利用されることによりお客さまの情報が外部に漏れたり、お客さまに不正な取引注文等による損害が発生したりする可能性があることを十分認識したうえで、お客さまの責任において登録スマートフォンおよびアプリパスワード等を厳重に管理し、これらを第三者に貸与または開示してはならないものとします。

第8条 スマートフォンの機種変更

本サービスのために利用するスマートフォンを、現にお客さまがサービス利用設定をして いるスマートフォン（以下「旧スマートフォン」という）から別のスマートフォン（以下 「新スマートフォン」という）に変更する場合、新スマートフォンに本アプリをダウンロードしたうえで改めてサービス利用設定を行ってください。このサービス利用設定に係る手続きを完了した時点から、新スマートフォンを登録スマートフォンとして本サービスに利用できます（旧スマートフォンでは本サービスの利用はできなくなります）。

第9条　権利帰属および遵守事項

1. 本アプリその他本サービスに関連するシステムおよびその一切の派生物（以下「本アプリ等」という）に関する著作権及び特許権等の知的所有権並びにその他一切の権利は各保有者に帰属します。また、アグリゲーションサービスにおいてお客さまに提供される情報に関しては、当該情報サービスの提供元に帰属します。お客さまは、お客さまご自身で利用する以外に本サービスの内容を、その目的の如何に関わらず、複製、加工、再利用又は転載することは一切できません。
2. お客さまは、アグリゲーションサービスにおいてお客さまに提供された情報を第三者へ販売すること、第三者へ再配信すること、第三者と共同で利用すること、情報を閲覧している端末機以外へ転載すること、インターネットアクセスポイントを不明にすることおよび当行またはauカブコム証券のデータベースにアクセスして情報を入手する行為その他スクリーンスクレイピングに類する一切の行為であって、当行がシステムの負荷を増加させると判断する行為を行ってはならないものとします。
3. お客さまは、合法的な目的のためにのみ本サービスを利用するものとします。
4. お客さまは、本アプリ等の複製、逆アセンブル、逆コンパイル又はリバースエンジニアリングや、本アプリ等の全部又は一部を改変を行うことはできません。
5. お客さまは、本アプリ等を第三者に対し、有償、無償を問わず、譲渡、使用許諾その他の方法で使用させてはならず、また、担保の目的に供することはできません。
6. お客さまは、本アプリ等の内容を第三者に開示することはできません。
7. お客さまは、当行の同意を得ることなく当行の商標、社名及びロゴマーク等を使用することはできません。
8. お客さまは、本アプリ等を金融商品仲介業において使用することはできません。

第10条　免責事項

1. 当行は、本サービスの信頼性を高めるために最善の努力をしますが、アグリゲーションサービスにおいてお客さまに提供された情報の正確性、適格性、信頼性、適時性を保証するものではなく、また、取引注文伝達サービスを利用してお客さまが行った取引注文がauカブコム証券において適正かつ適時に処理されることを保証するものではありません。
2. 当行は、本サービスに関し、本サービスとauカブコムAPIサービスとの連携が常時適切に行われること、お客さまの利用目的に適合すること、連携結果が正確性、適格性、有用性、信頼性、適時性を有すること、auカブコム証券のシステム管理態勢その他のセキュリティレベル、顧客保護態勢、信用性等が十分であることを保証するものではありません。
3. 当行は、auカブコム証券の提供するサービスに起因してお客さまに発生したすべての損害について、お客さまに対し、責任を負いません。
4. 本サービスに関する技術上の理由または当行の業務上の理由もしくはセキュリティ上の理由（第5条第6項の場合を含みますがこれに限られません。）、 保守等の理由やauカブコム証券のシステムの故障、不具合、一時停止等の理由に基づき、お客さまに事前に通知することなく、本サービスの全部または一部が一時的に制限、停止されることがあります。なお、本項に基づく本サービスの全部または一部の一時的な制限、停止によって生じた損害については、当行は責任を負いません。
5. 本サービスの利用に関して、本アプリの作動に係る不具合 （表示内容の誤謬・逸脱、取引依頼の不能など）、スマートフォンに与える影響およびお客さまが本サービスを利用できないことにより被る不利益、その他一切の不利益について、当行に故意または重大な過失がある場合を除き、当行は一切の責任を負いません。
6. 前項のほか、次の各号の事由により、本サービスが利用できなかった場合には、これによって生じた損害については、当行は責任を負いません。
(1) 災害・事変、裁判所等公的機関の措置等のやむをえない事由が生じた場合。
(2) 当行が相当と認める安全対策を講じたにもかかわらず、通信回線またはコンピュータ等の障害が生じた場合。
(3) 当行以外の第三者の責に帰すべき事由による場合。
(4) 登録スマートフォンまたはインターネット回線の利用不可能な地域または状況下 にある場合。
(5) お客さまが本アプリの最新版を登録スマートフォンにダウンロードしていない場 合。
(6) 登録スマートフォンが本アプリの動作確認済環境を有していない場合（登録スマートフォンの OS（オペレーションシステム）の更新等により本アプリの動作確認済環境が一時的に失われた場合を含む。）

第11条　本サービスの変更等

1. 当行は、本サービスの内容を変更または停止する場合があります。その場合には、当行は 当行ホームページへ掲載することによりお客さまに告知したうえで、サービス内容を変更または停止するものとします。 
2. 前項に定めるほか、auカブコムAPIサービスの内容が変更または停止された場合には、本サービスの内容についても変更または停止される場合があります。この場合も、当行は当行ホームページへ掲載することによりお客さまに告知しますが、止むを得ない場合は変更または停止後に告知が行われる場合があります。

第12条　本サービスの中止

1. 当行は、お客さまが本規約または当行の定める他の規約に違反した場合、または第4条第1項各号に定める本サービスの利用条件のいずれか一つでも充足していないことが 判明した場合は、お客さまに通知することなく、本サービスの提供を中止することができるものとします。 
2. 理由のいかんを問わず、以下の各号のいずれかの事由に該当する場合、本サービスの提供は自動的に中止されます。
(1) お客さまが当行に開設している普通預金口座が解約された場合
(2) auカブコム証券がお客さまに対するauカブコムAPIサービスの提供を中止した場合

第13条　お客さま申立てによる本サービスの利用中止

登録スマートフォンの紛失その他の理由によりお客さまが本サービスの利用中止を行いたい場合は、当行お客さまセンターへ連絡し、当行所定の手続きを行ってください。

第14条　お客さま情報の取得および利用

1. 当行は、お客さまが本サービスを利用された場合において、当該利用に係る情報を自動的に取得します。
2. 当行は、お客さまが当行に届け出た情報および前項により取得した情報を、以下の目的のために利用します。また、お客さま個人の識別・特定が通常できない状態に加工したものを作成し、利用または当行が指定する第三者へ提供する場合があります。お客さまは係る情報の利用および提供をあらかじめ同意するものとします。
(1) お客さまとの契約（本規約を含みます。）や法律等に基づく権利の行使や義務の履行（取引注文伝達サービスにおいてお客さまの取引注文に係る情報をauカブコム証券に伝達することを含みます。）のため
(2) 市場調査、マーケティングならびにデータ分析やアンケートの実施等による当行新サービスの開発、研究またはサービス向上のため
(3) 金融商品やサービスに関する各種ご提案のため
(4) auカブコム証券その他の提携会社等の商品やサービスの各種ご提案のため
(5) お客さま情報の属性やデータを集計、分析するため
(6) その他、お客さまとのお取引を適切かつ円滑に履行するため

第15条　規約等の準用

1. 本サービスに関し、本規約の定めていない事項については、本アプリ利用規約やau じぶん銀行取引規約等の当行の他の規約の定めを準用し、また、お客さまとauカブコム証券との間の権利義務関係等についてはAPIサービス利用規定が適用されます。
2. 本規約において使用する用語の意味は、特に指定のない限り当行所定の au じぶん銀行 取引規約において定義した内容に従うものとします。 

第16条　規約の変更

当行は、この規約の内容を変更する場合があります。その場合には、当行は変更日および変更内容を当行のホームページへ掲示することにより告知し、変更日以降は変更後の内容により、取扱うものとします。

以上
`}
          </div>
          <div className="agreement-input">
            <input type="checkbox" checked={agreed} onChange={onInputChecked} />
            <p onClick={onInputChecked}>
              上記の「kabu.com
              APIサービス利用規定」「API連携（auカブコム証券）サービスご利用規約」の内容を確認のうえ、同意します。
            </p>
          </div>
          <Button
            handleClick={navigateToAuthUrl}
            className="btn-next center"
            text={'次へ'}
            disabled={!agreed}
          />
        </div>
      </div>
    </StyledAuthDisclaimerPage>
  )
}

export default AuthDisclaimerPage

const StyledAuthDisclaimerPage = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .scrollable {
    overflow-y: scroll;
    border: 1px solid gray;
    max-height: 40vh;
    padding: 10px;
    white-space: break-spaces;
    margin-top: 5px;
  }

  div.agreement-input {
    display: flex;
    margin: 15px 1px;
    align-items: center;

    input[type='checkbox'] {
      min-width: 3em;
      min-height: 3em;
    }

    p {
      margin-left: 3px;
      strong {
        color: gray;
      }
    }
  }

  .center {
    margin: 0 auto;
    display: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: #ff6400;
    text-decoration: underline;
  }
`
