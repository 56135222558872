import React from 'react'
import styled from 'styled-components'
import {
  EXTERNAL_URL_TRADE_DISCLAIMER,
  EXTERNAL_URL_TRADE_DISCLAIMER_2,
  EXTERNAL_URL_TRADE_DISCLAIMER_3,
} from '../../common/utils/constants'
import ICON_ALERT from '../../assets/images/icon_alert.png'
import ICON_BLANK from '../../assets/images/icon_blank.gif'
import ICON_MARKER from '../../assets/images/icon_marker.png'
import ICON_MARKER_DOT from '../../assets/images/icon_marker_dot.png'

const TradeDisclaimer = () => {
  return (
    <StyledTradeDisclaimer className="trade-disclaimer">
      <div className="l-message02 first-section">
        <div className="l-message02__head">
          金融商品仲介のお取引は元本割れとなるリスクがあります。また、手数料がかかる場合があります。お取引の前に必ずご確認ください。
        </div>
        <div className="l-message02__lead">
          金融商品仲介でご案内する商品をお申込みの際は、以下の点にご注意ください。
        </div>
        <ul className="l-message02__list">
          <li>
            金融商品仲介における取扱商品は預金ではないため、預金保険制度の対象ではありません。また、当行が元本を保証するものではありません。
          </li>
          <li>
            店頭FXを含む金融商品仲介は金融商品取引法第33条の登録金融機関による媒介業務です。
          </li>
          <li>
            金融商品仲介で取扱う有価証券などは、金利・為替・株式相場などの変動や、有価証券の発行者の業務または財産の状況の変化などにより価格が変動し、損失が生じるおそれがあります。
          </li>
          <li>
            お取引に際しては、手数料などがかかる場合があります。手数料などは商品・銘柄・取引金額・取引方法などにより異なり多岐にわたるため、具体的な金額または計算方法を記載することができません。
          </li>
          <li>
            各商品のリスクおよび手数料などの情報の詳細については、auカブコム証券のウェブサイトの
            <a href={EXTERNAL_URL_TRADE_DISCLAIMER} target="_parent">
              「勧誘方針」
            </a>
            、
            <a href={EXTERNAL_URL_TRADE_DISCLAIMER_3} target="_parent">
              「ご投資にかかる手数料等およびリスクについて」
            </a>
            、各商品の契約締結前交付書面、目論見書または販売用資料などでご確認ください。
          </li>
          <li>
            当行は委託金融商品取引業者とは別法人であり、金融商品仲介のご利用にあたっては、委託金融商品取引業者の証券取引口座の開設が必要です。
          </li>
          <li>
            当行における金融商品仲介のお取引の有無が、お客さまと当行の預金、融資など他のお取引に影響を与えることはありません。また、当行での預金、融資などのお取引内容が金融商品仲介のお取引に影響を与えることはありません。
          </li>
          <li>
            当行が登録金融機関としてご案内する金融商品仲介の商品やサービスは、委託金融商品取引業者によるものであり、当行が提供するものではありません。
          </li>
          <li>
            当行には委託金融商品取引業者とお客さまとの契約締結に関する代理権はありません。したがって、委託金融商品取引業者とお客さまとの間の契約の締結権はありません。
          </li>
          <li>
            お取引に際しては、各商品の契約締結前交付書面、目論見書または約款などをご確認のうえ、投資判断はご自身でされるようお願い申し上げます。
          </li>
          <li>
            ご購入いただいた有価証券などは、委託金融商品取引業者に開設された口座でお預かりのうえ、委託金融商品取引業者の資産と分別して保管されますので、委託金融商品取引業者が破たんした際にも、委託金融商品取引業者の整理・処分などに流用されることはなく、原則として全額が保全されます。万が一、一部不足額が生じた場合など、全額の返還ができないケースが発生した場合でも、投資者保護基金により、お一人あたり1,000万円まで補償されます。
          </li>
          <li>原則として、18歳以上のお客さまを対象とさせていただきます。</li>
          <li>
            当行のウェブサイトでご案内している証券会社の商品・サービスおよび取引条件などは、変更される場合があります。最新の情報については、必ず証券会社のウェブサイトでご確認ください。
          </li>
        </ul>
        <div className="l-message02__foot">
          <div>
            <p>金融商品仲介を行う登録金融機関</p>
            <table>
              <thead>
                <tr>
                  <th>商号等</th>
                  <td>
                    auじぶん銀行株式会社
                    <br />
                    登録金融機関　関東財務局長（登金）第652号
                  </td>
                </tr>
                <tr>
                  <th>加入協会</th>
                  <td>日本証券業協会、一般社団法人金融先物取引業協会</td>
                </tr>
              </thead>
            </table>
          </div>
          <ul className="l-message02__list">
            <li>
              auじぶん銀行は、金融商品仲介業者として委託金融商品取引業者であるau力ブコム証券株式会社の証券口座開設のお申込み、取次ぎおよびau力ブコム証券株式会社の取扱う「株武、投資信託、債券」をご案内しております。
            </li>
            <li>
              「株式、投資信託、債券（金融商品仲介）」に関するお申込み、お問い合わせなどの各種お手続きはau力ブコム証券株式会社が受付します。
            </li>
            <li>
              auじぶん銀行は、auカブコム証券株式会社の代理権は有しておらず、また、金融商品仲介業に関してお客さまから金銭もしくは有価証券の預託を受ける事はできません。
            </li>
          </ul>
          <div>
            <p>委託金融商品取引業者</p>
            <table>
              <thead>
                <tr>
                  <th>商号等</th>
                  <td>
                    auカブコム証券株式会社
                    <br />
                    金融商品取引業者　関東財務局長（金商）第61号
                    <br />
                    銀行代理業　関東財務局長（銀代）第8号
                    <br />
                    電子決済等代行業者　関東財務局長（電代）第18号
                  </td>
                </tr>
                <tr>
                  <th>加入協会</th>
                  <td>
                    日本証券業協会、一般社団法人金融先物取引業協会、一般社団法人日本STO協会、一般社団法人日本投資顧問業協会、一般社団法人第二種金融商品取引業協会
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

      <div className="second-section">
        <h2>お問い合わせ</h2>
        <div className="enquiry">
          金融商品仲介でご案内する商品・サービス内容については、auカブコム証券のコールセンターへお問い合わせください。
          <div>
            <div className="enquiry-box">
              <h3>auカブコム証券</h3>
              <dl>
                <dt>電話番号</dt>
                <dd>
                  <ul>
                    <li>
                      フリーコール
                      <br />
                      <strong>0120-390-390</strong>
                    </li>
                    <li>
                      携帯
                      <br />
                      <strong>03-6688-8888</strong>
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>受付時間</dt>
                <dd>
                  <ul>
                    <li>平日 8:00～16:00</li>
                  </ul>
                </dd>
                <span className="holiday-disclaimer">
                  <span>※</span>年末年始を除く
                </span>
              </dl>
            </div>
            <table>
              <thead>
                <tr>
                  <th>商号等</th>
                  <td>
                    auカブコム証券株式会社
                    <br />
                    金融商品取引業者　関東財務局長（金商）第61号
                    <br />
                    銀行代理業　関東財務局長（銀代）第8号
                    <br />
                    電子決済等代行業者　関東財務局長（電代）第18号
                  </td>
                </tr>
                <tr>
                  <th>加入協会</th>
                  <td>
                    日本証券業協会、一般社団法人金融先物取引業協会、一般社団法人日本STO協会、一般社団法人日本投資顧問業協会、一般社団法人第二種金融商品取引業協会
                  </td>
                </tr>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th>商号等</th>
                  <td>
                    auじぶん銀行株式会社
                    <br />
                    登録金融機関　関東財務局長（登金）第652号
                  </td>
                </tr>
                <tr>
                  <th>加入協会</th>
                  <td>日本証券業協会、一般社団法人金融先物取引業協会</td>
                </tr>
              </thead>
            </table>
          </div>
          <div className="link vertical-center">
            <img src={ICON_MARKER} alt="外部サイトへ移動します"></img>
            <a href={EXTERNAL_URL_TRADE_DISCLAIMER_2} target="_blank">
              auカブコム証券ディスクロージャー誌<span className="icon"></span>
            </a>
            <img src={ICON_BLANK} alt="外部サイトへ移動します"></img>
          </div>
        </div>
      </div>
      <div className="third-section">
        <ul className="l-message02__list">
          <li>
            auカブコム証券株式会社は、auじぶん銀行株式会社を所属銀行とする銀行代理業者として、「資金の貸付（住宅ローン・カードローン）」ならびに「円貨建て普通預金の受入れ」を内容とする契約締結の媒介を行います。
          </li>
        </ul>
      </div>
    </StyledTradeDisclaimer>
  )
}
export default TradeDisclaimer

const StyledTradeDisclaimer = styled.div`
  h2 {
    color: #656565;
    font-weight: 400;
  }
  .first-section,
  .third-section {
    margin: 20px var(--app-left-padding) 0 var(--app-right-padding);
  }

  .link {
    margin-top: 8.75px;

    a {
      padding: 0 5px;
    }
  }

  .vertical-center * {
    vertical-align: middle;
  }

  .second-section {
    background-color: #eee;
    padding: 20px var(--app-right-padding) 20px var(--app-left-padding);

    table {
      margin-top: 1.25em;
    }
    .enquiry {
      background-color: #e0e0e0;
      padding: 16px 10px;
      box-shadow: inset 1px 1px 4px #d8d8d8;
      margin-top: 1em;

      .enquiry-box {
        background-color: #eee;
        border: 1px solid #d4d5d5;
        margin-top: 0.7em;
        padding: 10px;
        margin-bottom: 0.9375em;

        dt {
          display: inline-block;
          position: relative;
          padding-left: 20px;
          color: #535252;
          &:before {
            width: 12px;
            height: 100%;
            background-image: url(${ICON_MARKER_DOT});
            background-repeat: no-repeat;
            background-size: auto 6px;
            position: absolute;
            left: 6px;
            top: 0.4375em;
            content: '';
          }
        }

        dd {
          margin-left: 40px;
          ul li {
            list-style-type: square;
            margin: 0;
            padding: 0;
          }
        }

        span.holiday-disclaimer {
          color: #888;

          span {
            color: #888;
            margin-right: 0.375em;
            padding-left: 22px;
          }
        }
      }
    }
  }

  table {
    th,
    td {
      padding: 8px;
      vertical-align: top;
      border: 1px solid #d4d5d5;
    }
    th {
      width: 30%;
      font-weight: normal;
      background: #eee;
    }
    td {
      width: 70%;
    }

    td:nth-child(2) {
      background: #fff;
    }
  }

  img {
    width: 16px;
  }

  .l-message02 {
    &__head {
      font-size: 1.6rem;
      font-weight: bold;
      color: #eb7000;
      margin: 0 0 16px;
    }
    &__lead {
      margin: 0 0 16px;
    }
    &__list {
      margin: 0 0 16px;
      li {
        position: relative;
        padding: 0 0 0 24px;
        margin: 0 0 8px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          background: url(${ICON_ALERT});
          background-size: cover;
        }
        &:last-child {
          margin: 0;
        }
        img {
          width: 16px;
          height: 16px;
        }
        a {
          color: #03c;
          text-decoration: underline;
        }
      }
    }
    &__foot {
      div {
        margin: 0 0 16px;
      }
      p {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 8px;
      }
    }
  }
`
