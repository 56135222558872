import React from 'react'
import ICON_FAIL from '../../assets/images/icon_auth_failure.svg'

const ServerErrorPage = () => (
  <div>
    <div className="l-login01">
      <div className="l-login01__head">
        <img src={ICON_FAIL} alt="" />
      </div>
      <div className="l-login01__body">
        <p>サーバーエラー</p>
        <p>
          しばらくしてからもう一度お試しください。
          <br />
          本画面を閉じてください。
        </p>
      </div>
    </div>
  </div>
)

export default ServerErrorPage
