import ProgressStepBar from '../../organisms/ProgressStepBar'
import React, { useCallback, useEffect, useState } from 'react'
import FundInfo from '../../organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPE_NISA,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  EXTERNAL_URL_KABUCOM,
  RESERVE_PLAN_STEP_ACCOUNT,
  RESERVE_ROUTES,
  ROUTE_INDIVIDUAL_FUND,
} from '../../../common/utils/constants'
import { useAppContext } from '../../../contexts/AppContext'
import PurchaseWizardButtons from '../../molecules/PurchaseWizardButtons'
import { fetchFundWallet } from '../../../common/utils/fetcher'
import Spinner from '../../molecules/Spinner'
import styled from 'styled-components'
import { isDistributionTypeDisabled } from '../../../common/utils/fund'

const ReserveAccountSelectionPage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }
  const [accountTypeNames] = useState(Object.values(ACCOUNT_TYPES))

  const { fund, reservePlanInfo, setReservePlanInfo } = useAppContext()

  const [isNisaDisabled, setNisaDisabled] = useState(true)

  const [isLoading, setLoading] = useState(true)

  const history = useHistory()

  const loadData = useCallback(async () => {
    const investmentType = isDistributionTypeDisabled(
      DISTRIBUTION_TYPE_DISTRIBUTIVE,
      fund
    )
      ? 2
      : 1
    const walletRes = await fetchFundWallet(fundCode, investmentType)
    const nisaTradeLimit = walletRes.data.nisaTradeLimit
    // NOTE: We want to enable Nisa button if nisaTradeLimit is 0
    if (
      nisaTradeLimit !== null &&
      nisaTradeLimit !== undefined &&
      fund.isNisaReserve
    ) {
      setNisaDisabled(false)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const onPrevButtonClicked = () => {
    history.push(ROUTE_INDIVIDUAL_FUND.replace(':fundCode', fundCode))
  }

  const onNextButtonClicked = () => {
    if (reservePlanInfo.accountType) {
      history.push(
        RESERVE_ROUTES.DISTRIBUTION_TYPE.replace(':fundCode', fundCode)
      )
    }
  }

  const onAccountButtonClicked = (accountTypeName: string) => {
    if (accountTypeName === ACCOUNT_TYPE_NISA && isNisaDisabled) {
      return
    }
    setReservePlanInfo({
      ...reservePlanInfo,
      accountType: Number(
        Object.keys(ACCOUNT_TYPES).find(
          (key) => ACCOUNT_TYPES[key] === accountTypeName
        )
      ),
    })
  }

  return (
    <StyledAccountSelectionPage>
      <ProgressStepBar currentStep={RESERVE_PLAN_STEP_ACCOUNT} isReserve />
      <div
        className={`l-link03 account-selection ${isLoading ? '-loading' : ''}`}
      >
        <p className="l-link03__ttl">取引される口座区分は？</p>
        {isLoading ? (
          <Spinner isBlack short />
        ) : (
          <>
            <ul className="l-link03__list">
              {accountTypeNames.map((accountTypeName) => {
                return (
                  <li
                    key={accountTypeName}
                    className={`
                  ${
                    ACCOUNT_TYPES[reservePlanInfo.accountType] ===
                    accountTypeName
                      ? 'in-active'
                      : ''
                  } ${
                      accountTypeName === ACCOUNT_TYPE_NISA && isNisaDisabled
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={() => {
                      onAccountButtonClicked(accountTypeName)
                    }}
                  >
                    <button>
                      <span>{accountTypeName}</span>
                    </button>
                  </li>
                )
              })}
            </ul>
            <div className="nisa-disclaimer">
              <p>
                ※本アプリを通じたauカブコム証券での投資信託のお取引においては、NISA口座は一般NISAのみ選択可能です。
              </p>
              <br />
              <p>
                <strong>
                  つみたてNISAのお取引をご希望の場合は、
                  <a
                    href={EXTERNAL_URL_KABUCOM}
                    target="_blank"
                    className="kabucom-link"
                  >
                    auカブコム証券のウェブサイト
                  </a>
                  にて直接ご注文ください。
                </strong>
              </p>
            </div>
          </>
        )}
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={!reservePlanInfo.accountType || isLoading}
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </StyledAccountSelectionPage>
  )
}

export default ReserveAccountSelectionPage

const StyledAccountSelectionPage = styled.div`
  .account-selection ul span {
    font-size: 1.5rem;
  }

  .nisa-disclaimer {
    margin-top: 20px;
  }

  a.kabucom-link {
    color: #ff6400;
    text-decoration: underline;
  }
`
