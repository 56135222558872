import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Accordion from './Accordion'
import ICON_RANK_1 from '../../assets/images/icon_rank01.png'
import ICON_RANK_2 from '../../assets/images/icon_rank02.png'
import ICON_RANK_3 from '../../assets/images/icon_rank03.png'
import ICON_BLANK from '../../assets/images/icon_blank.gif'
import ICON_MARKER from '../../assets/images/icon_marker.png'
import { FundRank, FundRankingType } from '../../common/utils/types'
import {
  EXTERNAL_URL_FUND_INFO,
  FUND_RANKING_TYPES,
} from '../../common/utils/constants'
import { fetchFundRanking } from '../../common/utils/fetcher'
import { generateFundPageUrl } from '../../common/utils/fund'
import { useAppContext } from '../../contexts/AppContext'
import Spinner from '../molecules/Spinner'
import ServerErrorText from '../molecules/ServerErrorText'
import FundDisclaimer from './FundDisclaimer'

type FundRankingProps = {
  isFundRankingPage?: boolean
  isHiddenState?: boolean
  setHiddenState?: React.Dispatch<React.SetStateAction<boolean>>
}

const FundRanking = ({
  isFundRankingPage,
  isHiddenState,
  setHiddenState,
}: FundRankingProps) => {
  const { abrParams } = useAppContext()

  const [isLoading, setLoading] = useState(true)

  const [serverError, setServerError] = useState(false)

  const [rankingTypes, setRankingTypes] = useState(
    Object.keys(FUND_RANKING_TYPES).map(
      (key) =>
        ({
          type: key,
          name: FUND_RANKING_TYPES[key],
          ranks: [],
        } as FundRankingType)
    )
  )

  const [selectedRankingType, setSelectedRankingType] = useState(
    rankingTypes[0]
  )

  const loadData = async () => {
    const res = await fetchFundRanking()

    if (res.data.rankings) {
      const rankingTypes = res.data.rankings
        .filter((_ranking) =>
          Object.keys(FUND_RANKING_TYPES).includes(_ranking.rankingType)
        )
        .map((_ranking) => {
          return {
            type: _ranking.rankingType,
            name: FUND_RANKING_TYPES[_ranking.rankingType],
            ranks: _ranking.rankingList
              .sort((a, b) => Number(a.rank) - Number(b.rank))
              .slice(0, 10)
              .map((_rank) => {
                return {
                  rank: Number(_rank.rank),
                  name: _rank.fundName,
                  company: _rank.managementCompanyName,
                  fundCode: _rank.fundCode,
                } as FundRank
              }),
          }
        })
      setRankingTypes(rankingTypes)
      setSelectedRankingType(rankingTypes[0])
    } else if (res.status !== 200) {
      setServerError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const onRankingTypeClicked = (rankingType: FundRankingType) => {
    setSelectedRankingType(rankingType)
  }

  return (
    <StyledFundRanking className="fund-ranking">
      <Accordion
        title="auカブコム証券の投資信託ランキング"
        shownOnDefault
        boldTitle
        isHiddenState={isHiddenState}
        setHiddenState={setHiddenState}
      >
        <div className="content-ranking">
          <div className="content-ranking__txt">
            <p>
              ランキングからファンドを選択し、購入や積立設定が可能です。
              <br />
              ※一部対象外のファンドがあります。
            </p>
            <p>
              ※auカブコム証券の「投資信託」ランキングは、auカブコム証券が集計しランキング化したものであり、auじぶん銀行はその正確性・完全性を保証するものではありません。
            </p>
            <p>※同ランキングに関する【注記事項】も併せてご確認ください。</p>
          </div>
          <ul className="content-ranking__tab">
            {rankingTypes.map((rankingType) => (
              <li
                key={rankingType.type}
                className={
                  selectedRankingType.type === rankingType.type
                    ? 'in-active'
                    : ''
                }
                onClick={() => {
                  onRankingTypeClicked(rankingType)
                }}
              >
                <span>{rankingType.name}</span>
              </li>
            ))}
          </ul>
          <div className="content-ranking__table">
            {isLoading ? (
              <Spinner isBlack />
            ) : serverError ? (
              <ServerErrorText />
            ) : (
              <>
                <p className="fund-type-title">
                  {selectedRankingType.name} トップ10
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>順位</th>
                      <th>銘柄名</th>
                      <th>運用会社名</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedRankingType.ranks.map((rank) => (
                      <tr key={`rank-${rank.rank}`}>
                        <td>
                          {rank.rank === 1 && <img src={ICON_RANK_1} alt="" />}
                          {rank.rank === 2 && <img src={ICON_RANK_2} alt="" />}
                          {rank.rank === 3 && <img src={ICON_RANK_3} alt="" />}
                          {rank.rank >= 4 && <span>{rank.rank}</span>}
                        </td>
                        <td>
                          <span>
                            <a
                              href={
                                isFundRankingPage ||
                                !abrParams.param1 ||
                                !abrParams.param2 ||
                                !abrParams.param3
                                  ? EXTERNAL_URL_FUND_INFO.replace(
                                      '${fundCode}',
                                      rank.fundCode
                                    )
                                  : generateFundPageUrl(
                                      abrParams,
                                      rank.fundCode
                                    )
                              }
                              target="_blank"
                            >
                              <img src={ICON_MARKER} alt="" />
                              {rank.name}
                              <img src={ICON_BLANK} alt="" />
                            </a>
                          </span>
                        </td>
                        <td>{rank.company}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        <div>
          <FundDisclaimer />
        </div>
      </Accordion>
    </StyledFundRanking>
  )
}
export default FundRanking

const StyledFundRanking = styled.span`
  span.anchor {
    color: #03c;
    text-decoration: underline;
  }
  img {
    max-width: 24px !important;
  }
  .content-ranking {
    margin: 20px var(--app-right-padding) 20px var(--app-left-padding);
    &__txt {
      margin: 0 0 16px;
      p {
        margin: 0 0 16px;
        &:last-child {
          margin: 0;
        }
      }
    }
    &__tab {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px 8px;
      li {
        width: 33.3333%;
        padding: 0 4px 8px;
        box-sizing: border-box;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          font-size: 1rem;
          font-weight: bold;
          text-align: center;
          border: 1px solid #999;
          border-radius: 5px;
        }
      }
      .in-active {
        span {
          color: #fff;
          background: #ff6400;
        }
      }
    }
    &__table {
      p.fund-type-title {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 8px;
      }
      table {
        width: 100%;
        thead {
          background: #ffeee1;
        }
        tbody {
          tr {
            &:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
              td {
                &:nth-child(1) {
                  background: #eee;
                }
              }
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              background: #fff7f1;
            }
          }
        }
        th,
        td {
          padding: 5px;
          border: 1px solid #bcbdbd;
        }
        th {
          font-weight: normal;
          text-align: center;
          &:nth-child(1) {
            width: 14%;
          }
          &:nth-child(2) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 26%;
          }
        }
        td {
          &:nth-child(1) {
            font-weight: bold;
            text-align: center;
          }
          &:nth-child(2) {
            span {
              position: relative;
              display: inline;
            }
            img {
              width: 18px;

              &:first-child {
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
`
