import React from 'react'
import styled from 'styled-components'

type SpinnerProps = {
  small?: boolean
  short?: boolean
  isBlack?: boolean
}
const Spinner = ({ small, short, isBlack }: SpinnerProps) => {
  let colorPrimary = 'white',
    colorSecondary = 'rgba(255, 255, 255, 0.2)'
  if (isBlack) {
    colorPrimary = 'black'
    colorSecondary = 'gray'
  }

  return (
    <StyledSpinner
      className="spinner"
      small={small}
      short={short}
      colorPrimary={colorPrimary}
      colorSecondary={colorSecondary}
    />
  )
}

export default Spinner

const StyledSpinner = styled.div<{
  small?: boolean
  short?: boolean
  colorPrimary?: string
  colorSecondary?: string
}>`
  &,
  &:after {
    border-radius: 50%;
    width: ${(props) => (props.small ? '2em' : '3em')};
    height: ${(props) => (props.small ? '2em' : '3em')};
    cursor: default;
  }

  & {
    margin: ${(props) =>
      props.small ? '5px auto' : props.short ? 'auto' : '80px auto'};
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: ${(props) => (props.small ? '0.5em' : '1.1em')} solid
      ${(props) => props.colorSecondary};
    border-right: ${(props) => (props.small ? '0.5em' : '1.1em')} solid
      ${(props) => props.colorSecondary};
    border-bottom: ${(props) => (props.small ? '0.5em' : '1.1em')} solid
      ${(props) => props.colorSecondary};
    border-left: ${(props) => (props.small ? '0.5em' : '1.1em')} solid
      ${(props) => props.colorPrimary};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
