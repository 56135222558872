import ProgressStepBar from '../../components/organisms/ProgressStepBar'
import React, { useState } from 'react'
import FundInfo from '../../components/organisms/FundInfo'
import { useHistory, useParams } from 'react-router-dom'
import {
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  PURCHASE_STEP_DISTRIBUTION,
  ROUTE_ACCOUNT_SELECTION,
  ROUTE_PROSPECTUS,
} from '../../common/utils/constants'
import { useAppContext } from '../../contexts/AppContext'
import ICON_DISTRIBUTIVE_SELECTED from '../../assets/images/icon_distributive_selected.svg'
import ICON_DISTRIBUTIVE_UNSELECTED from '../../assets/images/icon_distributive_unselected.svg'
import ICON_ACCUMULATIVE_SELECTED from '../../assets/images/icon_accumulative_selected.svg'
import ICON_ACCUMULATIVE_UNSELECTED from '../../assets/images/icon_accumulative_unselected.svg'
import PurchaseWizardButtons from '../../components/molecules/PurchaseWizardButtons'
import { isDistributionTypeDisabled } from '../../common/utils/fund'

const DistributionTypePage = () => {
  const { fundCode } = useParams() as {
    fundCode: string
  }

  const [distributionTypeNames] = useState(Object.values(DISTRIBUTION_TYPES))

  const { fund, purchaseInfo, setPurchaseInfo } = useAppContext()

  const history = useHistory()

  const onPrevButtonClicked = () => {
    history.push(ROUTE_ACCOUNT_SELECTION.replace(':fundCode', fundCode))
  }

  const onNextButtonClicked = () => {
    if (purchaseInfo.investmentType) {
      history.push(
        ROUTE_PROSPECTUS.replace(':fundCode', fundCode)
          .replace(':accountType', purchaseInfo.accountType.toString())
          .replace(':investmentType', purchaseInfo.investmentType.toString())
      )
    }
  }

  const onDistributionButtonClicked = (distributionTypeName: string) => {
    if (!isDistributionTypeDisabled(distributionTypeName, fund)) {
      setPurchaseInfo({
        ...purchaseInfo,
        investmentType: Number(
          Object.keys(DISTRIBUTION_TYPES).find(
            (key) => DISTRIBUTION_TYPES[key] === distributionTypeName
          )
        ),
      })
    }
  }

  return (
    <div>
      <ProgressStepBar currentStep={PURCHASE_STEP_DISTRIBUTION} />
      <div className="l-link03 distribution-selection">
        <p className="l-link03__ttl">分配金は？</p>
        <ul className="l-link03__list">
          {distributionTypeNames.map((distributionTypeName) => {
            return (
              <li
                key={distributionTypeName}
                className={`${
                  DISTRIBUTION_TYPES[purchaseInfo.investmentType] ===
                  distributionTypeName
                    ? 'in-active'
                    : ''
                } ${
                  isDistributionTypeDisabled(distributionTypeName, fund)
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  onDistributionButtonClicked(distributionTypeName)
                }}
              >
                <button>
                  <span>
                    {DISTRIBUTION_TYPES[purchaseInfo.investmentType] ===
                    distributionTypeName ? (
                      <img
                        src={
                          distributionTypeName ===
                          DISTRIBUTION_TYPE_DISTRIBUTIVE
                            ? ICON_DISTRIBUTIVE_SELECTED
                            : ICON_ACCUMULATIVE_SELECTED
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        src={
                          distributionTypeName ===
                          DISTRIBUTION_TYPE_DISTRIBUTIVE
                            ? ICON_DISTRIBUTIVE_UNSELECTED
                            : ICON_ACCUMULATIVE_UNSELECTED
                        }
                        alt=""
                      />
                    )}
                    {distributionTypeName}
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <FundInfo />
      <PurchaseWizardButtons
        disabled={!purchaseInfo.investmentType}
        handlePrevClick={onPrevButtonClicked}
        handleNextClick={onNextButtonClicked}
      />
    </div>
  )
}

export default DistributionTypePage
