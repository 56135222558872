import { useAppContext } from '../../contexts/AppContext'
import React, { useEffect, useState } from 'react'
import ICON_TICK from '../../assets/images/icon_tick.svg'
import {
  ACCOUNT_TYPES,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPE_DISTRIBUTIVE,
  EXTERNAL_URL_FUND_INFO,
  FREE_COMMISSION_DISPLAY,
  NO_DATA,
} from '../../common/utils/constants'
import { Prospectus } from '../../common/utils/types'
import { formatWithComma, getWeekday } from '../../common/utils/util'

type OrderDetailsProps = {
  isCompletionPage?: boolean
  prospectuses?: Prospectus[]
}
const OrderDetails = ({
  isCompletionPage,
  prospectuses,
}: OrderDetailsProps) => {
  const { fund, purchaseInfo } = useAppContext()

  const [isNoLoad, setNoLoad] = useState(false)

  useEffect(() => {
    if (fund.prospectuses && fund.prospectuses?.length > 0 && !prospectuses) {
      prospectuses = fund.prospectuses
    }
  }, [prospectuses])

  useEffect(() => {
    const commission =
      DISTRIBUTION_TYPES[purchaseInfo.investmentType] ===
      DISTRIBUTION_TYPE_DISTRIBUTIVE
        ? fund.buyingInfo.distributing?.commission
        : fund.buyingInfo.accumulating?.commission
    if (
      commission === FREE_COMMISSION_DISPLAY ||
      commission === '0' ||
      !commission
    ) {
      setNoLoad(true)
    } else {
      setNoLoad(false)
    }
  }, [fund, purchaseInfo])

  const calculateBalanceAmount = (): string => {
    const purchasePrice = Number(purchaseInfo.price.replace(/,/g, ''))
    const selectedPoints = Number(purchaseInfo.points.replace(/,/g, ''))

    return formatWithComma(purchasePrice - selectedPoints)
  }

  return (
    <div className="l-content01__body">
      <div className="l-content01__body__block">
        <div className="l-content01__body__ttl">注文内容</div>
        <div className="l-content01__body__content">
          <div className="l-content01__body__num">
            <p>
              <a
                href={EXTERNAL_URL_FUND_INFO.replace(
                  '${fundCode}',
                  fund.fundCode
                )}
                target="_blank"
              >
                {fund.fundName}
              </a>
            </p>
            {isNoLoad && <p>ノーロード</p>}
          </div>
          <div className="l-content01__body__detail">
            <dl>
              <dt>運用会社</dt>
              <dd>{fund.investmentCompany || NO_DATA}</dd>
            </dl>
            <dl>
              <dt>投信分類</dt>
              <dd>
                {DISTRIBUTION_TYPES[purchaseInfo.investmentType] ===
                DISTRIBUTION_TYPE_DISTRIBUTIVE
                  ? '一般型'
                  : '累投型'}
              </dd>
            </dl>
            <dl>
              <dt>基準価額</dt>
              <dd>
                {fund.standardPrice}円<span>({fund.standardPriceDate})</span>
              </dd>
            </dl>
          </div>
          {!isCompletionPage && (
            <div className="l-content01__body__link">
              <p>交付目論見書および目論見補完書面</p>
              {prospectuses &&
                prospectuses.map((prospectus: Prospectus) => {
                  return (
                    <dl key={`prospectus-${prospectus.fileName}`}>
                      <dt>
                        <a href={prospectus.url} target="_blank">
                          {prospectus.fileName}
                        </a>
                      </dt>
                      {prospectus.requiresConfirmation && (
                        <dd>
                          {prospectus.isConfirmed ? (
                            <span className="confirmed">
                              <img src={ICON_TICK} alt="" />
                              確認済
                            </span>
                          ) : (
                            <span>未確認</span>
                          )}
                        </dd>
                      )}
                    </dl>
                  )
                })}
            </div>
          )}
          <div className="l-content01__body__detail">
            <dl>
              <dt>注文金額</dt>
              <dd>{purchaseInfo.price}円</dd>
            </dl>
            <dl>
              <dt>口座区分</dt>
              <dd>{ACCOUNT_TYPES[purchaseInfo.accountType]}</dd>
            </dl>
            {isCompletionPage && (
              <>
                <dl>
                  <dt>約定日</dt>
                  <dd>
                    {fund.executionDate}（{getWeekday(fund.executionDate)}）
                  </dd>
                </dl>
                <dl>
                  <dt>受渡日</dt>
                  <dd>
                    {fund.settlementDate}（{getWeekday(fund.settlementDate)}）
                  </dd>
                </dl>
              </>
            )}
            <dl>
              <dt>受渡方法</dt>
              <dd>お預り金</dd>
            </dl>
          </div>
        </div>
        {/* {!isCompletionPage && (
          <ul className="l-content01__body__list">
            <li>投資信託予約受付中です。</li>
            <li>投資信託の買付注文は翌営業日のご予約となります。</li>
          </ul>
        )} */}
      </div>
      {purchaseInfo.pointsSelect && (
        <div className="l-content01__body__points__breakdown">
          <div className="l-content01__body__points__breakdown__inner">
            <div>
              <p>証券口座にあるお金</p>
              <p>Pontaポイント</p>
            </div>
            <div>
              <p>{calculateBalanceAmount()}円</p>
              <p>{purchaseInfo.points}P</p>
            </div>
          </div>
        </div>
      )}
      {/* <div className="l-content01__body__block">
        <div className="l-content01__body__ttl">予定受渡金額概算</div>
        <div className="l-content01__body__content">
          <div className="l-content01__body__detail">
            <dl>
              <dt>約定金額</dt>
              <dd>{purchaseInfo.price}円</dd>
            </dl>
            <dl>
              <dt>手数料（消費税込）</dt>
              <dd>0円</dd>
            </dl>
            <dl>
              <dt>内消費税</dt>
              <dd>0円</dd>
            </dl>
            <dl>
              <dt>手数料率</dt>
              <dd>ノーロード</dd>
            </dl>
            <dl>
              <dt>受渡金額</dt>
              <dd>{purchaseInfo.price}円</dd>
            </dl>
            {isCompletionPage && (
              <dl>
                <dt>買付口数</dt>
                <dd>{fund.executionQuantity}口</dd>
              </dl>
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default OrderDetails
