import React from 'react'
import styled from 'styled-components'
import Button from '../atoms/Button'

type PurchaseWizardButtonsProps = {
  handlePrevClick?: () => void
  handleNextClick: () => void
  disabled?: boolean
  hidePreviousButton?: boolean
}

const PurchaseWizardButtons = ({
  handlePrevClick,
  handleNextClick,
  disabled,
  hidePreviousButton,
}: PurchaseWizardButtonsProps) => {
  return (
    <StyledPurchaseWizardButtons>
      {!hidePreviousButton && handlePrevClick && (
        <Button
          handleClick={handlePrevClick}
          className="btn-prev"
          text={'戻る'}
        />
      )}
      <Button
        handleClick={handleNextClick}
        className="btn-next"
        text={'次へ'}
        disabled={disabled}
      />
    </StyledPurchaseWizardButtons>
  )
}

export default PurchaseWizardButtons

const StyledPurchaseWizardButtons = styled.div`
  & {
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  button.btn-prev {
    margin-right: 2.5px;
    color: #999;
    border: 2px solid #999;
  }

  button.btn-next {
    margin-left: 2.5px;
  }
`
