import { useAppContext } from '../../contexts/AppContext'
import React from 'react'
import Spinner from '../molecules/Spinner'
import { EXTERNAL_URL_FUND_INFO } from '../../common/utils/constants'

const FundInfo = () => {
  const { fund } = useAppContext()
  return (
    <div className="l-price01 fund-info">
      {!fund.fundCode ? (
        <Spinner isBlack />
      ) : (
        <>
          <div className="l-price01__container">
            <div className="l-price01__head">
              <p className="l-price01__head__ttl">{fund.fundCode}</p>
              <p className="l-price01__head__txt">
                <a
                  href={EXTERNAL_URL_FUND_INFO.replace(
                    '${fundCode}',
                    fund.fundCode
                  )}
                  target="_blank"
                >
                  {fund.fundName}
                </a>
              </p>
            </div>
            <div className="l-price01__body">
              <p className="l-price01__body__ttl">基準価額</p>
              <p className="l-price01__body__price">
                {fund.standardPrice}円
                <span>({fund.standardPriceDate} 現在)</span>
              </p>
              {/* <div className="l-price01__body__date">
                <dl>
                  <dt>約定日</dt>
                  <dd>{fund.settlementDate}</dd>
                </dl>
                <dl>
                  <dt>受渡日</dt>
                  <dd>{fund.executionDate}</dd>
                </dl>
              </div>
              <ul className="l-price01__body__list">
                <li>投資信託予約受付中です。</li>
                <li>投資信託の買付注文は翌営業日のご予約となります。</li>
              </ul> */}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FundInfo
