import React from 'react'
import styled from 'styled-components'

type CenteredTextProps = {
  children: any
}
const CenteredText = ({ children }: CenteredTextProps) => (
  <StyledCenteredText>{children}</StyledCenteredText>
)

export default CenteredText

const StyledCenteredText = styled.div`
  text-align: center;
  margin: 80px 0;
`
